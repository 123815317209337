<!-- *ngIf="utilsService.isLanguageChange" -->
<ion-app>
  <!-- <ion-split-pane contentId="main-content"> -->
    <!-- *ngIf="isMenuEnable()" -->
    <ng-container *ngIf="utilsService.isLanguageChange">
    
  <ion-menu contentId="main-content" *ngIf="isMenuEnable() && authServices.isExitLanguageData()">
    <ion-content>
      <ion-list lines="none" class="pt-0">
        <ion-list-header class="pl-0 top-bar">
          <!-- hp-85 -->
          <ion-row class="w-100 pt-8 pb-8" *ngIf="userProfileData">
            <ion-col size="4">
              <div class="container">
                <!-- F43217 -->
                <circle-progress
                  [percent]="'100'"
                  [radius]="30"
                  [outerStrokeWidth]="3"
                  [innerStrokeWidth]="3"
                  [space]="-3"
                  [outerStrokeColor]="'#F43217'"
                  [innerStrokeColor]="'#ececec'"
                  [animation]="true"
                  [showInnerStroke]="true"
                  [animationDuration]="300"
                  [showTitle]="false"
                  [showSubtitle]="true"
                  [showUnits]="false"
                  [subtitle]="''"
                  [clockwise]="true"
                ></circle-progress>
                <div class="centered">
                  <div class="container">
                    <!-- (click)="openImageViewrComponent(); $event.stopPropagation()" -->
                    <ion-avatar class="avt-56">
                      <img *ngIf="!isBusinessUser"
                      (click)="openProfileImage(); $event.stopPropagation()"
                        [src]="
                          userProfileImg &&
                          userProfileImg.display_files &&
                          userProfileImg.display_files.userprofiles_img &&
                          userProfileImg.display_files.userprofiles_img
                            .square_url
                            ? userProfileImg.display_files.userprofiles_img
                                .square_url
                            : userProfileData | getUserImagePipe"
                      />
                      <img *ngIf="isBusinessUser"
                      (click)="openProfileImage(); $event.stopPropagation()"
                        [src]="utilsService.getLogoImageDisplay(userProfileData)"/>
                    </ion-avatar>
                    <!-- (click)="openProfileImage();$event.stopPropagation();" -->
                    <!-- <div class="edit-bottom">
                      <div class="circle-div">
                        <ion-icon
                          [src]="'ic_edit' | getImagePipe"
                          class="ft-12 pb-4"
                        ></ion-icon>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </ion-col>
            <ion-col size="8" class="flex flex-col pt-12">
              <!-- <ion-text class="lbl-bold-15">Hardik Patel</ion-text>
              <ion-text class="lbl-10">Bhavani Enterprise </ion-text>
              <ion-text class="lbl-10">Manufacturer / Retailer </ion-text> -->
              <!-- <ion-text class="lbl-bold-17" *ngIf="userProfileData.f_name || userProfileData.l_name"><span>{{userProfileData.f_name}}</span> <span>&nbsp;&nbsp;{{userProfileData.l_name}}</span></ion-text> -->
              <ion-text
                class="lbl-bold-17"
                *ngIf="userProfileData.f_name || userProfileData.l_name">
                <!-- {{userProfileData.f_name | firstLatterPipe: userProfileData.l_name}} -->
                <span *ngIf="userProfileData.f_name">{{userProfileData.f_name}}&nbsp;</span>
                <span *ngIf="userProfileData.l_name">{{userProfileData.l_name}}</span>
              </ion-text>
              <ion-text
                class="lbl-12 profile-text-height mt-4"
                *ngIf="isBusinessUser && userProfileData.business_name"
                >{{ userProfileData.business_name }}
              </ion-text>
              <ion-text
                class="lbl-12 profile-text-height mlines-1"
                *ngIf="
                  isBusinessUser &&
                  userProfileData.businesslist_id &&
                  userProfileData.businesslist_id.length > 0
                "
                >{{
                  userProfileData.businesslist_id | arrayToStringPipe
                }}</ion-text
              >
              <ion-text
                class="lbl-12 profile-text-height"
                *ngIf="userProfileData.uniqueid"
                >{{ "member_id" | getLanguagePipe  }} :
                <b>{{ userProfileData.uniqueid }}</b>
              </ion-text>
            </ion-col>
          </ion-row>
          <app-without-login-header
            *ngIf="!authServices.getUserLoginData()"
          ></app-without-login-header>
        </ion-list-header>
        <ion-menu-toggle>
          <ion-item
            (click)="opneLanguage()"
            class="pt-4 pl-16 pb-4"
            detail="true"
          >
            <div class="flex flex-col">
              <!-- <ion-text class="lbl-16">{{
                "languages" | getLanguagePipe
              }}</ion-text> -->
              <ion-text
                ><span class="sel-lang-lbl"
                  >{{ "chosen_language" | getLanguagePipe }} :</span
                ><span class="lbl-12">
                  <b *ngIf="selectedLanguage"
                    >&nbsp;{{ selectedLanguage.text }}</b
                  ></span
                >
              </ion-text>
              <ion-text class="lbl-op05-12">V {{appVersion}}</ion-text>
            </div>
          </ion-item>
        </ion-menu-toggle>
        <hr class="divider1 w-100" [ngClass]="isDisplaycard() ? 'mb-16':'mb-12'" />
        <ion-row class="pl-12 pr-12" *ngIf="isDisplaycard()">
          <ion-col>
            <ion-menu-toggle autoHide="false">
            <app-warning-card [routerUrl]="CONST_DATA.RECEIVED_PAYMENTS" [icon1]="'ic_p_info'" [lbl1]="'txt_warn_pending_payment_sub_amaruty'"></app-warning-card>
          </ion-menu-toggle>
          </ion-col>
        </ion-row>
        <ng-container *ngIf="sideMenu && sideMenu.length > 0">
          <ng-container
            *ngFor="let p of sideMenu; let i = index; let isFirst = first"
          >
            <ng-container *ngIf="!p.subMenu || p.subMenu.length === 0">
              <ion-menu-toggle autoHide="false">
                <!-- [routerLink]="p.url" -->
                <ion-item
                *ngIf="!p.isHideMenu"
                  [routerLink]="p.url"
                  [queryParams]="p.param"
                  routerLinkActive="selected"
                  routerDirection="root"
                  detail="false"
                  (click)="onItemClick(p)"
                  class="item-minh"
                  [ngClass]="isFirst ? 'menu-mt' : ''"
                >
                  <ion-icon
                    class="mr-16 ft-20 icon-mtb"
                    slot="start"
                    [src]="p.icon | getImagePipe"
                  ></ion-icon>
                  <ion-label
                    [ngClass]="p.isChecked ? 'lbl-bold-sec-14' : 'lbl-14'"
                    >{{ p.title | getLanguagePipe }}<span class="counter-div" *ngIf="p.isCounter && counterDisplay">{{counterDisplay}}</span></ion-label
                  >
                </ion-item>
              </ion-menu-toggle>
            </ng-container>
            <ng-container *ngIf="p.subMenu && p.subMenu.length > 0">
              <ion-item *ngIf="!p.isHideMenu" detail="false" (click)="onItemClick(p)">
                <ion-icon
                  class="mr-16"
                  slot="start"
                  [src]="p.icon | getImagePipe"
                ></ion-icon>
                <ion-label
                  [ngClass]="p.isChecked ? 'lbl-bold-sec-14' : 'lbl-14'"
                  >{{ p.title | getLanguagePipe }}</ion-label
                >
                <ion-icon
                  color="secondary"
                  [name]="
                    p.isExpand ? 'chevron-up-outline' : 'chevron-down-outline'
                  "
                  class="ft-20 cursor-ptr"
                  slot="end"
                >
                </ion-icon>
              </ion-item>
              <ng-container *ngIf="p.isExpand">
                <ion-row>
                  <ion-col size="3" class="flex flex-col ion-align-items-end">
                    <div class="dvr-vl"></div>
                  </ion-col>
                  <ion-col size="9">
                    <ng-container
                      *ngFor="let submenu of p.subMenu; let i = index"
                    >
                      <ng-container
                        *ngIf="
                          !submenu.innerSubMenu ||
                          submenu.innerSubMenu.length === 0
                        "
                      >
                        <ion-menu-toggle autoHide="false">
                          <ion-item
                          *ngIf="!submenu.isHideMenu"
                            [routerLink]="submenu.url"
                            routerLinkActive="selected"
                            routerDirection="root"
                            detail="false"
                            (click)="subMenuClick(submenu)"
                          >
                          <ion-icon
                          class="mr-16"
                          slot="start"
                          [src]="submenu.icon | getImagePipe"
                        ></ion-icon>
                            <ion-label
                              [ngClass]="
                                submenu.isChecked ? 'lbl-bold-sec-14' : 'lbl-14'
                              "
                              >{{ submenu.title | getLanguagePipe }}</ion-label
                            >
                          </ion-item>
                        </ion-menu-toggle>
                      </ng-container>
                      <ng-container
                        *ngIf="
                          submenu.innerSubMenu &&
                          submenu.innerSubMenu.length > 0
                        "
                      >
                        <ion-item
                          detail="false"
                          (click)="subMenuClick(submenu)"
                        >
                          <ion-icon
                            class="mr-16"
                            slot="start"
                            [src]="submenu.icon | getImagePipe"
                          ></ion-icon>
                          <ion-label
                            [ngClass]="
                              submenu.isChecked ? 'lbl-bold-sec-14' : 'lbl-14'
                            "
                            >{{ submenu.title | getLanguagePipe }}</ion-label
                          >
                          <ion-icon
                            color="secondary"
                            [name]="
                              submenu.isExpand
                                ? 'chevron-up-outline'
                                : 'chevron-down-outline'
                            "
                            class="ft-20 cursor-ptr"
                            slot="end"
                          >
                          </ion-icon>
                        </ion-item>
                        <ng-container *ngIf="submenu.isExpand">
                          <ion-row>
                            <ion-col
                              size="3"
                              class="flex flex-col ion-align-items-end"
                            >
                              <div class="dvr-vl"></div>
                            </ion-col>
                            <ion-col size="9">
                              <ng-container
                                *ngFor="
                                  let inrSubmn of submenu.innerSubMenu;
                                  let idx = index
                                "
                              >
                                <ion-menu-toggle autoHide="false">
                                  <ion-item
                                  *ngIf="!inrSubmn.isHideMenu"
                                    [routerLink]="inrSubmn.url"
                                    routerLinkActive="selected"
                                    routerDirection="root"
                                    detail="false"
                                    (click)="innersubMenuClick(inrSubmn)"
                                  >
                                    <ion-label
                                      [ngClass]="
                                        inrSubmn.isChecked
                                          ? 'lbl-bold-sec-14'
                                          : 'lbl-14'
                                      "
                                      >{{
                                        inrSubmn.title | getLanguagePipe
                                      }}</ion-label
                                    >
                                  </ion-item>
                                </ion-menu-toggle>
                              </ng-container>
                            </ion-col>
                          </ion-row>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ion-col>
                </ion-row>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
        <ion-item
          (click)="logoutClick()"
          *ngIf="authServices.getUserLoginData()"
        >
          <ion-icon
            class="mr-16"
            slot="start"
            [src]="'logout_menu' | getImagePipe"
          ></ion-icon>
          <ion-label [ngClass]="isLogoutClick ? 'lbl-bold-sec-14' : 'lbl-14'">{{
            "logout" | getLanguagePipe
          }}</ion-label>
        </ion-item>
        <ion-item
          (click)="loginClick()"
          *ngIf="!authServices.getUserLoginData()"
        >
          <ion-icon
            class="mr-16"
            slot="start"
            [src]="'logout_menu' | getImagePipe"
          ></ion-icon>
          <ion-label [ngClass]="isLogoutClick ? 'lbl-bold-sec-14' : 'lbl-14'">{{
            "login" | getLanguagePipe
          }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main-content" ></ion-router-outlet>
</ng-container>
  <!-- </ion-split-pane> -->
</ion-app>
