import { Component, OnInit } from '@angular/core';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-chat-card',
  templateUrl: './chat-card.component.html',
  styleUrls: ['./chat-card.component.scss'],
})
export class ChatCardComponent implements OnInit {

  seletectedText="select";
  constructor() { }

  ngOnInit() {}

  onChangeAlert(ev){
    this.seletectedText=ev.detail.value;
    LogData("seletectedText...",this.seletectedText);
    
  }

}
