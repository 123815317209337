export const LOGIN_API = "login";
export const LOGOUT_API = "logout";
export const GET_BUSINESSLISTS_API = "master/businesslists";
export const GET_MASTERS_API = "master";
export const GETPO_API = "getpo?lang=";
export const GET_HOME_API="home";
export const GET_PRIVACY_API="policy";
export const GET_TERMS_API="terms";
// export const GETPO_API="getpo?";

// this services used for varify otp
export const USER_REGISTRATION_API = "userprofiles/registration";
export const USER_SEND_OTP_API = "userprofiles/sendotp";
export const USER_OTPSENDEDIT_API = "userprofiles/otpsendedit";
export const USER_IMAGE_UPLOAD_API = "userprofiles/imageupload";
export const FORGOTPASSWORD_API = "forgotpassword";
export const FORGOTPASSWORDOTPVERIFY_API = "forgotpasswordotpverify";
export const RESETPASSWORD_API = "resetpassword";
export const FORGOTMEMBERID_API = "forgotmemberid";
export const USER_PROFILE_API = "userprofiles/edit";
export const CLOSE_PROFILE_API="userprofiles/closeprofile";
export const USERPROFILES_ADD_API = "userprofiles/add";
export const GET_USERPROFILES_INDEX_API = "userprofiles/index";
export const USER_EXIT_API = "uniquedata";
export const CHANGEPASSWORD_API = "changepassword";
export const REFERRALCODES_CHECK_API = "referralcodes/check";
export const GET_BUSINESSDIRECTORY_API="businessdirectory";
export const USER_PROFILE_UPDATE_API = "userprofiles/updateprofile";
export const USERPROFILES_CONVERTPROFILE_API="userprofiles/convertprofile"

// Master Type Data
export const GET_STATE_API = "requestarr/states";
export const GET_DISTRICT_BY_ID_API = "requestarr/districts";
export const GET_TALUKA_BY_ID_API = "requestarr/talukas";
export const GET_VILLAGES_BY_ID_API = "requestarr/villages";
export const GET_VILLAGES_AREA_BY_ID_API = "requestarr/villageareas";
export const PRODUCTCOMPANIES_API = "requestarr/productcompanies";
export const GET_BUSINESSTYPES_API = "requestarr/businesstypes";
export const GET_TRADINGTYPES_API = "requestarr/tradingtypes";
export const GET_PRODUCTLISTS_API = "requestarr/productlists";
export const GET_LANGUAGES_API = "requestarr/languages";
export const GET_STATUS_API = "requestarr/status";


export const GET_BUSINESSLISTS_INDEX_API = "businesslists/index";
export const ADD_BUSINESSLISTS_API = "businesslists/add";
export const EDIT_BUSINESSLISTS_API = "businesslists/edit";
export const UPDATE_STATUS_BUSINESS_LIST_API = "businesslists/status";
export const DELETE_BUSINESS_LIST_API = "businesslists/delete";

export const GET_BUSINESSTYPES_INDEX_API = "businesstypes/index";
export const ADD_BUSINESSTYPES_API = "businesstypes/add";
export const EDIT_BUSINESSTYPES_API = "businesstypes/edit";

export const GET_PRODUCTLISTS_INDEX_API = "productlists/index";
export const ADD_PRODUCTLISTS_API = "productlists/add";
export const EDIT_PRODUCTLISTS_API = "productlists/edit";
export const UPDATE_STATUS_PRODUCTLISTS_API = "productlists/status";
export const DELETE_PRODUCTLISTS_API = "productlists/delete";

export const GET_PRODUCTCOMPANIES_INDEX_API = "productcompanies/index";
export const ADD_PRODUCTCOMPANIES_API = "productcompanies/add";
export const EDIT_PRODUCTCOMPANIES_API = "productcompanies/edit";
export const UPDATE_STATUS_PRODUCTCOMPANIES_API ="productcompanies/status";
export const DELETE_PRODUCTCOMPANIES_API ="productcompanies/delete";

export const GET_TRADINGTYPES_INDEX_API = "tradingtypes/index";
export const ADD_TRADINGTYPES_API = "tradingtypes/add";
export const EDIT_TRADINGTYPES_API = "tradingtypes/edit";
export const UPDATE_STATUS_TRADING_LIST_API = "tradingtypes/status";
export const DELETE_TRADING_LIST_API = "tradingtypes/delete";

export const GET_STATE_INDEX_API = "states/index";
export const STATES_EDIT_API = "states/edit";
export const STATES_ADD_API = "states/add";
export const UPDATE_STATUS_STATES_LIST_API = "states/status";
export const DELETE_STATES_LIST_API = "states/delete";

export const GET_DISTRICTS_INDEX_API = "districts/index";
export const DISTRICT_EDIT_API = "districts/edit";
export const DISTRICTS_ADD_API = "districts/add";
export const UPDATE_STATUS_DISTRICTS_LIST_API = "districts/status";
export const DELETE_DISTRICTS_LIST_API = "districts/delete";

export const GET_TALUKAS_INDEX_API = "talukas/index";
export const TALUKAS_EDIT_API = "talukas/edit";
export const TALUKAS_ADD_API = "talukas/add";
export const UPDATE_STATUS_TALUKA_LIST_API = "talukas/status";
export const DELETE_TALUKA_LIST_API = "talukas/delete";

export const GET_VILLAGES_INDEX_API = "villages/index";
export const VILLAGES_EDIT_API = "villages/edit";
export const VILLAGES_ADD_API = "villages/add";
export const UPDATE_STATUS_VILLAGES_LIST_API = "villages/status";
export const DELETE_VILLAGES_LIST_API = "villages/delete";

export const GET_VILLAGEAREAS_INDEX_API = "villageareas/index";
export const VILLAGEAREAS_EDIT_API = "villageareas/edit";
export const VILLAGEAREAS_ADD_API = "villageareas/add";
export const UPDATE_STATUS_VILLAGES_AREA_LIST_API = "villageareas/status";
export const DELETE_VILLAGES_AREA_LIST_API = "villageareas/delete";

export const GET_SUBJECTS_INDEX_API = "subjects/index";
export const SUBJECTS_EDIT_API = "subjects/edit";
export const SUBJECTS_ADD_API = "subjects/add";
export const UPDATE_STATUS_SUBJECTS_LIST_API = "subjects/status";
export const DELETE_SUBJECTS_LIST_API = "subjects/delete";

export const GET_STAFF_INDEX_API = "staffs/index";
export const UPDATE_STATUS_USERPROFILES_LIST_API = "userprofiles/status";
export const DELETE_USERPROFILES_LIST_API = "userprofiles/delete";

export const TRANSLATIONS_INDEX_API = "translations/index";
export const TRANSLATIONS_UPDATE_API = "translations/update";
// export const GET_COMPLAINS_INDEX_API = "complains/index";
// export const GET_INQUIRIES_LIST_API = "inquiries/index";

// export const GET_COMPLAININQUIRIES_LIST_API="complaininquiries/index";
// export const UPDATE_STATUS_COMPLAININQUIRIES_API = "complaininquiries/status";
// export const DELETE_COMPLAININQUIRIES_API = "complaininquiries/delete";
// export const COMPLAININQUIRIES_ADD_API="complaininquiries/add";
// export const COMPLAININQUIRIES_EDIT_API="complaininquiries/edit";

export const HOMECONTACT_API="homecontact";

export const GET_USERREGISTERAREAS_INDEX_API = "Userregisterareas/index";
export const ADD_USERREGISTERAREAS_API = "Userregisterareas/add";
export const EDIT_USERREGISTERAREAS_API = "Userregisterareas/edit";
export const UPDATE_STATUS_USERREGISTERAREAS_API = "Userregisterareas/status";
export const DELETE_USERREGISTERAREAS_API = "Userregisterareas/delete";

export const GET_GROUPS_INDEX_API = "groups/index";
export const ADD_GROUPS_API = "groups/add";
export const EDIT_GROUPS_API = "groups/edit";
export const UPDATE_STATUS_GROUPS_API ="groups/status";
export const DELETE_GROUPS_API ="groups/delete";

export const GET_ITEM_GROUPS_INDEX_API = "itemgroups/index";
export const ADD_ITEM_GROUPS_API = "itemgroups/add";
export const EDIT_ITEM_GROUPS_API = "itemgroups/edit";
export const UPDATE_STATUS_ITEM_GROUPS_API ="itemgroups/status";
export const DELETE_ITEM_GROUPS_API ="itemgroups/delete";

export const GET_LEDGERS_INDEX_API = "ledgers/index";
export const ADD_LEDGERS_API = "ledgers/add";
export const EDIT_LEDGERS_API = "ledgers/edit";
export const UPDATE_STATUS_LEDGERS_API ="ledgers/status";
export const DELETE_LEDGERS_API ="ledgers/delete";

export const GET_ITEM_LEDGERS_INDEX_API = "items/index";
export const ADD_ITEM_LEDGERS_API = "items/add";
export const EDIT_ITEM_LEDGERS_API = "items/edit";
export const UPDATE_STATUS_ITEM_LEDGERS_API ="items/status";
export const DELETE_ITEM_LEDGERS_API ="items/delete";

export const GET_BUSINESSVIEW_API="businessview";
export const RATING_API="ratings/newrating";

export const GET_ROLES_INDEX_API="roles/index";
export const ADD_ROLE_API="roles/add";
export const EDIT_ROLE_API="roles/edit";

export const GET_ADVERTISEMENTS_INDEX_API="advertisements/index";
export const EDIT_ADVERTISEMENTS_API="advertisements/edit";
export const ADD_ADVERTISEMENTS_API="advertisements/add";
export const UPDATE_STATUS_ADVERTISEMENTS_API ="advertisements/status";
export const DELETE_ADVERTISEMENTS_API ="advertisements/delete";

export const GETALLOWPERMISSION_API="getallowpermission";

export const DELETE_TRANSACTIONS_API = "transactions/delete";
export const GET_BANK_TRANS_INDEX_API = "transactions/bankIndex";
export const ADD_BANK_TRANS_API = "transactions/bankAdd";
export const EDIT_BANK_TRAS_API = "transactions/bankEdit";

export const GET_PAYMENT_TRANS_INDEX_API = "transactions/paymentIndex";
export const ADD_PAYMENT_TRANS_API = "transactions/paymentAdd";
export const EDIT_PAYMENT_TRAS_API = "transactions/paymentEdit";

export const GET_RECEIPT_TRANS_INDEX_API = "transactions/receiptIndex";
export const ADD_RECEIPT_TRANS_API = "transactions/receiptAdd";
export const EDIT_RECEIPT_TRAS_API = "transactions/receiptEdit";

export const GET_JOURNAL_TRANS_INDEX_API = "transactions/journalIndex";
export const ADD_JOURNAL_TRANS_API = "transactions/journalAdd";
export const EDIT_JOURNAL_TRAS_API = "transactions/journalEdit";
export const GET_TRANSACTIONS_LEDGER_API = "transactions/ledger";
export const TRANS_APPROVEPAYMENT_API="transactions/approvepayment";

export const GET_SALES_TRANS_INDEX_API = "transactions/salesIndex";
export const ADD_SALES_TRANS_API = "transactions/salesAdd";
export const EDIT_SALES_TRAS_API = "transactions/salesEdit";


export const GETAPPLINK_API="getapplink";

export const GET_ACCOUNTS_INDEX_API="accounts/index";
export const ACCOUNTS_ADD_API="accounts/add";
export const ACCOUNTS_EDIT_API="accounts/edit";
export const ACCOUNTS_CREATEORDER_API="accounts/createorder";
export const USER_PLAN_DETAILS="accounts/plandetails";
export const ACCOUNTS_UPDATEENDDATE_API="accounts/updateenddate";

export const EDIT_REFERALCODE="referralcodes/edit";
export const GET_APP_VERSION = "versions"
export const GET_REGISTRATIONPLANS="registrationplans";

export const GET_ADVSETS_INDEX_API = "Advsets/index";
export const ADD_ADVSETS_API = "Advsets/add";
export const EDIT_ADVSETS_API = "Advsets/edit";
export const UPDATE_STATUS_ADVSETS_LIST_API = "Advsets/status";
export const DELETE_ADVSETS_LIST_API = "Advsets/delete";

export const GET_COMPLAINS_INDEX_API = "complains/index";
export const ADD_COMPLAINS_API = "complains/add";
export const EDIT_COMPLAINS_API = "complains/edit";
export const UPDATE_STATUS_COMPLAINS_API ="complains/status";
export const DELETE_COMPLAINS_API ="complains/delete";

export const GET_INQUIRIES_INDEX_API = "inquiries/index";
export const ADD_INQUIRIES_API = "inquiries/add";
export const EDIT_INQUIRIES_API = "inquiries/edit";
export const UPDATE_STATUS_INQUIRIES_API ="inquiries/status";
export const DELETE_INQUIRIES_API ="inquiries/delete";

export const GET_EMPLOYEES_INDEX_API = "employees/index";
export const ADD_EMPLOYEES_API = "employees/add";
export const EDIT_EMPLOYEES_API = "employees/edit";
export const UPDATE_STATUS_EMPLOYEES_API ="employees/status";
export const DELETE_EMPLOYEES_API ="employees/delete";

export const ACCOUNTS_APPLY_FREETRIAL_API="accounts/applyfreetrial";

export const DELETE_COMMENTS_LIST_API = "ratings/delete";
export const GETSTATISTICS_API="reports/getstatistics";
export const NEWREVIEW_API="ratings/newreview";
export const FOLLOWLIKE_API="ratings/followlike";
export const UPDATE_STATUS_COMMENTS_LIST_API="ratings/status";
export const GETREVIEWS_API="ratings/getreviews";
export const GET_BUSINESSREVIEWS_API="ratings/businessreviews";
