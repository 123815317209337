
  <!-- <ion-item-sliding> -->
<div class="container cursor-ptr">

  <ion-item lines="none" class="mt-12" [ngClass]="getStatus(businessData.status) === 2 ? 'bus-item':'bus-item-sel'"
  (press)="onPress($event)" (pressup)="onPressUp($event)" (click)="onClickItem();$event.stopPropagation();">
    <ng-container *ngIf="UIType === 1">
      <div class="flex flex-col mt-8 mb-8">
        <ion-text [ngClass]="getStatus(businessData.status) === 2 ? 'lbl-med-op05-15':'lbl-med-15 '">{{businessData.name}}</ion-text>
        <ion-button *ngIf="isViewBtn" class="mt-8 view-btn" fill="outline" mode="ios" color="secondary" (click)="openView();$event.stopPropagation();">
          View
        </ion-button>
        </div>
      <div slot="end" [ngClass]="getStatus(businessData.status) === 2 ? 'name-div-inactive':'name-div'">
        <ion-text *ngIf="businessData" [ngClass]="getStatus(businessData.status) === 2 ? 'lbl-op05-12':'lbl-sec-12'"><span>&#8377;</span>{{businessData.subscription_cost}}</ion-text>
      </div>
     
    </ng-container>
    <ng-container *ngIf="UIType === 2">
      <div class="flex flex-col pt-8 pb-8">
        <ion-text [ngClass]="getStatus(businessData.status) === 2 ? 'lbl-lt-op05-10':'lbl-sec-10'" *ngIf="businessData.businesstype_id && businessData.businesstype_id.length > 0">{{businessData.businesstype_id[0].text}}</ion-text>
        <ion-text class="mt-4" [ngClass]="getStatus(businessData.status) === 2 ? 'lbl-med-op05-15':'lbl-med-15 '">{{businessData.name}}</ion-text>
      </div>
    </ng-container>
    <ng-container *ngIf="UIType === 3">
      <div class="flex flex-col pt-8 pb-8">
        <ion-text [ngClass]="getStatus(businessData.status) === 2 ? 'lbl-lt-op05-10':'lbl-sec-10'" *ngIf="businessData.tradingtype_id && businessData.tradingtype_id.length > 0">{{businessData.tradingtype_id[0].text}} 
          <span *ngIf="businessData.businesstype_id && businessData.businesstype_id.length > 0">({{businessData.businesstype_id[0].text}})</span>
        </ion-text>
        <ion-text class="mt-4" [ngClass]="getStatus(businessData.status) === 2 ? 'lbl-med-op05-15':'lbl-med-15 '">{{businessData.name}}</ion-text>
      </div>
    </ng-container>
    <ng-container *ngIf="UIType === 4">
      <div class="flex flex-col pt-8 pb-8">
        <ion-text [ngClass]="getStatus(businessData.status) === 2 ? 'lbl-med-op05-15':'lbl-med-15 '" *ngIf="businessData.com_short_name">{{businessData.com_short_name}} 
        </ion-text>
        <ion-text class="mt-4" [ngClass]="getStatus(businessData.status) === 2 ?  'lbl-lt-op05-10':'lbl-sec-10'">{{businessData.company_name}}</ion-text>
      </div>
      </ng-container>
    
  </ion-item>
 
  <div class="top-left">
    <ion-icon [src]="'ic_sel' | getImagePipe" class="ft-24" *ngIf="businessData.isChecked"></ion-icon>
  </div>
</div>
 <!-- <ion-item-options>
    <ion-item-option color="primary">
      <ion-icon slot="start" ios="ellipsis-horizontal" md="ellipsis-vertical"></ion-icon>
      More
    </ion-item-option>
    </ion-item-options>
  </ion-item-sliding> -->

