<ion-accordion-group class="p-16" (ionChange)="onExpand($event)">
  <ion-accordion *ngFor="let item of dataArray" class="according-card mb-16" [toggleIcon]="item.isExpand ? 'acc_up':'acc_down' | getImagePipe">
    <ion-item slot="header" class="according-item">
      <div [ngClass]="item.isExpand ? 'side-div-sel':'side-div'">
        </div>
      <ion-label class="lbl-12 ml-16">{{item.text}}</ion-label>
    </ion-item>

    <ion-list slot="content" class="pl-16 pt-0" *ngFor="let innerItem of item.data">
      <ion-item class="item-0 mt-8" lines="none">
        <ion-icon [src]="'checked' | getImagePipe" class="ft-16"></ion-icon>
        <ion-text class="lbl-11 ml-8">{{innerItem.text}}</ion-text>
      </ion-item>
    </ion-list>
  </ion-accordion>
</ion-accordion-group>