export enum InputTypeEnum {
    TEXT = 'text',
    TEXT_AREA = 'textarea',
    NUMERIC = 'numeric',
    DECIMAL = 'decimal',
    DATE='date',
    DATE_TIME='datetime',
    TIME='time',
    SELECTION="select"
};
