<div class="w-100 item-card-bg pt-8 pr-8 cursor-ptr" [ngClass]="!isActiveStatus() ? 'item-card-bg-iac':'item-card-bg'">
  <ion-item lines="none" class="item-card">
    <ion-thumbnail slot="start" class="mr-2 ion-align-self-start">
      <ion-img class="round-8" [src]="isCustomer ? utilServices.getProfileDisplayImg(userProfileData):utilServices.getLogoImageDisplay(userProfileData)"></ion-img>
    </ion-thumbnail>
    <div class="flex flex-col">
      <ion-text [ngClass]="!isActiveStatus() ? 'lbl-med-16-iac':'lbl-med-16'" *ngIf="userProfileData.business_name && !isCustomer">{{userProfileData.business_name}}</ion-text>
      <ion-text [ngClass]="!isActiveStatus() ? 'lbl-med-16-iac':'lbl-med-16'" *ngIf="(userProfileData.f_name || userProfileData.l_name) && isCustomer">
        <span *ngIf="userProfileData.f_name">{{userProfileData.f_name}}&nbsp;</span>
        <span *ngIf="userProfileData.l_name">{{userProfileData.l_name}}</span>
      </ion-text>
      <ion-text [ngClass]="!isActiveStatus() ? 'lbl-10-iac':'lbl-10'">{{'member_id' | getLanguagePipe }} : <b>{{userProfileData.uniqueid}}</b> </ion-text>
      <div class="flex flex-row" *ngIf="userProfileData.businesslist_id && userProfileData.businesslist_id.length > 0">
        <ion-button class="w-ft" [ngClass]="!isActiveStatus() ? 'btn-iac':'btn-3'" expand="block" mode="ios" *ngFor="let item of userProfileData.businesslist_id">{{item.text}}</ion-button>
      </div>
      <div class="flex flex-row mt-8 ion-align-items-center pb-16">
        <ng-container *ngIf="!isCustomer || (isCustomer && authServices.getUserRoll() === CONST_DATA.ADMIN_ROLE_ID)">
          <ion-icon [src]="(!isActiveStatus() ? 'ic_ro_call_iac':'ic_ro_call') | getImagePipe" class="ft-24"></ion-icon>
        <ion-text class="ml-8" [ngClass]="!isActiveStatus() ? 'lbl-med-9-iac':'lbl-med-9'">{{userProfileData.phone}}</ion-text>
        </ng-container>
        
        
        <ion-icon [src]="(!isActiveStatus() ? 'ic_ro_location_iac':'ic_ro_location') | getImagePipe" class="ft-24 ml-8"></ion-icon>
        <ion-text class="ml-8" [ngClass]="!isActiveStatus() ? 'lbl-med-9-iac':'lbl-med-9'" *ngIf="(userProfileData.village_id && userProfileData.village_id.length > 0) || 
          (userProfileData.district_id && userProfileData.district_id.length > 0)">
         <span *ngIf="userProfileData.village_id && userProfileData.village_id.length > 0">{{userProfileData.village_id[0].text}},&nbsp;</span>
         <span *ngIf="userProfileData.district_id && userProfileData.district_id.length > 0">{{userProfileData.district_id[0].text}}.</span>
        </ion-text>
      </div>
    </div>
    <div slot="end" class="ion-align-self-start pt-4 pr-4 ml-12" *ngIf="!isCustomer && userProfileData.vw_lastacount && 
    userProfileData.vw_lastacount.status &&  userProfileData.vw_lastacount.status.length > 0">
      <ion-text class="lbl-med-12">{{userProfileData.vw_lastacount.status[0].text}}</ion-text>
    </div>
    <!-- <div slot="end">
      <circle-progress [percent]="'80'" [radius]="21" [outerStrokeWidth]="3" [innerStrokeWidth]="3" [space]="-3"
        [outerStrokeColor]="!isActiveStatus() ? '#929292':'#F43217'" [innerStrokeColor]="'#ececec'" [animation]="true" [showInnerStroke]="true"
        [animationDuration]="300" [showTitle]="false" [showSubtitle]="true" [showUnits]="false" [subtitle]="'80'"
        [clockwise]="false"></circle-progress>
    </div> -->
  </ion-item>
 
  <!-- <div class="flex flex-row mt-8 ion-justify-content-end" *ngIf="!isCustomer" [ngClass]="!isActiveStatus() ? 'iac-grd':'ac-grd'">
    <ion-icon [src]="(!isActiveStatus() ? 'ic_iac_cal':'ic_ac_cal') | getImagePipe" class="ft-16"></ion-icon>
    <ion-text class="ml-8" [ngClass]="!isActiveStatus() ? 'lbl-med-9-iac':'lbl-med-9'">180 Days left</ion-text>
  </div> -->
</div>
