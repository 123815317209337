<ion-content class="bg-white">
  <div style="padding: 24px;">
    <ion-row >
      <ion-col>
        <ion-label class="label-7-bold">Upload photo</ion-label>
      </ion-col>
    </ion-row>
    <ion-row class="ion-margin-top">
      <ion-col class="ion-text-center" (click)="removePhoto()" *ngIf="document">
        <div class="container cursor-ptr">
          <img src="assets/img/round_error.svg" >
          <ion-icon name="trash-outline" class="centered" size="large"></ion-icon>
        </div>
        <ion-label class="label-5">Remove photo</ion-label>
      </ion-col>
      <ion-col class="ion-text-center" (click)="uploadDocument()" *ngIf="isUploadDocument">
        <div class="container cursor-ptr">
          <img src="assets/img/round_success.svg"  >
          <ion-icon name="document-outline" class="centered" size="large"></ion-icon>
        </div>
        <ion-label class="label-5">Document</ion-label>
      </ion-col>
      <ion-col class="ion-text-center" (click)="openGallary()">
        <div class="container cursor-ptr">
          <img src="assets/img/round_success.svg"  >
          <ion-icon name="image-outline" class="centered" size="large"></ion-icon>
        </div>
        <ion-label class="label-5">Gallery</ion-label>
      </ion-col>
      <ion-col class="ion-text-center" (click)="openCamera()">
        <div class="container cursor-ptr">
          <img src="assets/img/round_success.svg"  >
          <ion-icon name="camera-outline" class="centered" size="large"></ion-icon>
        </div>
        <ion-label class="label-5">Camera</ion-label>
      </ion-col>
    </ion-row></div>
</ion-content>