<ion-grid>
  <ion-row class="center-row">
    <ion-col
      style="align-self: center"
      class="no-margin-padding"
      [attrSizeXl]="'5'"
      appSizeAttribute
    >
      <div class="bus-details-card pl-16 pr-16 pb-16 pt-8 detail-bg">
        <div class="flex flex-col mt-8">
          <ion-item
            class="mb-8 item-0 ml-na"
            lines="none"
            *ngIf="
              businessDetail.businesscurrently === 1 &&
              businessDetail.businesscurrentlytext
            "
          >
            <ion-thumbnail slot="start" class="avt-32">
              <ion-img [src]="'bus_curr_msg' | getImagePipe"></ion-img>
            </ion-thumbnail>
            <ion-text class="lbl-12 lh-17">
              {{ businessDetail.businesscurrentlytext }}
            </ion-text>
          </ion-item>
          <ion-text class="lbl-sb-18" *ngIf="businessDetail.business_name">{{
            businessDetail.business_name
          }}</ion-text>
          <ion-item class="mt-12 item-0" lines="none">
            <div
              class="flex flex-row wrap-div"
              *ngIf="
                businessDetail.businesslist_id &&
                businessDetail.businesslist_id.length > 0
              "
            >
              <div
                class="type-bg mr-8 mb-8"
                *ngFor="let item of businessDetail.businesslist_id"
              >
                <ion-text class="type-lbl">{{ item.text }}</ion-text>
              </div>
            </div>
          </ion-item>

          <!-- <ion-item class="item-0 item-trans mt-4" lines="none">
            <div class="flex flex-row ion-align-items-center logo-bg mr-0  ion-align-self-start" slot="start">
              <div class="pl-8 pt-4 pr-6">
                <ion-icon [src]="'ic_logo' | getImagePipe" class="ft-16"></ion-icon>
              </div>
              <div class="flex flex-row ion-align-items-center star-bg">
                <ionic5-star-rating #rating activeIcon="star" defaultIcon="star-outline" activeColor="#FFFFFF"
                  defaultColor="#FFFFFF" [rating]="businessDetail.rating ? businessDetail.rating:-1" fontSize="14px" readonly="true"
                  halfStar="true">
                </ionic5-star-rating>
                <ion-text class="lbl-med-wh-12 ml-4">{{businessDetail.rating}}</ion-text>
              </div>
            </div>
            <div *ngIf="businessDetail.rating_user" class="rating-lbl-br flex-row-center ml-16">(<ion-icon [src]="'rating_user' | getImagePipe"></ion-icon><span class="rating-lbl ml-4">{{businessDetail.rating_user}} {{'lbl_rat_user' | getLanguagePipe}}</span>)</div>
          </ion-item> -->

          <ion-item class="item-0 item-trans mt-4" lines="none">
            <!-- <div class="flex flex-row ion-align-items-center logo-bg mr-0  ion-align-self-start" slot="start">
              <div class="pl-8 pt-4 pr-6">
                <ion-icon [src]="'star_sec' | getImagePipe" class="ft-16"></ion-icon>
              </div>
              <div class="flex flex-row ion-align-items-center star-bg">
                <ion-text class="lbl-med-wh-12 ml-4">{{businessDetail.rating}}<span *ngIf="businessDetail.rating_user"
                    class="rating-lbl-br">&nbsp;({{businessDetail.rating_user}})</span></ion-text>
              </div>
            </div> -->
            <div class="flex-row-center ml-4">
              <ion-icon
                [src]="
                  (businessDetail.rating_user ? 'sel_star_sq' : 'star_sq')
                    | getImagePipe
                "
                class="ft-32"
              ></ion-icon>
              <ion-text class="lbl-med-sec-12 ml-8"
                >{{ businessDetail.rating
                }}<span *ngIf="businessDetail.rating_user" class="rating-lbl-br"
                  >&nbsp;({{ businessDetail.rating_user }})</span
                ></ion-text
              >
            </div>
            <div class="flex-row-center ml-8" (click)="followClick()">
              <ion-icon
                [src]="(isFollow ? 'sel_fl_sq' : 'fl_sq') | getImagePipe"
                class="avt-32 cursor-ptr"
              ></ion-icon>
              <ion-text class="lbl-med-sec-12 ml-8"
                ><span
                  >{{
                    businessDetail.followed_user
                      ? businessDetail.followed_user
                      : 0
                  }}&nbsp;</span
                >{{ "lbl_follow" | getLanguagePipe }}</ion-text
              >
            </div>
            <div class="flex-row-center ml-8" (click)="likeClick()">
              <ion-icon
                [src]="(isLike ? 'sel_like_sq' : 'like_sq') | getImagePipe"
                class="avt-32 cursor-ptr"
              ></ion-icon>
              <ion-text class="lbl-med-sec-12 ml-8">{{
                businessDetail.liked_user ? businessDetail.liked_user : 0
              }}</ion-text>
            </div>
            <div class="flex-row-center ml-8" (click)="openComment($event)">
              <ion-icon
                [src]="
                  (businessDetail.review_user ? 'sel_com_sq' : 'com_sq')
                    | getImagePipe
                "
                class="avt-32 cursor-ptr"
              ></ion-icon>
              <ion-text class="lbl-med-sec-12 ml-8">{{
                businessDetail.review_user ? businessDetail.review_user : 0
              }}</ion-text>
            </div>
          </ion-item>

          <ion-item class="mt-8 item-0" lines="none">
            <ion-thumbnail slot="start" class="avt-32 ion-align-self-start">
              <ion-img [src]="'location_sq' | getImagePipe"></ion-img>
            </ion-thumbnail>
            <ion-text class="lbl-12 lh-15"
              >{{ businessDetail | getAddressStrPipe }}
            </ion-text>
          </ion-item>

          <ion-item
            class="item-0"
            lines="none"
            *ngIf="businessDetail.phone && businessDetail.phone_on === 1"
          >
            <ion-thumbnail slot="start" class="avt-32">
              <ion-img [src]="'call_sq' | getImagePipe"></ion-img>
            </ion-thumbnail>
            <a href="tel:{{ businessDetail.phone }}">
              <ion-text class="lbl-12 cursor-ptr">{{
                businessDetail.phone
              }}</ion-text>
            </a>
          </ion-item>
          <ion-item
            class="item-0"
            lines="none"
            *ngIf="businessDetail.whats_no && businessDetail.whats_no_on === 1"
          >
            <ion-thumbnail slot="start" class="avt-32">
              <ion-img [src]="'wh_sq' | getImagePipe"></ion-img>
            </ion-thumbnail>
            <a href="https://wa.me/91{{ businessDetail.whats_no }}">
              <ion-text class="lbl-12 cursor-ptr">{{
                businessDetail.whats_no
              }}</ion-text>
            </a>
          </ion-item>
          <ion-item
            class="item-0"
            lines="none"
            *ngIf="businessDetail.email && businessDetail.email_on === 1"
          >
            <ion-thumbnail slot="start" class="avt-32">
              <ion-img [src]="'msg_sq' | getImagePipe"></ion-img>
            </ion-thumbnail>
            <a href="mailto:{{ businessDetail.email }}">
              <ion-text class="lbl-12 cursor-ptr">{{
                businessDetail.email
              }}</ion-text>
            </a>
          </ion-item>

          <ng-container
            *ngIf="
              authServices.getUserLoginData() &&
              businessDetail.userprofiledetails &&
              businessDetail.userprofiledetails.length > 0
            "
          >
            <ion-accordion-group>
              <ng-container
                *ngFor="
                  let item of businessDetail.userprofiledetails;
                  let i = index
                "
              >
                <ng-container
                  *ngIf="item.view_on_web === 1 && isDesinationDetails(item)"
                >
                  <ion-accordion
                    class="according-card mt-12"
                    [toggleIcon]="'acc_down' | getImagePipe"
                  >
                    <ion-item slot="header" class="according-item">
                      <ion-thumbnail slot="start" class="avt-32 accord-mn">
                        <ion-img
                          [src]="'contact_person_sq' | getImagePipe"
                        ></ion-img>
                      </ion-thumbnail>
                      <ion-label class="lbl-med-12">{{
                        item.designation_id[0].text
                      }}</ion-label>
                    </ion-item>

                    <ion-list slot="content" class="pl-16 pt-0">
                      <ion-item
                        class="item-0"
                        lines="none"
                        *ngIf="item.full_name"
                      >
                        <ion-thumbnail slot="start" class="avt-12">
                          <ion-img [src]="'name_sc' | getImagePipe"></ion-img>
                        </ion-thumbnail>
                        <ion-text class="lbl-10">{{ item.full_name }}</ion-text>
                      </ion-item>

                      <ion-item class="item-0" lines="none" *ngIf="item.phone">
                        <ion-thumbnail slot="start" class="avt-12">
                          <ion-img [src]="'call_sc' | getImagePipe"></ion-img>
                        </ion-thumbnail>
                        <a href="tel:{{ item.phone }}">
                          <ion-text class="lbl-10 cursor-ptr">{{
                            item.phone
                          }}</ion-text>
                        </a>
                      </ion-item>

                      <ion-item class="item-0" lines="none" *ngIf="item.email">
                        <ion-img
                          [src]="'msg_sec' | getImagePipe"
                          class="avt-14"
                        ></ion-img>
                        <a href="mailto:{{ item.email }}">
                          <ion-text class="lbl-10 ml-16 cursor-ptr"
                            >{{ item.email }}
                          </ion-text>
                        </a>
                      </ion-item>
                    </ion-list>
                  </ion-accordion>
                </ng-container>
              </ng-container>
            </ion-accordion-group>
          </ng-container>

          <ion-item class="item-0" lines="none">
            <ion-thumbnail slot="start" class="avt-32">
              <ion-img [src]="'website_sq' | getImagePipe"></ion-img>
            </ion-thumbnail>
            <ion-text class="lbl-12" *ngIf="!businessDetail.website"
              >N/A</ion-text
            >
            <a [href]="businessDetail.website">
              <ion-text *ngIf="businessDetail.website" class="lbl-12">{{
                businessDetail.website
              }}</ion-text>
            </a>
          </ion-item>

          <div
            class="flex flex-row ion-align-items-center mt-12"
            *ngIf="authServices.getUserLoginData()"
          >
            <ion-icon [src]="'gst_sc' | getImagePipe" class="avt-32"></ion-icon>
            <ion-text class="ml-16 lbl-12">
              <!-- {{'lbl_gst' | getLanguagePipe}} : -->
              <span *ngIf="businessDetail.isgst !== 1">
                {{ "no" | getLanguagePipe }}</span
              >
              <span
                *ngIf="
                  businessDetail.isgst === 1 && businessDetail.gstno_on !== 1
                "
              >
                {{ "yes" | getLanguagePipe }}</span
              >
              <span
                *ngIf="
                  businessDetail.isgst === 1 &&
                  businessDetail.gstno_on === 1 &&
                  businessDetail.gstno
                "
                >{{ businessDetail.gstno }}</span
              >
            </ion-text>
          </div>

          <div
            class="flex flex-row ion-align-items-center mt-20"
            *ngIf="isLink()"
          >
            <a [href]="businessDetail.fb_url" *ngIf="businessDetail.fb_url">
              <ion-icon
                [src]="'fb_og' | getImagePipe"
                class="avt-32 mr-12"
              ></ion-icon>
            </a>
            <a
              [href]="businessDetail.twitter_url"
              *ngIf="businessDetail.twitter_url"
            >
              <ion-icon
                [src]="'twi_og' | getImagePipe"
                class="avt-32 mr-12"
              ></ion-icon>
            </a>
            <a
              [href]="businessDetail.linkdin_url"
              *ngIf="businessDetail.linkdin_url"
            >
              <ion-icon
                [src]="'link_og' | getImagePipe"
                class="avt-32 mr-12"
              ></ion-icon>
            </a>
            <a
              [href]="businessDetail.insta_url"
              *ngIf="businessDetail.insta_url"
            >
              <ion-icon
                [src]="'insta_og' | getImagePipe"
                class="avt-32 mr-12"
              ></ion-icon>
            </a>
            <a
              [href]="businessDetail.google_business_url"
              *ngIf="businessDetail.google_business_url"
            >
              <ion-icon
                [src]="'gb_og' | getImagePipe"
                class="avt-32"
              ></ion-icon>
            </a>
            <a
              [href]="businessDetail.youtube_url"
              *ngIf="businessDetail.youtube_url"
            >
              <ion-icon
                [src]="'yt_og' | getImagePipe"
                class="avt-32"
              ></ion-icon>
            </a>
          </div>
        </div>
      </div>
    </ion-col>
  </ion-row>

  <ion-row class="center-row mt-8" *ngIf="businessDetail.description">
    <ion-col style="align-self: center" [attrSizeXl]="'5'" appSizeAttribute>
      <div class="bus-details-card pt-16 pb-16">
        <app-name-icon
          [icon]="'detail_bl'"
          [lable1]="'lbl_bus_de_des'"
        ></app-name-icon>
        <hr class="divider2 w-100 mt-16 mb-8" />
        <ion-item class="item-0" lines="none">
          <div class="pl-16">
            <ion-text class="lbl-12">{{ businessDetail.description }}</ion-text>
          </div>
        </ion-item>
      </div>
    </ion-col>
  </ion-row>

  <ion-row class="center-row mt-8" *ngIf="isBusinessHours()">
    <ion-col style="align-self: center" [attrSizeXl]="'5'" appSizeAttribute>
      <div class="bus-details-card pt-16 pb-16">
        <app-name-icon
          [icon]="'timer_bl'"
          [lable1]="'lbl_bus_de_bus_tim'"
        ></app-name-icon>
        <hr class="divider2 w-100 mt-16 mb-8" />
        <!-- <ng-container *ngIf="isBusinessHours();else noBusHrs"></ng-container> -->
        <ng-container *ngFor="let item of businessDetail.businesshours">
          <div class="pl-12 pr-12" *ngIf="item.v === 1">
            <ion-item class="item-0" lines="none">
              <ion-text slot="start" class="lbl-12">{{ item.t }}</ion-text>
              <ion-text slot="end" class="lbl-12"
                >{{ item.times_t }} {{ "lbl_to" | getLanguagePipe }}
                {{ item.timee_t }}
              </ion-text>
            </ion-item>
          </div>
        </ng-container>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
