<div class="flex flex-col" *ngIf="uiType === 1">
  <ion-item class="item-0" lines="none">
    <ion-avatar slot="start" class="mr-12 ion-align-self-start" [ngClass]="avtCls">
      <img [src]="img ? img:('def_user' | getImagePipe)" />
    </ion-avatar>
    <div class="flex flex-col">
      <ion-text class="lbl-med-14">{{name}}</ion-text>
      <ion-text class="lbl-op05-11" *ngIf="address">{{address}}</ion-text>
      </div>
    <div slot="end">
      <ion-text class="lbl-11" *ngIf="date">{{date | daysAgo}}</ion-text>
    </div>
  </ion-item>
  <ion-text class="lbl-11 lh-20" [ngClass]="!isFullWidthText ? 'mlines-3':''" *ngIf="comment">{{comment}}</ion-text>
</div>

<ion-item class="item-0" lines="none" *ngIf="uiType === 2">
  <ion-avatar slot="start" class="mr-12 ion-align-self-start mt-4" [ngClass]="avtCls">
    <img [src]="img ? img:('def_user' | getImagePipe)" />
  </ion-avatar>
  <!-- <div class="container">
    <app-read-more
      [content]="comment"
      [limit]="300"
      [completeWords]="true"
    >
    </app-read-more>
    </div> -->
  <ion-text class="lbl-11 lh-20"  [ngClass]="!isFullWidthText ? 'mlines-3':''"  *ngIf="comment">{{comment}}</ion-text>
</ion-item>