import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputTypeEnum } from 'src/app/enums/inputtypes-enum';
import { UtilsService } from 'src/app/services/uitils.services';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-amct-input',
  templateUrl: './amct-input.component.html',
  styleUrls: ['./amct-input.component.scss'],
})
export class AmctInputComponent implements OnInit {
  @Input() commonForm:FormGroup  | any;
  @Input() controlName;
  @Input() isFormSubmit:boolean=false;
  @Input() maxDate;
  @Input() minDate=new Date();
  @Input() inputType:any;
  @Input() lbl;
  @Input() placeHolder;
  @Input() isValidation:boolean=false;
  @Input() valMessage;
  @Input() isCharCount:boolean=false;
  @Input() rows="4";
  @Input() cols="20";
  @Input() serverSideError;
  @Input() isServerSideError:boolean=false;
  @Input() index;
  @Input() isIndex:boolean=false;
  @Input() dataList=[];
  @Input() isNoMin:boolean=false;
  @Input() isRSSymbol:boolean=true;
  @Input() isDisabled:boolean=false;
  @Output() onDateTimeValue: EventEmitter<any> = new EventEmitter();
  @Output() onDateValue: EventEmitter<any> = new EventEmitter();
  @Output() onClearDateValue: EventEmitter<any> = new EventEmitter();
  @Output() onTimeValue: EventEmitter<any> = new EventEmitter();
  @Output() onInputChange:EventEmitter<any> = new EventEmitter();
  inputTypeEnum=InputTypeEnum;
  constructor(public utilsService:UtilsService) { }

  ngOnInit() {}

  onDateTimeValueChange(ev){
    // LogData("onDateTimeValueChange..",ev.value);
    this.onDateTimeValue.emit(ev.value);
  }
  onDateValueChange(ev){
    // LogData("onDateValueChange..this.index",this.index);
    this.onDateValue.emit(this.isIndex ? {index:this.index,data:ev.value}:ev.value);
  }

  clearDate(ev){
    this.onClearDateValue.emit(this.isIndex ? {index:this.index,isClear:true}:true);
    
  }

  onTimeValueChange(ev){
    // LogData("onTimeValueChange..",ev.value);
    this.onTimeValue.emit(ev.value);
  }
  onChangeData(ev){
    // LogData("onInputChange_cm",ev);
    this.onInputChange.emit(ev);
  }
}
