import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { LogData } from 'src/app/utils/utils';
import { ProfileImageComponent } from '../profile-image/profile-image.component';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { DomSanitizer } from '@angular/platform-browser';
import { Crop } from '@ionic-native/crop/ngx';
import { UtilsService } from 'src/app/services/uitils.services';
import { ImgCropUtilComponent } from '../img-crop-util/img-crop-util.component';
import { FILE_SIZE_1MB } from 'src/app/utils/constant';
const IMAGE_DIR = 'stored-images';
@Component({
  selector: 'app-photo-utility',
  templateUrl: './photo-utility.component.html',
  styleUrls: ['./photo-utility.component.scss'],
})
export class PhotoUtilityComponent implements OnInit {

  @Input() document: string;
  @Input() quality = 90;
  @Input() cameraDirection = 'back';
  @Input() targetWidth: number;
  @Input() targetHeight: number;
  @Input() isBase64 = false;
  @Input() onlyCamera = false;
  @Input() actionSheetHeader;
  @Output() fileSelected: EventEmitter<any> = new EventEmitter();
  @Output() base64Selected: EventEmitter<any> = new EventEmitter();
  @Input() allowEdit = false;
  @Input() isImageCroppingImg: boolean = false;
  @Input() isUploadDocument: boolean = false;
  @Input() fileLimit = FILE_SIZE_1MB;
  selectedImage;
  @ViewChild('fileIn', { static: false }) fileInputBtn: ElementRef;
  constructor(private popoverCtrl: PopoverController,
    private sanitizer: DomSanitizer,
    private utilsService: UtilsService,
    private crop: Crop) { }

  ngOnInit() { }

  async takePhoto(document?) {
    const modal = await this.popoverCtrl.create({
      component: ProfileImageComponent,
      componentProps: { document: document, isUploadDocument: this.isUploadDocument },
      cssClass: 'custom-popover',
    });

    await modal.present();
    modal.onDidDismiss().then((data) => {
      LogData("data..", data);
      if (data.data != null) {
        switch (data.data) {
          case 0:
            this.fileSelected.emit();
            break;
          case 1:
            this.selectImage(false);
            break;
          case 2:
            this.selectImage(true);
            break;
          case 3:
            // const inp =document
            // window.document.getElementById("fileIn").click();
            // inp.click();
            // this.fileInputBtn.nativeElement.dispatchEvent(new MouseEvent('click'));
            let inputElement: HTMLElement = this.fileInputBtn.nativeElement as HTMLElement;
            inputElement.click();
            break;
        }
      }
    });

  }

  docUpload(event) {
    LogData("docUpload_event", event);
    // const {file}= event[0];
    // LogData("docUpload",file);
  }

  // isBas64() {
  //   return (this.isImageCroppingImg && !this.utilsService.isWeb()) ? true : false;
  // }

  async selectImage(isCamera) {
    const image = await Camera.getPhoto({
      quality: this.quality,
      // allowEditing: true,
      resultType: this.utilsService.isBase64(this.isImageCroppingImg) ? CameraResultType.Base64 : CameraResultType.Uri,
      source: isCamera ? CameraSource.Camera : CameraSource.Photos,
      // Camera, Photos or Prompt!
    });
    // this.selectedImage = image.webPath;
    this.selectedImage = this.sanitizer.bypassSecurityTrustUrl(image.webPath);
    LogData("Image", this.selectedImage);
    if (image) {
      this.saveImage(image)
    }
  }

  async saveImage(photo: Photo) {
    await this.readAsBase64(photo);
  }

  getRandumNumber() {
    return Math.floor((Math.random() * 100000) + 1);
  }

  //base64 to blob
  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  // https://ionicframework.com/docs/angular/your-first-app/3-saving-photos
  private async readAsBase64(photo: Photo) {
    LogData("photo", photo);
    const fileName = new Date().getTime() + '.' + photo.format;

    //Use for Mobile
    if (Capacitor.getPlatform() !== 'web') {
      let file;
      if (!this.isImageCroppingImg) {
        LogData("photo_path_no_crop....", photo.path);
        file = await Filesystem.readFile({
          path: photo.path
        });
        //New code
        const blob = this.b64toBlob(file.data, `image/${photo.format}`);
        if (!this.utilsService.isInValidFile(this.fileLimit, { data: blob })) {
          this.fileSelected.emit({ data: blob, selectedImage: this.selectedImage, fileName: fileName, webPath: photo.webPath });
        }
      } else {
        // const fileURIOrData_crop = await this.crop.crop(photo.path, { quality: 75 });
        // LogData("photo_path_crop.....", fileURIOrData_crop);
        // try{
        //   file = await Filesystem.readFile({
        //     path: fileURIOrData_crop
        //   });
        // }catch(err){
        //   LogData("Filesystem_err",err);
        // }
        this.openImagecrop(photo, fileName);
      }

      // LogData("readAsBase64_file", file);
      // const blob = this.b64toBlob(file.data, `image/${photo.format}`);
      // this.fileSelected.emit({ data: blob, selectedImage: this.selectedImage, fileName: fileName,webPath:photo.webPath });
    }
    else {
      //Use for web
      // Fetch the photo, read as a blob, then convert to base64 format
      if (this.isImageCroppingImg) {
        this.openImagecrop(photo, fileName);
      } else {
        const response = await fetch(photo.webPath);
        const blob = await response.blob();
        LogData("readAsBase64_blob", blob);
        if (!this.utilsService.isInValidFile(this.fileLimit, { data: blob })) {
          this.fileSelected.emit({ data: blob, selectedImage: this.selectedImage, fileName: fileName, webPath: photo.webPath });
        }

      }

    }
  }

  setEmitData() {

  }

  async openImagecrop(photo, fileName) {
    LogData("imageURL", photo);
    const modal = await this.popoverCtrl.create({
      component: ImgCropUtilComponent,
      componentProps: {
        title: 'crop',
        btn1: "apply",
        btn2: "clear",
        imageURL: 'data:image/jpeg;base64,' + photo.base64String,
        format: photo.format
      },
      cssClass: "custom-popover",
      showBackdrop: true,
      backdropDismiss: false,
      // breakpoints: [0, 0.3, 0.5, 0.8],
      // initialBreakpoint: 0.5
    });

    modal.onDidDismiss().then((data) => {
      LogData("onDidDismiss...", data);
      if (data.data != null &&  data.data.image) {
        const base6_str = data.data.image;
        const data_64 = base6_str.split(',')[1];
        const blob = this.b64toBlob(data_64, `image/${photo.format}`);
        LogData("onDidDismiss...blob", blob);
        if(!this.utilsService.isInValidFile(this.fileLimit,{data:blob})){
          this.fileSelected.emit({ data: blob, selectedImage: this.selectedImage, fileName: fileName, webPath: base6_str });
        }
        // this.fileSelected.emit({ data: blob, selectedImage: this.selectedImage, fileName: fileName, webPath: base6_str });
        
      }
    });

    await modal.present();
  }

}
