<!-- <ion-row class="ml-12 mr-12">
  <ion-col class="pad-chat">
    <ion-item lines="none">
      <ion-avatar slot="start" class="m-0 ion-align-self-end">
        <ion-img src="assets/img/user.png">
        </ion-img>
      </ion-avatar>
      <div class="opp-user pl-16 pr-16 pt-12 pb-12">
        <ion-label>
          <p class="lbl-opp ion-text-wrap mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <p class="lbl-time-opp ion-text-end">10:45</p>
        </ion-label>
      </div>
    </ion-item>

    <div style="display: flex;float: right;" class="mt-16">
      <ion-item lines="none">
        <div class="avatar">
        </div>
        <div class="login-user pl-12 pr-12 pt-8 pb-8">
          <ion-label>
            <p class="lbl-med-14 ion-text-wrap mt-8 ml-8 mr-8 ls-01">
              He Creates atmoshphere have do</p>
            <p class="lbl-med-op75-12 ion-text-start ml-8">11:05</p>
          </ion-label>
        </div>
      </ion-item>
    </div>
  </ion-col>
</ion-row> -->

<!-- Right -->
<!-- <ion-row class="pl-16 pr-16">
  <ion-col size="2" class="ion-align-self-end" style="padding-bottom: 20px;">
    <ion-avatar style="height: 36px;width: 36px;margin-top: -16px;">
      <ion-img src="assets/img/user.png">
      </ion-img>
    </ion-avatar>
  </ion-col>
  <ion-col size="10">
    <div class="chat left w-ft" style="background: #eff9fd;">
      <div class="pl-16 pr-16 pt-12 pb-12">
        <ion-label>
          <p class="lbl-opp ion-text-wrap mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <p class="lbl-time-opp ion-text-end">10:45</p>
        </ion-label>
      </div>
    </div>
  </ion-col>
</ion-row> -->

<ion-row class="ml-12 mr-12">
  <ion-col class="pad-chat">
    <!-- Opp user -->
    <div class="flex flex-row">
      <div class="ion-align-self-end" style="padding-bottom: 20px;">
        <ion-avatar style="height: 36px;width: 36px;margin-top: -16px;">
          <ion-img src="assets/img/user.png">
          </ion-img>
        </ion-avatar>
      </div>
      <div class="chat left w-ft ml-8" style="background: #eff9fd;">
        <div class="pl-16 pr-16 pt-12 pb-12">
          <ion-label>
            <p class="lbl-opp ion-text-wrap mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <p class="lbl-time-opp ion-text-end">10:45</p>
          </ion-label>
        </div>
      </div>
    </div>

    <!-- Login User -->
    <div style="display: flex;float: right;">
      <div class="chat right w-ft" style="background: #FFF9DC;">
        <div class="pl-12 pr-12 pt-8 pb-8">
          <ion-label>
            <p class="lbl-med-14 ion-text-wrap mt-8 ml-8 mr-8 ls-01">
              He Creates atmoshphere have do</p>
            <p class="lbl-med-op75-12 ion-text-start ml-8">11:05</p>
          </ion-label>
        </div>
      </div>
    </div>

  </ion-col>
</ion-row>
<ion-row class="ml-12 mr-12">
  <ion-col class="pad-chat">
    <!-- Opp user -->
    <div class="flex flex-row">
      <div class="ion-align-self-end" style="padding-bottom: 20px;">
        <ion-avatar style="height: 36px;width: 36px;margin-top: -16px;">
          <ion-img src="assets/img/user.png">
          </ion-img>
        </ion-avatar>
      </div>
      <div class="chat left w-ft ml-8" style="background: #eff9fd;">
        <div class="pl-16 pr-16 pt-12 pb-12">
          <ion-label>
            <p class="lbl-opp ion-text-wrap mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <p class="lbl-time-opp ion-text-end">10:45</p>
          </ion-label>
        </div>
      </div>
    </div>

    <!-- Login User -->
    <div style="display: flex;float: right;">
      <div class="chat right w-ft" style="background: #FFF9DC;">
        <div class="pl-12 pr-12 pt-8 pb-8">
          <ion-label>
            <p class="lbl-med-14 ion-text-wrap mt-8 ml-8 mr-8 ls-01">
              He Creates atmoshphere have do</p>
            <p class="lbl-med-op75-12 ion-text-start ml-8">11:05</p>
          </ion-label>
        </div>
      </div>
    </div>

  </ion-col>
</ion-row>
<ion-row class="ml-12 mr-12">
  <ion-col class="pad-chat">
    <!-- Opp user -->
    <div class="flex flex-row">
      <div class="ion-align-self-end" style="padding-bottom: 20px;">
        <ion-avatar style="height: 36px;width: 36px;margin-top: -16px;">
          <ion-img src="assets/img/user.png">
          </ion-img>
        </ion-avatar>
      </div>
      <div class="chat left w-ft ml-8" style="background: #EAF9FF;">
        <div class="pl-16 pr-16 pt-12 pb-12">
          <ion-label>
            <p class="lbl-opp ion-text-wrap mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <p class="lbl-time-opp ion-text-end">10:45</p>
          </ion-label>
        </div>
      </div>
    </div>

    <!-- Login User -->
    <div style="display: flex;float: right;">
      <div class="chat right w-ft" style="background: #FFF9DC;">
        <div class="pl-12 pr-12 pt-8 pb-8">
          <ion-label>
            <p class="lbl-med-14 ion-text-wrap mt-8 ml-8 mr-8 ls-01">
              He Creates atmoshphere have do</p>
            <p class="lbl-med-op75-12 ion-text-start ml-8">11:05</p>
          </ion-label>
        </div>
      </div>
    </div>

  </ion-col>
</ion-row>
<!-- <ion-row>
  <ion-col>
    <ion-item class="ion-item-bg p-8" lines="none">
      <ion-label>{{seletectedText}}</ion-label>
      <ion-select (ionChange)="onChangeAlert($event)" >
        <ion-select-option value="brown">Brown</ion-select-option>
        <ion-select-option value="blonde">Blonde</ion-select-option>
        <ion-select-option value="black">Black</ion-select-option>
        <ion-select-option value="red">Red</ion-select-option>
      </ion-select>
    </ion-item>
    </ion-col>
  </ion-row> -->