import { ADVERTISEBANNERS_MC, BUSINESSES_MC, BUSINESSLISTS_MC, COMPLAINS_MC, CUSTOMERS_MC, DEALERS_MC, DISTRICTS_MC, EMAILTEMPLATES_MC, GROUPS_MC, INQUIRIES_MC, ITEMGROUPS_MC, LEDGERS_MC, PRODUCTCOMPANIES_MC, PRODUCTLISTS_MC, PUSHADVERTISEBANNERS_MC, ROLES_MC, SMSTEMPLATES_MC, STAFFS_MC, STATES_MC, SUBJECTS_MC, TALUKAS_MC, TRADINGTYPES_MC, TRANSACTIONS_MC, TRANSLATIONS_MC, VILLAGEAREAS_MC, VILLAGES_MC } from "./menu-code";
import {
  ACCOUNT_LADGER,
  ACCOUNT_LADGER_REPORT,
  ACCOUNT_SETTING,
  ADMIN_HOME_ROUTE,
  ADMIN_PROFILE_ROUTE,
  ADMIN_TAB1_ROUTE,
  ADMIN_TAB3_ROUTE,
  ADMIN_TAB4_ROUTE,
  ADVERTISE_BANNER,
  ADVERTISE_PUSH_BANNER,
  ADVERTISE_SETTINGS,
  BANK_TRANSACTIONS,
  BRANCH_DEALER,
  BUSINESS_LIST,
  BUSINESS_TYPE,
  CHANGE_PASSWORD,
  COMMENTS_RT,
  COMPLAINS_LIST,
  COMPLAIN_SUBJECTS,
  DISTRCT_ROUTE,
  EMAIL_ROUTE,
  GALARRY_SETTINGS_ROUTE,
  GROUP_LIST,
  INQUIRE_LIST,
  ITEM_GROUP,
  ITEM_LADGER,
  JOURNAL_TRANSACTIONS,
  LANGUAGE_LIST,
  PAYMENT_TRANSACTIONS,
  PRODUCT_COMPNEY_LIST,
  PRODUCT_LIST,
  PROMO_CODES_RT,
  PURCHASE_ENTRY,
  PUSH_ADVERTISE_SETTINGS,
  RECEIPT_TRANSACTIONS,
  RECEIVED_PAYMENTS,
  REGISTRATION_REPORT,
  REG_STEP1,
  ROLES_ROUTE,
  SALES_ENTRY,
  SMS_ROUTE,
  STAFF_ROUTE,
  STATES_ROUTE,
  TALUKAS_ROUTE,
  TRADING_LIST,
  VILLAGES_AREA_ROUTE,
  VILLAGES_ROUTE,
} from "../../utils/routes";
import { BUSINESS_REG_SLUG, CUSTOMER_REG_SLUG } from "src/app/utils/constant";
import { Capacitor } from "@capacitor/core";

export const AdminMenuData = [
    {
      id: 1000,
      title: "mn_visit_website",
      url: ADMIN_HOME_ROUTE,
      icon: "ic_visit_web",
      isChecked: false,
  
    },
    {
      id: 1001,
      title: "mn_add_language",
      url: LANGUAGE_LIST,
      icon: "lang_sm",
      isChecked: false,
      menuCode: TRANSLATIONS_MC
    },
    {
      id: 1,
      title: "dashboard",
      url: ADMIN_TAB1_ROUTE,
      icon: "ic_dashboard",
      isChecked: false,
    },
    {
      id: 2,
      title: "branch_dealers",
      url: BRANCH_DEALER,
      icon: "branch_sm",
      isChecked: false,
      menuCode: DEALERS_MC
    },
    {
      id: 1002,
      title: "businesse",
      url: ADMIN_TAB3_ROUTE,
      icon: "bus_sm",
      isChecked: false,
      menuCode: BUSINESSES_MC
    },
    {
      id: 1003,
      title: "mn_customers",
      url: ADMIN_TAB4_ROUTE,
      icon: "cst_sm",
      isChecked: false,
      menuCode: CUSTOMERS_MC
    },
    {
      id: 3,
      title: "staff",
      url: STAFF_ROUTE,
      icon: "staff_sm",
      isChecked: false,
      menuCode: STAFFS_MC
    },
    {
      id: 4,
      title: "roles",
      url: ROLES_ROUTE,
      icon: "role_sm",
      isChecked: false,
      menuCode: ROLES_MC
    },
    {
      id: 5,
      title: "information",
      url: "",
      icon: "info_sm",
      isChecked: false,
      subMenu: [
        {
          id: 51,
          title: "address",
          url: "",
          icon: "address_sm",
          isChecked: false,
          isExpand: false,
          innerSubMenu: [
            // { id: 511, title: "countries", url: "" },
            { id: 512, title: "state", url: STATES_ROUTE, menuCode: STATES_MC },
            { id: 513, title: "districts", url: DISTRCT_ROUTE, menuCode: DISTRICTS_MC },
            { id: 514, title: "talukas", url: TALUKAS_ROUTE, menuCode: TALUKAS_MC },
            { id: 515, title: "villages", url: VILLAGES_ROUTE, menuCode: VILLAGES_MC },
            { id: 516, title: "village_areas", url: VILLAGES_AREA_ROUTE, menuCode: VILLAGEAREAS_MC },
          ],
        },
        // {
        //   id: 52,
        //   title: "banks",
        //   url: "'",
        //   icon: "bank_sm",
        //   isChecked: false,
        // },
        // {
        //   id: 53,
        //   title: "post_offices",
        //   url: "",
        //   icon: "post_sm",
        //   isChecked: false,
        // },
        // {
        //   id: 54,
        //   title: "police_stations",
        //   url: "",
        //   icon: "police_sm",
        //   isChecked: false,
        // },
      ],
    },
    {
      id: 6,
      title: "mn_business_master",
      url: "",
      icon: "bus_sm",
      isChecked: false,
      subMenu: [
        // {
        //   id: 61,
        //   title: 'businesses_types',
        //   url: '',
        //   icon: 'bt_sm',
        //   isChecked: false
        // },
        // {
        //   id: 62,
        //   title: 'trading_types',
        //   url: '',
        //   icon: 'trad_sm',
        //   isChecked: false
        // },
        {
          id: 61,
          title: "business_list",
          url: BUSINESS_LIST,
          icon: "bus_list_sm",
          isChecked: false,
          menuCode: BUSINESSLISTS_MC
        },
        {
          id: 62,
          title: "trading_types",
          url: TRADING_LIST,
          icon: "trad_sm",
          isChecked: false,
          menuCode: TRADINGTYPES_MC
        },
        {
          id: 63,
          title: "product",
          url: PRODUCT_LIST,
          icon: "product_sm",
          isChecked: false,
          menuCode: PRODUCTLISTS_MC
        },
        {
          id: 64,
          title: "product_company",
          url: PRODUCT_COMPNEY_LIST,
          icon: "pro_compl_sm",
          isChecked: false,
          menuCode: PRODUCTCOMPANIES_MC
        },
      ],
    },
    {
      id: 7,
      title: "advertise",
      url: "",
      icon: "adv_sm",
      isChecked: false,
      subMenu: [
        {
          id: 71,
          title: "advertise_banners",
          url: ADVERTISE_BANNER,
          icon: "adv_banner_sm",
          isChecked: false,
          menuCode: ADVERTISEBANNERS_MC
        },
        {
          id: 72,
          title: "push_notification",
          url: ADVERTISE_PUSH_BANNER,
          icon: "push_add_sm",
          isChecked: false,
          menuCode: PUSHADVERTISEBANNERS_MC
        },
      ],
    },
    {
      id: 73,
      title: "mn_promo_code",
      url: PROMO_CODES_RT,
      icon: "promo_sm",
      isChecked: false
    },
    {
      id: 8,
      title: "accounts",
      url: "",
      icon: "acc_sm",
      isExpand: false,
      isChecked: false,
      subMenu: [
        {
          id: 81,
          title: "master",
          url: "",
          icon: "master_sm",
          innerSubMenu: [
            // { id: 811, title: "gst_master", url: "" },
            { id: 812, title: "account_group", url: GROUP_LIST, menuCode: GROUPS_MC },
            { id: 813, title: "item_group", url: ITEM_GROUP, menuCode: ITEMGROUPS_MC },
            { id: 814, title: "account_ledger", url: ACCOUNT_LADGER, menuCode: LEDGERS_MC },
            { id: 815, title: "item_ledger", url: ITEM_LADGER, menuCode: LEDGERS_MC },
          ],
        },
        {
          id: 82,
          title: "transaction",
          url: "",
          icon: "trans_sm",
          menuCode: TRANSACTIONS_MC,
          innerSubMenu: [
            { id: 821, title: "sales_entry", url: SALES_ENTRY },
            { id: 822, title: "purchase_entry", url: PURCHASE_ENTRY },
            // { id: 823, title: "sales_return", url: SALES_RETURN },
            // { id: 824, title: "purchase_return", url: PURCHASE_RETURN },
            { id: 825, title: "bank_entry", url: BANK_TRANSACTIONS },
            { id: 826, title: "payment_entry", url: PAYMENT_TRANSACTIONS },
            { id: 827, title: "receipt_entry", url: RECEIPT_TRANSACTIONS },
            { id: 828, title: "journal_entry", url: JOURNAL_TRANSACTIONS },
          ],
        },
        {
          id: 83,
          title: "report",
          url: "",
          icon: "report_sm",
          // menuCode: TRANSACTIONS_MC,
          innerSubMenu: [
            { id: 831, title: "account_ledger", url: ACCOUNT_LADGER_REPORT },
            // { id: 842, title: "item_ledger", url: ITEM_LADGER_REPORT },
          ],
        },
      ],
    },
    {
      id: 80,
      title: 'mn_business_reg',
      icon: 'mn_bus_reg',
      // url: BUSINESS_TYPE,
      url: Capacitor.getPlatform() !== 'ios' ? BUSINESS_TYPE:'',
      isBrowser:true,
      param:{
        type:BUSINESS_REG_SLUG
      }
    },
    {
      id: 81,
      title: 'mn_customer_reg',
      icon: 'mn_cst_reg',
      url: REG_STEP1,
      param:{
        type:CUSTOMER_REG_SLUG
      }
    },
    {
      id:79,
      title: "mn_billing_history",
      // url: RECEIVED_PAYMENTS,
      url: Capacitor.getPlatform() !== 'ios' ? RECEIVED_PAYMENTS:'',
      isBrowser:true,
      icon: "rec_pay_sm",
      isChecked: false,
      menuCode: ''
    },
    {
      id: 9,
      title: "inquiries",
      url: INQUIRE_LIST,
      icon: "inq_sm",
      isChecked: false,
      menuCode: INQUIRIES_MC
    },
    {
      id: 10,
      title: "complains_tickets",
      url: '',
      icon: "complain_sm",
      isExpand: false,
      isChecked: false,
      subMenu: [
        {
          id: 101,
          title: "mn_tickets",
          url: COMPLAINS_LIST,
          icon: "tkt_sm",
          isChecked: false,
          menuCode: COMPLAINS_MC
        },
        {
          id: 102,
          title: "mn_subjects",
          url: COMPLAIN_SUBJECTS,
          icon: "subject_sm",
          isChecked: false,
          menuCode: SUBJECTS_MC
        }
      ],
    },
    {
      id: 111,
      title: "pg_tl_comments",
      url: COMMENTS_RT,
      // url: "",
      icon: "com_sm",
      isExpand: false,
      isChecked: false,
      isCounter:true
    },
    {
      id: 11,
      title: "mn_member_chart",
      url: REGISTRATION_REPORT,
      // url: "",
      icon: "chart_sm",
      isExpand: false,
      isChecked: false,
    },
    {
      id: 12,
      title: "settings",
      url: "",
      icon: "setting_sm",
      isExpand: false,
      isChecked: false,
      subMenu: [
        {
          id: 121,
          title: "profile",
          url: ADMIN_PROFILE_ROUTE,
          icon: "profile_sm",
          isChecked: false,
        },
        {
          id: 122,
          title: "mn_gal_sett",
          url: GALARRY_SETTINGS_ROUTE,
          icon: "gs_sm",
          isChecked: false,
        },
        {
          id: 123,
          title: "mn_sms",
          url: SMS_ROUTE,
          icon: "sms_sm",
          isChecked: false,
          menuCode: SMSTEMPLATES_MC
        },
        {
          id: 124,
          title: "mn_email",
          url: EMAIL_ROUTE,
          icon: "email_sm",
          isChecked: false,
          menuCode: EMAILTEMPLATES_MC
        },
        {
          id: 125,
          title: "mn_accounts",
          url: ACCOUNT_SETTING,
          icon: "account_sm",
          isChecked: false,
        },
        {
          id: 126,
          title: "mn_change_pass",
          url: CHANGE_PASSWORD,
          icon: "mn_cp",
          isChecked: false,
        },
        {
          id: 127,
          title: "mn_banner_adv_setting",
          url: ADVERTISE_SETTINGS,
          icon: "mn_adv_set",
          isChecked: false,
        },
        {
          id: 128,
          title: "mn_push_adv_setting",
          url: PUSH_ADVERTISE_SETTINGS,
          icon: "mn_adv_set",
          isChecked: false,
        },
      ],
    },
    // {
    //   id: 13,
    //   title: "mn_pricing",
    //   url: ADMIN_TABS_PRICING,
    //   icon: "mn_pricing",
    //   isExpand: false,
    //   isChecked: false,
    // },
  ];