import { Component, Input, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss'],
})
export class ProfileImageComponent implements OnInit {

  @Input() document:string;
  @Input() isUploadDocument:boolean=false;
  constructor(private popoverCtrl:PopoverController) {
      // this.document=this.navParms.get("value");
      LogData("ProfileImageComponent...document",this.document);
      
     }

  removePhoto(){
    this.popoverCtrl.dismiss(0);
  }

  openGallary(){
    this.popoverCtrl.dismiss(1);
  }

  openCamera(){
    this.popoverCtrl.dismiss(2);
  }

  uploadDocument(){
    this.popoverCtrl.dismiss(3);
  }

  ngOnInit() {}


}
