import { Component, Input, OnInit } from '@angular/core';
import { AdvertisementDetails } from 'src/app/models/advertisements-data';

@Component({
  selector: 'app-adv-card',
  templateUrl: './adv-card.component.html',
  styleUrls: ['./adv-card.component.scss'],
})
export class AdvCardComponent implements OnInit {

  @Input() isPushAdv:boolean=false;
  @Input() advertisementData:AdvertisementDetails;
  constructor() { }

  ngOnInit() {}

}
