import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
// import { PushNotifications } from '@capacitor/push-notifications';
import { BUSINESS_DETAIL } from "../utils/routes";
import { LogData } from "../utils/utils";
import { UtilsService } from "./uitils.services";


@Injectable({
    providedIn: 'root'
})
export class FcmServices {
    constructor(private utilsService:UtilsService) {

    }

    initPush() {
        if (Capacitor.getPlatform() !== 'web') {
            // this.registerPush();
        }
    }

    // private registerPush() {
    //     PushNotifications.requestPermissions().then((permission) => {
    //         if (permission.receive) {
    //             // Register with Apple / Google to receive push via APNS/FCM
    //             PushNotifications.register();
    //         } else {
    //             // No permission for push granted
    //         }
    //     });

    //     PushNotifications.addListener(
    //         'registration',
    //         (token) => {
    //             LogData('registration_token: ' + JSON.stringify(token.value));
    //         }
    //     );

    //     PushNotifications.addListener('registrationError', (error: any) => {
    //         LogData('registration_Error: ' + JSON.stringify(error));
    //     });

    //     PushNotifications.addListener(
    //         'pushNotificationReceived',
    //         async (notification) => {
    //             LogData('pushNotificationReceived_received: ' + JSON.stringify(notification));
    //         }
    //     );

    //     PushNotifications.addListener(
    //         'pushNotificationActionPerformed',
    //         async (notification) => {
    //             LogData('pushNotificationActionPerformed: ' + JSON.stringify(notification));
    //             const data = notification.notification.data;
    //             if (data.notiId) {
    //                 //   this.router.navigateByUrl(`/home/${data.detailsId}`);
    //                 this.utilsService.setNavigationRoute(BUSINESS_DETAIL + "/" + data.notiId);
    //             }
    //         }
    //     );
    // }
}