import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { LoadingController, ModalController } from "@ionic/angular";
import { Config } from "ng-otp-input/lib/models/config";
import { finalize } from "rxjs/operators";
import {
  AddressData,
  UserRegistrationRequestData,
} from "src/app/models/global-data";
import { CurrentUserData } from "src/app/models/user-data";
import { AuthServices } from "src/app/services/auth.services";
import { UtilsService } from "src/app/services/uitils.services";
import { UserServices } from "src/app/services/user.services";
import {
  BUSINESSES_USER_TYPE,
  BUSINESS_REG_SLUG,
  CUSTOMERS_USER_TYPE,
  CUSTOMER_ROLE_ID,
  CUSTOMER_TO_BUSINESS,
  FEMALE_GN,
  MALE_GN,
  OTHER_ID,
  OTP_TIMER,
} from "src/app/utils/constant";
import {
  ADDI_ADDRESS_KEY,
  ADDRESSLINE1_KEY,
  AGREE_KEY,
  BUSINESSLIST_ID_KEY,
  BUSINESS_NAME_KEY,
  DATEOFBIRTH_KEY,
  DISTRICT_ID_KEY,
  EMAIL_KEY,
  EMAIL_ON_KEY,
  F_NAME_KEY,
  GENDER_KEY,
  LAT_KEY,
  LNG_KEY,
  L_NAME_KEY,
  OTP_EMAIL_KEY,
  OTP_MOBILE_KEY,
  PASSWORD_KEY,
  PHONE_KEY,
  PHONE_ON_KEY,
  PINCODE_KEY,
  REFERENCE_KEY,
  REFERREL_CODE_KEY,
  STATE_ID_KEY,
  TALUKA_ID_KEY,
  USER_TYPE_KEY,
  VILLAGEAREA_ID_KEY,
  VILLAGEAREA_ID_OTHER_KEY,
  VILLAGE_ID_KEY,
  VILLAGE_ID_OTHER_KEY,
  WHATS_NO_ON_KEY,
} from "src/app/utils/params-data";
import { LogData } from "src/app/utils/utils";
import { ValidationMessages } from "src/app/validators/validations";

@Component({
  selector: "app-varify-account",
  templateUrl: "./varify-account.component.html",
  styleUrls: ["./varify-account.component.scss"],
})
export class VarifyAccountComponent implements OnInit {
  @Input() reference;
  @Input() mobile;
  @Input() email;
  @Input() isForgotPasswordFunctionality: boolean = false;
  isFormSubmit: boolean = false;
  @Input() isEditMode: boolean = false;
  @Input() editFormData: FormData;
  @Input() profileId;
  @Input() selectedType;
  @Input() convert;
  serverSideError;
  validationMessages = ValidationMessages;
  formData = new FormData();
  isOnlyEmail: boolean = false;
  maxtimeEmail;
  timerOn = true;
  otp: string;
  showOtpComponent = true;
  config: Config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "0",
    inputStyles: {
      width: "62px",
      height: "62px",
      background: "#ffffff",
      "box-shadow": "0px 4px 9px rgba(0, 0, 0, 0.08)",
      "border-radius": "12px",
      color: "#000000",
      "font-weight": "bold",
      "text-align": "center",
      "font-size": "24px",
      "--padding-start": "0px",
      "margin-right": "14px",
    },
  };
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  constructor(
    private modalController: ModalController,
    private loadingCtrl: LoadingController,
    private utilsService: UtilsService,
    private userServices: UserServices,
    private authServices: AuthServices
  ) {}

  ngOnInit() {
    // this.phone=this.utilsService.globalData.userRegitrationData.phone;
    // this.email=this.utilsService.globalData.userRegitrationData.email;
    this.timer(OTP_TIMER);
  }

  ngAfterViewInit() {
    LogData("ngAfterViewInit().....call");
    setTimeout(() => {
      this.ngOtpInput.setFocus();
    }, 500);
  }

  varyfyOtp(ev) {
    // this.modalController.dismiss('data');
    this.isFormSubmit = true;
    if (this.otp && this.otp.length === 4) {
      this.setVaryfyOTPCall();
    }
  }

  setVaryfyOTPCall() {
    if (this.isEditMode) {
      this.editFormData.append(OTP_EMAIL_KEY, this.otp);
      this.editFormData.append(OTP_MOBILE_KEY, this.otp);
      this.userServices.userProfileUpdateApicall(
        this.editFormData,
        this.profileId
      );
    } else {
      if (!this.isForgotPasswordFunctionality) {
        this.customerRegitrationApiCall();
      } else {
        this.forgotPasswordOTPVarifyApiCall();
      }
    }
  }

  onOtpChange(ev) {
    LogData("onOtpChange...", ev);
    this.otp = ev;
  }

  resendOTP() {
    if (!this.timerOn) {
      this.setClearData();
      // this.modalController.dismiss();
      if (this.isEditMode) {
        this.resendOTPApiCallForEdit();
        // this.userServices.sendOtpEdit(this.profileId,this.editFormData,this.email,this.mobile,this.authServices.getUserToken());
      } else {
        if (this.isForgotPasswordFunctionality) {
          this.resendOTPApiCallForForgotPassword();
        } else {
          this.resendOTPApiCall();
        }
      }
    }
  }
  setClearData() {
    this.otp = "";
    this.ngOtpInput.setValue("");
    this.isFormSubmit = false;
    this.serverSideError = "";
    this.timerOn = false;
  }

  timer(remaining) {
    let m: any = Math.floor(remaining / 60);
    let s: any = remaining % 60;

    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;
    this.maxtimeEmail = m + ":" + s;
    remaining -= 1;

    if (remaining >= 0 && this.timerOn) {
      setTimeout(() => {
        // LogData("Timer_remaining", remaining);
        this.timer(remaining);
      }, 1000);
      return;
    } else {
      this.timerOn = false;
    }

    if (!this.timerOn) {
      // Do validate stuff here
      return;
    }

    // Do timeout stuff here
    // alert('Timeout for otp');
  }

  initTimerStart() {
    setTimeout(() => {
      this.timerOn = true;
      this.timer(OTP_TIMER);
    }, 1000);
  }

  async resendOTPApiCallForEdit() {
    const loading = await this.loadingCtrl.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    const formData = new FormData();
    formData.append(PHONE_KEY, this.mobile);
    formData.append(EMAIL_KEY, this.email);
    this.userServices
      .sendOTPForEdit(formData, this.authServices.getUserToken())
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("customerSendOTP_res", res);
          res = this.utilsService.getDecriptReasponse(res);
          this.initTimerStart();
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("customerSendOTP_error", err);
          this.userServices.logoutApiCall(err);
        }
      );
  }

  async resendOTPApiCall() {
    const loading = await this.loadingCtrl.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    const formData = new FormData();
    const userRegitrationData: UserRegistrationRequestData =
      this.utilsService.globalData.userRegitrationData;
    formData.append(
      USER_TYPE_KEY,
      this.utilsService.isBusinessSelect
        ? BUSINESSES_USER_TYPE
        : CUSTOMERS_USER_TYPE
    );
    formData.append(F_NAME_KEY, userRegitrationData.f_name);
    formData.append(L_NAME_KEY, userRegitrationData.l_name);
    formData.append(PHONE_KEY, userRegitrationData.phone);
    formData.append(EMAIL_KEY, userRegitrationData.email);
    this.userServices
      .sendOTP(formData)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("customerSendOTP_res", res);
          res = this.utilsService.getDecriptReasponse(res);
          if (res) {
            this.setClearData();
            // setTimeout(() => {
            //   this.timerOn = true;
            //   this.timer(OTP_TIMER);
            // }, 1000);
            this.initTimerStart();
          }
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("customerSendOTP_error", err);
        }
      );
  }

  async resendOTPApiCallForForgotPassword() {
    const loading = await this.loadingCtrl.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    const formData = new FormData();
    formData.append(REFERENCE_KEY, this.reference);
    this.userServices
      .forgotPassword(formData)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("resendOTPApiCallForForgotPassword_res", res);
          res = this.utilsService.getDecriptReasponse(res);
          if (res) {
            this.setClearData();
            // setTimeout(() => {
            //   this.timerOn = true;
            //   this.timer(OTP_TIMER);
            // }, 1000);
            this.initTimerStart();
          }
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("resendOTPApiCallForForgotPassword_error", err);
        }
      );
  }

  async customerRegitrationApiCall() {
    const loading = await this.loadingCtrl.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    const formData = new FormData();
    const userRegitrationData: UserRegistrationRequestData =
      this.utilsService.globalData.userRegitrationData;
    const addressData: AddressData =
      this.utilsService.globalData.userRegitrationData.address_data;
    formData.append(
      USER_TYPE_KEY,
      this.selectedType === BUSINESS_REG_SLUG
        ? BUSINESSES_USER_TYPE
        : CUSTOMERS_USER_TYPE
    );
    formData.append(F_NAME_KEY, userRegitrationData.f_name);
    formData.append(L_NAME_KEY, userRegitrationData.l_name);
    formData.append(PHONE_KEY, userRegitrationData.phone);
    formData.append(PASSWORD_KEY, userRegitrationData.password);
    if (userRegitrationData.referral_code) {
      formData.append(REFERREL_CODE_KEY, userRegitrationData.referral_code);
    }
  
    if (this.selectedType === BUSINESS_REG_SLUG) {
      formData.append(EMAIL_KEY, userRegitrationData.email);
      formData.append(PHONE_ON_KEY, "1");
      formData.append(EMAIL_ON_KEY, "1");
      formData.append(WHATS_NO_ON_KEY, "1");
      if (userRegitrationData.dateofbirth) {
        formData.append(
          DATEOFBIRTH_KEY,
          this.utilsService.getDateTimeFixFormat(userRegitrationData.dateofbirth)
        );
      } else {
        formData.append(DATEOFBIRTH_KEY, "");
      }
      if (userRegitrationData.gender) {
        formData.append(
          GENDER_KEY,
          "" + (userRegitrationData.gender === MALE_GN ? MALE_GN : FEMALE_GN)
        );
      } else {
        formData.append(GENDER_KEY, "");
      }
      formData.append(
        BUSINESSLIST_ID_KEY,
        this.utilsService.globalData.businesslist_id
      );
      formData.append(BUSINESS_NAME_KEY, userRegitrationData.business_name);
      //address
      formData.append(STATE_ID_KEY, "" + addressData.state_id);
      formData.append(DISTRICT_ID_KEY, "" + addressData.district_id);
      formData.append(TALUKA_ID_KEY, "" + addressData.taluka_id);
      formData.append(VILLAGE_ID_KEY, "" + addressData.village_id);
      formData.append(VILLAGEAREA_ID_KEY, "" + addressData.villagearea_id);

      if (addressData.village_id !== OTHER_ID) {
      } else {
        formData.append(VILLAGEAREA_ID_KEY, "");
      }
      if (
        addressData.village_id === OTHER_ID ||
        addressData.villagearea_id === OTHER_ID
      ) {
        formData.append(PINCODE_KEY, "" + addressData.pincode);
      }
      if (addressData.addi_address) {
        formData.append(ADDI_ADDRESS_KEY, addressData.addi_address);
      }
      if (addressData.addressline1) {
        formData.append(ADDRESSLINE1_KEY, addressData.addressline1);
      }
      formData.append(AGREE_KEY, "" + addressData.agree);
      formData.append(OTP_EMAIL_KEY, this.otp);
    }

    formData.append(OTP_MOBILE_KEY, this.otp);
    this.userServices
      .userRegistration(formData)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("customerRegitrationApiCall_res", res);
          res = this.utilsService.getDecriptReasponse(res);
          if (res && res.result) {
            // this.utilsService.currentUserData = res.result.currentUserData;
            if (res.result.responce && res.result.responce.otherSettings) {
              this.authServices.setUserOtherSettingsData(
                res.result.responce.otherSettings
              );
            }
            const c_userdata = res.result.currentUserData;
            this.utilsService.currentUserData = c_userdata;
            this.utilsService.accountsData = res.result.responce.Accounts;
            this.authServices.setForIdNotlogin(c_userdata.id);
            // this.authServices.setUserLoginData(res.result.responce.currentUserData);
            // this.authServices.setUserToken(res.token);
            //start
            // if (
            //   !this.selectedType &&
            //   c_userdata.userprofile &&
            //   c_userdata.userprofile.user_type === BUSINESSES_USER_TYPE
            // ) {
            //   this.setLoginData(res,c_userdata);
            // }
            //end
            if(this.authServices.getUserRoll()){
              if(this.convert && this.convert === CUSTOMER_TO_BUSINESS){
                this.setLoginData(res,c_userdata);
              }
            }else{
              this.setLoginData(res,c_userdata);
            }

            this.modalController.dismiss("data");
          }
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("customerRegitrationApiCall_error", err);
          this.serverSideError = this.utilsService.getServerError(err);
        }
      );
  }

  setLoginData(res:any,c_userdata){
    this.authServices.setUserLoginData(c_userdata);
    this.authServices.setUserToken(res.result.token);
    if (res.result.getAllowPermissionApi) {
      this.authServices.setAllowPermission(
        res.result.getAllowPermissionApi
      );
    }
  }

  async forgotPasswordOTPVarifyApiCall() {
    const loading = await this.loadingCtrl.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    const formData = new FormData();
    formData.append(REFERENCE_KEY, this.reference);
    formData.append(OTP_EMAIL_KEY, this.otp);
    formData.append(OTP_MOBILE_KEY, this.otp);
    this.userServices
      .forgotPasswordOTPVarify(formData)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("forgotPasswordOTPVarifyApiCall_res", res);
          res = this.utilsService.getDecriptReasponse(res);
          if (res) {
            // this.authServices.setUserLoginData(res.result.responce.currentUserData);
            // this.authServices.setUserToken(res.token);
            this.utilsService.resettoken = res.result.responce.resettoken;
            this.modalController.dismiss("data");
          }
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("forgotPasswordOTPVarifyApiCall_error", err);
          this.serverSideError = this.utilsService.getServerError(err);
        }
      );
  }
}
