import { Component, Input, OnInit } from '@angular/core';
import { BusinessDirectoryDetails } from 'src/app/models/business-director-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { ServicesCardCompneySliderConfig, WebCategorySliderConfig } from 'src/app/utils/slider.config';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-services-card',
  templateUrl: './services-card.component.html',
  styleUrls: ['./services-card.component.scss'],
})
export class ServicesCardComponent implements OnInit {

  @Input() businessDetail:BusinessDirectoryDetails;
  servicesCardCompneySliderConfig=ServicesCardCompneySliderConfig;
  constructor(public utilsService:UtilsService) { }

  ngOnInit() {
  }

  isAnyServices(){
    let flag=false;
    if(this.businessDetail){
      if((this.businessDetail.productcompany_id && this.businessDetail.productcompany_id.length > 0) ||
      (this.businessDetail.TradingtypesSales && this.businessDetail.TradingtypesSales.data &&
        this.businessDetail.TradingtypesSales.data.length > 0) ||
      (this.businessDetail.TradingtypesService && this.businessDetail.TradingtypesService.data && 
        this.businessDetail.TradingtypesService.data.length > 0)){
          flag=true;
        }
    }
    return flag;
  }
  
  
}
