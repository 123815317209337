<form [formGroup]="commonForm">
  <ion-label class="lbl-med-12" *ngIf="lbl">{{lbl | getLanguagePipe}} :</ion-label>

  <ion-item lines="none" class="mt-4" [ngClass]="utilsService.getValidCss(commonForm.get(controlName),isFormSubmit)">
    <!-- DATE -->
    <ng-container *ngIf="inputTypeEnum.DATE === inputType">
      <mat-form-field class="w-100 mtn-8">
        <div class="flex flex-row">
          <input matInput  [ngClass]="isDisabled ? 'lbl-med-op05-15':'lbl-med-15'" (click)="picker.open()" readonly [max]="maxDate" [min]="!isNoMin ? minDate:''"
          [matDatepicker]="picker" [formControlName]="controlName" (dateChange)="onDateValueChange($event)">
          <ion-icon *ngIf="commonForm.get(controlName).value && !isDisabled" name="close" class="ft-22 mr-12" (click)="clearDate()"></ion-icon>
          </div>
       
        <mat-datepicker-toggle matSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon>
            <ion-icon *ngIf="!isDisabled" [src]="'cal' | getImagePipe" class="ft-20"></ion-icon>
            <ion-icon *ngIf="isDisabled" [src]="'ic_cal_grey' | getImagePipe" class="ft-20"></ion-icon>
          </mat-icon>
          <!-- <mat-icon matDatepickerToggleIcon></mat-icon> -->
          <!-- <ion-icon [src]="'cal' | getImagePipe" class="ft-20"></ion-icon> -->
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </ng-container>
    <!-- DATE_TIME -->
    <ng-container *ngIf="inputTypeEnum.DATE_TIME === inputType">
      <mat-form-field class="w-100 mtn-8">
        <input matInput class="lbl-med-15" [ngxMatDatetimePicker]="picker" (click)="picker.open()" readonly
          [max]="maxDate" [min]="minDate" [formControlName]="controlName" (dateChange)="onDateTimeValueChange($event)">
        <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
        <mat-datepicker-toggle matSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon>
            <ion-icon [src]="'cal' | getImagePipe" class="ft-20"></ion-icon>
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </ng-container>
    <!-- TEXT_AREA -->
    <ng-container *ngIf="inputTypeEnum.TEXT_AREA === inputType">
      <ion-textarea [cols]="cols" [rows]="rows" [formControlName]="controlName" [placeholder]="placeHolder ? (placeHolder | getLanguagePipe):''">
      </ion-textarea>
    </ng-container>
    <!-- ION_SELECT -->
    <ng-container *ngIf="inputTypeEnum.SELECTION === inputType">
      <ion-select interface="popover" [formControlName]="controlName" (ionChange)="onChangeData($event)">
        <ion-select-option [value]="item.value" *ngFor="let item of dataList">{{ item.text }}
        </ion-select-option>
      </ion-select>
    </ng-container>
     <!-- DECIMAL -->
     <ng-container *ngIf="inputTypeEnum.DECIMAL === inputType">
      <ion-input id="ionInput"  (ionChange)="onChangeData($event)" (ionInput)="utilsService.onlyPositiveNumber($event)" [ngClass]="isRSSymbol ? (commonForm.get(controlName).value ? 'amount':''):''" [formControlName]="controlName"  type="number">
      </ion-input>
    </ng-container>
     <!-- NUMERIC -->
     <ng-container *ngIf="inputTypeEnum.NUMERIC === inputType">
      <ion-input id="ionInput" (ionChange)="onChangeData($event)" (ionInput)="utilsService.onlyNumericNumber($event)" [ngClass]="isRSSymbol ? (commonForm.get(controlName).value ? 'amount':''):''" [formControlName]="controlName"  type="number">
      </ion-input>
    </ng-container>

  </ion-item>
  <app-character-count *ngIf="isCharCount && commonForm.get(controlName).value"
    [formValue]="commonForm.get(controlName).value">
  </app-character-count>
  <app-validation *ngIf="isValidation" [validationMessages]="valMessage" [ValidationForm]="commonForm"
    [type]="controlName" [isFormSubmit]="isFormSubmit"></app-validation>
  <app-validation *ngIf="serverSideError && isServerSideError" [message]="serverSideError" [isServerSide]="true">
  </app-validation>
</form>