<ion-grid>
  <ion-row class="center-row">
    <ion-col style="align-self: center" class="no-margin-padding" [attrSizeXl]="'5'" appSizeAttribute>
      <ion-row>
        <ng-container *ngFor="let item of commentsList;let isfirst=first">
        <ion-col class="p-0" size="12">
          <div class="bus-details-card" (click)="openComment(item);$event.stopPropagation();">
            <ion-col class="p-0 pt-4" [attrSizeXl]="'6'" appSizeAttribute>
              <div class="pl-16 pr-16 pb-16">
                <app-comment-avtar  [img]="utilsService.getComImage(utilsService.getComUserDocments(item))" [name]="utilsService.getFullName(item)" [comment]="item.review" [date]="item.created"></app-comment-avtar>
                <div class="pl-44 mt-8" *ngIf="item.ratingschilds && item.ratingschilds.length > 0">
                  <app-comment-avtar [img]="utilsService.getComImage(this.utilsService.getComUserDocments(this.item.ratingschilds[0]))" [avtCls]="'avt-32'" [name]="utilsService.getFullName(item.ratingschilds[0],true)" [comment]="item.ratingschilds[0].review"></app-comment-avtar>
                </div>
                <div  class="flex-row-center mt-16 ion-justify-content-end" *ngIf="authServices.isBusinessRoll() && isSame && (!item.ratingschilds || item.ratingschilds.length === 0)">
                  <hr class="divider1 w-20" />
                  <ion-text class="lbl-11 ml-16" (click)="openComment(item);$event.stopPropagation();">{{'lbl_reply_com' | getLanguagePipe}}</ion-text>
                </div>
              </div>
            </ion-col>
          </div>
        </ion-col>
        </ng-container>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>