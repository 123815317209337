<div class="adv-card flex flex-col p-12">
  <ion-item class="item-0" lines="none">
    <ion-text slot="start" class="run-ad">Running Ad</ion-text>
    <div slot="end">
      <ion-icon [src]="'ic_cal_g' | getImagePipe"></ion-icon>
        <ion-text class="date-lbl ml-8">7 Feb 2022 | </ion-text>
        <ion-text class="date-lbl">31 Feb 2022</ion-text>
    </div>
  </ion-item>
  <hr class="divider1 mt-8" />
  <div class="flex flex-row mt-12">
    <div class="ver-line">
    </div>
    <div class="flex flex-col ml-12 w-100">
      <ion-text class="lbl-bold-18" *ngIf="advertisementData.userprofile_id && advertisementData.userprofile_id.length > 0">{{advertisementData.userprofile_id[0].text}}</ion-text>
      <!-- <ion-text class="disc-lbl mt-8">Clothing for womens</ion-text> -->
      <ion-row class="mt-2" *ngIf="!isPushAdv">
        <ion-col *ngIf="advertisementData.advertise_space && advertisementData.advertise_space.length > 0">
          <ion-item class="item-0" lines="none">
            <ion-icon [src]="'adv_va' | getImagePipe">
            </ion-icon>
            <ion-text class="disc-lbl ml-4">{{advertisementData.advertise_space[0].text}}</ion-text>
          </ion-item>
        </ion-col>
        <ion-col *ngIf="advertisementData.display && advertisementData.display.length > 0">
          <ion-item class="item-0" lines="none">
            <ion-icon [src]="'adv_line' | getImagePipe">
            </ion-icon>
            <ion-text class="disc-lbl ml-4">{{advertisementData.display[0].text}}</ion-text>
          </ion-item>
        </ion-col>
        <ion-col>
          <ion-item class="item-0" lines="none">
            <ion-icon [src]="'adv_rs' | getImagePipe">
            </ion-icon>
            <ion-text class="disc-lbl ml-4">{{advertisementData.cost}}</ion-text>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row class="mt-2" *ngIf="isPushAdv">
        <ion-col *ngIf="advertisementData.noofnotification">
          <ion-item class="item-0" lines="none">
            <ion-icon [src]="'adv_noti' | getImagePipe">
            </ion-icon>
            <ion-text class="disc-lbl ml-4">{{advertisementData.noofnotification}} {{'lbl_adv_qty' | getLanguagePipe}}.</ion-text>
          </ion-item>
        </ion-col>
        <!-- <ion-col>
          <ion-item class="item-0" lines="none">
            <ion-icon [src]="'adv_freq' | getImagePipe">
            </ion-icon>
            <ion-text class="disc-lbl ml-4">400 Freq.</ion-text>
          </ion-item>
        </ion-col> -->
        <ion-col>
          <ion-item class="item-0" lines="none">
            <ion-icon [src]="'adv_rs' | getImagePipe">
            </ion-icon>
            <ion-text class="disc-lbl ml-4">{{advertisementData.cost}}</ion-text>
          </ion-item>
        </ion-col>
      </ion-row>
    </div>
  </div>
</div>