import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoadingController, PopoverController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { CommonData } from 'src/app/models/common-data';
import { AdminPopoverMenu, BusinessPopoverMenu, CustomerPopoverMenu, DealerPopoverMenu, StaffPopoverMenu } from 'src/app/models/profile-menu';
import { UserDocument, UserprofileData } from 'src/app/models/user-profile-data';
import { AuthServices } from 'src/app/services/auth.services';
import { UtilsService } from 'src/app/services/uitils.services';
import { UserServices } from 'src/app/services/user.services';
import { BUSINESS_ROLE_ID, MALE_GN, CUSTOMER_ROLE_ID, YES, INDEX_ACCESS, SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID, LOGOUT_CODE } from 'src/app/utils/constant';
import { HOME_ROUTE, PROFILE_ROUTE, SEARCH_ROUTE, NOTIFICATIONS_ROUTE, PRIVACY_POLICY, INQUIRY_ROUTE, CHAT_ROUTE, TAB2_ROUTE, BUSINESS_DIRECTORY_LIST, CONTACTUS_ROUTE, MAIN_HOME, LOGIN_ROUTE, BUSINESS_TYPE, PRICING_RT,SELECT_TYPE } from 'src/app/utils/routes';
import { LogData } from 'src/app/utils/utils';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { LanguagePopoverComponent } from '../language-popover/language-popover.component';
import { finalize } from "rxjs/operators";
import {  INQUIRIES_MC } from "src/app/models/app-menu/menu-code";

@Component({
  selector: 'app-web-top-bar',
  templateUrl: './web-top-bar.component.html',
  styleUrls: ['./web-top-bar.component.scss'],
})
export class WebTopBarComponent implements OnInit, OnDestroy {
  @Input() selectedTab;
  selectedFilter:CommonData;
  selectedFilterMenu:CommonData;
  isExpand: boolean = false;
  isExpandForUser: boolean = false;
  userProfileData: UserprofileData;
  userProfileImg: UserDocument;
  businessUserLogoImg: UserDocument;
  isBusinessUser: boolean = false;
  CONST_DATA = {
    PRICING_RT,INDEX_ACCESS, INQUIRIES_MC, PRIVACY_POLICY, CHAT_ROUTE, MALE_GN, BUSINESS_ROLE_ID, CUSTOMER_ROLE_ID, LOGIN_ROUTE, HOME_ROUTE, PROFILE_ROUTE, SEARCH_ROUTE, NOTIFICATIONS_ROUTE, INQUIRY_ROUTE, BUSINESS_DIRECTORY_LIST, CONTACTUS_ROUTE, MAIN_HOME

  };
  
  // updateUserProfile$: Observable<boolean>;
  // updateUserProfileSubscription: Subscription;
  isSuccess$: Observable<boolean>;
  isSuccessSubscription: Subscription;
  // isCotactUsTab:boolean=true;
  isLoginUser:boolean=false;
  languagLits:Array<CommonData>=[];
  constructor(public authservices: AuthServices,
    public utilServices: UtilsService,
    private popCtrl: PopoverController,
    private userServices: UserServices,
    private loadingController:LoadingController) {
    this.subScriptionCall();
  }

  ngOnInit() {
    // this.getLanguage();
    if(this.authservices.getUserLoginData()){
      this.isLoginUser=true;
    }
    // if(this.authservices.getUserLoginData()){
    //   const allPermissions: Array<CommonData> = this.authservices.getAllowPermission();
    //   LogData("Allowpermission_menu_web", allPermissions);
    //   if(allPermissions && allPermissions.length > 0){
    //     const findContactus=allPermissions.find((element)=> element.value === INQUIRIES_MC);
    //     if(!findContactus){
    //       this.isCotactUsTab=false;
    //     }
    //   }else{
    //     this.isCotactUsTab=false;
    //   }
    // }else{
    //   this.isCotactUsTab=true;
    // }

    this.initData();
  }

  initData() {
    if(this.authservices.getLanguageArr()){
      this.languagLits=this.authservices.getLanguageArr();
      this.selectedFilter=this.authservices.getLanguage();
    }
    if (this.authservices.getUserLoginData()) {
      const currentuser = this.authservices.getUserLoginData();
      LogData("currentuser...", currentuser);
      this.isBusinessUser = this.authservices.getUserRoll() === BUSINESS_ROLE_ID ? true : false;
      this.userProfileData = currentuser.userprofile;
      if (this.userProfileData && this.userProfileData.documents && this.userProfileData.documents.length > 0) {
        if (!this.isBusinessUser) {
          this.userProfileImg = this.utilServices.getUserProfileImg(this.userProfileData.documents);
        } else {
          this.businessUserLogoImg = this.utilServices.getUserBusinessLogoImg(this.userProfileData.documents);
        }
      } else {
        this.userProfileImg = null;
        this.businessUserLogoImg = null;
      }
      LogData("businessUserLogoImg..", this.businessUserLogoImg);

    }
  }

  subScriptionCall() {
    //when userProfile Update
    this.isSuccess$ = this.utilServices.isSuccess$;
    this.isSuccessSubscription = this.isSuccess$.subscribe((value) => {
      LogData("isSuccessSubscription....call", value);
      if (value) {
        this.initData();
      }
    });
  }

  onClickListBusiness() {
    // this.headerMenuClick(item);
    // this.utilServices.setNavigationRoute(BUSINESS_TYPE);
    this.utilServices.setNavigationRoute(SELECT_TYPE);
    
  }

  openLoginPage() {
    if (!this.authservices.getUserLoginData()) {
      this.utilServices.setNavigationRoute(LOGIN_ROUTE);
    }
  }

  onClickSignIn() {
    this.utilServices.setNavigationRoute(LOGIN_ROUTE);
  }

  async openPopover(ev) {
    this.languagLits=this.authservices.getLanguageArr();
    LogData("languagLits",this.languagLits)
    this.isExpand = true;
    // const data = [{ id: 1, name: "English" }, { id: 2, name: "Hindi" }, { id: 3, name: "Gujarati" }];
    const modal = await this.popCtrl.create({
      component: LanguagePopoverComponent,
      componentProps: { listData: this.languagLits, selectedFilter: this.selectedFilter },
      event: ev,
      cssClass: 'custom-popover-4',
      backdropDismiss: true,
      // mode:"ios"
      // showBackdrop:false
    });

    modal.onDidDismiss().then((data) => {
      this.isExpand = false;
      LogData("onDidDismiss...", data);
      if (data && data.data) {
        this.selectedFilter = data.data;
        this.authservices.setLanguage(this.selectedFilter);
        this.getPoFileApi(this.selectedFilter.value);
      }
    });

    await modal.present();
  }

  async openPopoverForUser(ev) {
    this.isExpandForUser = true;
    let data=[];
    switch (this.authservices.getUserRoll()) {
      case SUPER_ADMIN_ROLE_ID:
        data=AdminPopoverMenu;
        break;
      case ADMIN_ROLE_ID:
        data=AdminPopoverMenu;
        break;
      case BUSINESS_ROLE_ID:
        data=BusinessPopoverMenu;
        break;
      case CUSTOMER_ROLE_ID:
        data=CustomerPopoverMenu;
        break;
      case DEALER_ROLE_ID:
        data=DealerPopoverMenu;
        break;
      case STAFFS_ROLL_ID:
        data=StaffPopoverMenu;
        break;
    }
    const modal = await this.popCtrl.create({
      component: LanguagePopoverComponent,
      componentProps: { listData: data,isTraslate:true,selectedFilter:this.selectedFilterMenu },
      event: ev,
      cssClass: 'custom-popover-4',
      backdropDismiss: true,
      // mode:"ios"
      // showBackdrop:false
    });

    modal.onDidDismiss().then((data) => {
      this.isExpandForUser = false;
      LogData("onDidDismiss...", data);
      if (data && data.data) {
        this.selectedFilterMenu=data.data;
        if(data.data.code && data.data.code === LOGOUT_CODE){
          this.logoutClick();
        }else{
          if(data.data.url){
            this.utilServices.setNavigationRouteQuery(data.data.url,data.data.param);
          }else{
            if(data.data.isBrowser){
              this.utilServices.openSfariViewForReg();
            }
            // this.utilServices.presentToast('Comming soon.');
          }
          
        }
        
      }
    });

    await modal.present();
  }

  async logoutClick() {
    const modal = await this.popCtrl.create({
      component: ConfirmationComponent,
      componentProps: { msg: "are_you_sure_you_want_to_logout", icon: 'ic_logout' },
      cssClass: 'custom-popover',
      showBackdrop: true,
      backdropDismiss: false,
      // breakpoints: [0, 0.3, 0.5, 0.8],
      // initialBreakpoint: 0.5
    });

    modal.onDidDismiss().then((data) => {
      LogData("onDidDismiss...", data);
      if (data.data != null && data.data === YES) {
        this.authservices.logout();
        this.userServices.userLogoutApiCall();
      }
    });

    await modal.present();
  }


  async getPoFileApi(selectedLang) {
    const loading = await this.loadingController.create(
      this.utilServices.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    this.userServices.getPoFile(selectedLang)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe((res) => {
        LogData("getPoFile_res", res);
        res = this.utilServices.getDecriptReasponse(res);
          if (res && res.result && res.result.responce) {
          if (res.result.responce.getpo) {
            this.authservices.setLanguageData(res.result.responce.getpo);
          }
          if (res.result.responce.getLanguagesArr && res.result.responce.getLanguagesArr.length > 0) {
            const langArray = res.result.responce.getLanguagesArr;
            this.authservices.setLanguageArr(langArray);
            const findObj = langArray.find((ele) => ele.value === selectedLang);
            if (findObj) {
              this.authservices.setLanguage(findObj);
            }
          }
          this.utilServices.setUpdateSideMenu(true);
          this.utilServices.openForSuccessError(false, res.result.success);
          // this.utilServices.reloadApp();
          this.utilServices.initLanguageChange();
          // window.history.back();
        }
      }, err => {
        LogData("loader_dismiss");
        loading.dismiss();
        LogData("getPoFile_error", err);
      });
  }


  ngOnDestroy(): void {
    // if(this.updateUserProfileSubscription){
    //   this.updateUserProfileSubscription.unsubscribe();
    // }
    if (this.isSuccessSubscription) {
      this.isSuccessSubscription.unsubscribe();
    }
  }

}
