// localstoreage key

export const USER_LST_KEY = "user";
export const OTHER_SETTINGS_LST_KEY = "other_settings";
export const TOKEN_LST_KEY = "token";
export const ALLOW_PERMISSION_LST_KEY="allow_permission";
export const FORID_LST_KEY="forid";
export const APPSTORE_LINK_LST_KEY="appstorelink";
export const PLAYSTORE_LINK_LST_KEY="playstorelink";
export const BUSINESS_NAME_LST_KEY="business_name";
export const BUSINESS_STATUS_LST_KEY="business_status";


export const SUPER_ADMIN_ROLE_ID=1;
export const ADMIN_ROLE_ID = 2; //2
export const BUSINESS_ROLE_ID = 3;
export const CUSTOMER_ROLE_ID = 4;
export const DEALER_ROLE_ID = 5;
export const STAFFS_ROLL_ID = 6;
export const EMPLOYEE_ROLL_ID = 7;
export const WEB = "web";
export const ANDROID = "android";
export const IOS = "ios";
export const CUSTOMERS_USER_TYPE = "Customers";
export const BUSINESSES_USER_TYPE = "Businesses";
export const DEALERS_USER_TYPE = "Dealers";
export const STAFFS_USER_TYPE = "Staffs";
export const EMPLOYEE_USER_TYPE = "Employees";
export const NORMAL_USER_TYPE="Normal";
export const PUSH_USER_TYPE="Push";
export const RUPPES = "&#8377;";
export const OTP_TIMER = 180;
export const YES = "Yes";
export const No = "No";
export const COMPOSITION_TP = "C";
export const REGULAR_TP = "R";
export const MALE_GN = "M";
export const FEMALE_GN = "F";
export const SEARCH_DEBOUNCE_TIME = 700;
export const PRODUCT_COMPNEY_INDEX = 1;
export const TRADING_SERVICES_INDEX = 2;
export const TRADING_SALES_INDEX = 3;
export const PRODUCT_SERVICES_INDEX = 4;
export const PRODUCT_SALES_INDEX = 5;

export const LOGOUT_CODE="logout";
export const CANCEL="Cancel";

// segment
export const OVERVIEW = "Overview";
export const LOCATION = "Location";
export const DETAILS = "Details";
export const SERVICE = "Service";
export const CONTACT = "Contact";
export const GALLERY = "Gallery";
export const REVIEW="review";
export const GENERAL_SETTINGS = "gen_st";
export const SPECIFIC_BUSINESS_SETTINGS = "sp_bus_set";
export const REGULAR_PAYMENTS_SEG = "regular";
export const PENDING_PAYMENTS_SEG = "pending";
export const RATING_DESC="rating_desc";
export const RATING_ASC="rating_asc";
export const AZSORT_ASC="azsort_asc";
export const AZSORT_DESC="azsort_desc";
export const ZERO_RS="\u20b90.00";

export const GST_YES=1;
export const GST_NO=2;
export const GST_BOTH=3;

export const ALL = "All";
export const AUTO_MOBILES = "Auto Mobiles";
export const REAL_ESTATE = "Real Estate";
export const TRAVEL = "Travel";
export const ELECTRICIANS = "Electricians";
export const SEARCH_MORE = "Search More";
export const SELECT_LIST_ITEM = "Select";
export const OTHER_ID = "other";
export const DATE_TIME_FORMAT_FOR_USER = "YYYY-MM-DD HH:mm:ss";
export const DATE_TIME_FORMAT_FOR_USER_NO_SS = "YYYY-MM-DD HH:mm";
export const PER_PAGE = "10";
export const PAGE_BUSINESS_LIST = 1;
export const PAGE_TRADING_LIST = 2;
export const PAGE_PRODUCT_LIST = 3;
export const PAGE_PRODUCT_COMPNEY_LIST = 4;
export const PAGE_SUBJECT_LIST = 5;
export const PAGE_STAFF_LIST = 6;
export const PAGE_STATE = 1;
export const PAGE_DISTRCIT = 2;
export const PAGE_TALUKA = 3;
export const PAGE_VILLAGE = 4;
export const PAGE_VILLAGE_AREA = 5;
// export const PAGE_BD = 6;
export const PAGE_STAFF = 7;
export const PAGE_COMPLAINS = 8;
export const PAGE_INQ = 9;
export const PAGE_COMPL_SUB= 10;
export const PAGE_ALLOC_AREA=11;
export const PAGE_ADMIN_CUSTOMERS=12;
export const PAGE_ADMIN_BUSINESS=13;
export const PAGE_BUSINESS_DIRECTORY=14;
export const PAGE_ADV_BANNER=15;
export const PAGE_ADV_PUSH=16;
export const PAGE_ACC_GROUP=17;
export const PAGE_ACC_LEDGER=18;
export const PAGE_ITEM_GROUP=19;
export const PAGE_ITEM_LADGER=20;
export const PAGE_RECEIVED_PAYMENTS=21;
export const PAGE_ADV_SETTING=22;
export const PAGE_PROMO_CODE=23;
export const PAGE_EMPLOYEE=24;
export const PAGE_REVIEW_COMMENTS=25;
export const PAGE_COMMENT_POPOVER="com_pop";
export const PAGE_BUSINESS_DETAIL="bus_detail";
export const PAGE_SIDE_MENU="side_menu";

export const WITHOUT_PAGE="wothout_page";

export const ADD_DATA = 1;
export const EDIT_DATA = 2;
export const DELETE_DATA = 3;

// transactiontype_id
export const BANK_TRANSACTIONTYPE_ID=1;
export const PAYMENT_TRANSACTIONTYPE_ID=2;
export const RECEIPT_TRANSACTIONTYPE_ID=3;
export const JOURNAL_TRANSACTIONTYPE_ID=4;

export const PAGE_BANK_TRANS=1;
export const PAGE_PAYMENT_TRANS=2;
export const PAGE_RECEIPT_TRANS=3;
export const PAGE_JOURNAL_TRANS=4;
export const PAGE_SALES_ENTRY=5;



//defalut images
export const COVER_DEFALUT_IMG="assets/img/bus_cover.png";
export const LOGO_DEAFLUT_IMG="assets/img/bus_d_logo.png";

//has access
export const INDEX_ACCESS="index";
export const ADD_ACCESS="add";
export const EDIT_ACCESS="edit";
export const VIEW_ACCESS="view";
export const DELETE_ACCESS="delete";
export const UPDATE_ACCESS="update";
export const UPDATESTATUS_ACCESS="status";

// limit chachter
export const BUS_TAG_TEXT_LIMIT=25;
export const BUS_KEYWORD_TEXT_LIMIT=30;
export const TEXT_AREA_LIMIT_M=500;
export const TEXT_AREA_LIMIT_S=100;
export const TEXT_AREA_LIMIT_2M=1000;
export const TEXT_AREA_LIMIT_L=1500;

// export const ;
// export const ;
// export const ;
// export const ;
// export const ;
// export const ;
// export const ;
// export const ;

// master names

export const getStatesArr = "getStatesArr";
export const getDistrictsArr = "getDistrictsArr";
export const getTalukasArr = "getTalukasArr";
export const getVillagesArr = "getVillagesArr";
export const getVillageareasArr = "getVillageareasArr";
export const businesslists = "businesslists";
export const getProductcompaniesArr = "getProductcompaniesArr";
export const getBusinesstypesArr = "getBusinesstypesArr";
export const getTradingtypesArr = "getTradingtypesArr";
export const getProductlistsArr = "getProductlistsArr";
export const getLanguagesArr = "getLanguagesArr";
export const getStatusArr = "getStatusArr";
export const getRolesArr = "getRolesArr";
export const getComplainSubjectsArr="getComplainSubjectsArr";
export const getInquriesforArr="getInquriesforArr";
export const getComplaininquiriesStatusArr="getComplaininquiriesStatusArr";
export const getComplainUsersArr="getComplainUsersArr";
export const getComplainInquiriesStaffsArr="getComplainInquiriesStaffsArr";
export const getTradingtypesSalesArr="getTradingtypesSalesArr";
export const getTradingtypesServiceArr="getTradingtypesServiceArr";
export const getAdCategoryArr="getAdCategoryArr";
export const getAdDisplayArr="getAdDisplayArr";
export const rolesUsersArr="rolesUsersArr";
export const getDesignationArr="getDesignationArr";
export const getGroupsArr="getGroupsArr";
export const getAccountLadgerArr="getAccountLadgerArr";
export const getPaymentMethodArr="getPaymentMethodArr";
export const getChequeStatusArr="getChequeStatusArr";
export const getItemgroupsArr="getItemgroupsArr";
export const getTaxcategoryArr="getTaxcategoryArr";
export const getUnitsArr="getUnitsArr";
export const getAccountStatusArr="getAccountStatusArr";
export const getAdFrequencyArr="getAdFrequencyArr";
export const getAdvertiseSpaceArr="getAdvertiseSpaceArr";
export const getPushFrequencyArr="getPushFrequencyArr";
export const getDiscounttypesArr="getDiscounttypesArr";
export const getBusinesslistsDetailsTypeArr="getBusinesslistsDetailsTypeArr";
export const getRatingsBusinessesArr="getRatingsBusinessesArr";
export const getRatingstatusArr="getRatingstatusArr";
//slug

export const BANNER_SLUG = "banner";
export const PUSH_SLUG = "push";
export const H_TOP_ADV="h_top";
export const H_MIDDLE_ADV="h_middle";
export const H_BOTTOM_ADV="h_bottom";
export const STATE_AD_CATEGORY="state";
export const DISTRICT_AD_CATEGORY="district";
export const TALUKA_AD_CATEGORY="taluka";
export const USERREGISTERAREAS_SC="Userregisterareas";
export const ADVSETS_SC="Advsets";
export const ADVERTISEMENTS_SC="advertisements"   
export const BANK_PM="bank";
export const PAYMENT_PM="payment";
export const RECEIPT_PM="receipt";
export const JOURNAL_PM="journal";

export const ADD_SLUG="add";
export const EDIT_SLUG="edit";

export const INPUTTYPE_CR="cr";
export const INPUTTYPE_DR="dr";

export const CUSTOMER_REG_SLUG="customer_registration";
export const BUSINESS_REG_SLUG="business_registration";

//'A','I','W'
export const A_APIMODE="A";
export const I_APIMODE="I";
export const W_APIMODE="W";

//API_KEYS 
// TEST
// export const RAZORPAY_KEY_ID="rzp_test_iquxrmY6tLO5td";
// export const RAZORPAY_KEY_SECRATE="IDinV6biF5W2BEDUaFgh83aO";
// LIVE
export const RAZORPAY_KEY_ID="rzp_live_Psc9JFs4jPkkLt";
export const RAZORPAY_KEY_SECRATE="bxBrsz9AADISrymDxHmMPCsD";

// social media links
export const FACEBOOK_LINK = "https://www.facebook.com/amarucity";
export const TWITTER_LINK = "https://twitter.com/amarucity";
export const LINKEDIN_LINK = "https://www.linkedin.com/company/amarucity";
export const INSTA_LINK = "https://www.instagram.com/amarucity/";
export const YOUTUBE_LINK="https://www.youtube.com/channel/UC9hxgvxlWZ0mixphytnCqLA";
export const PINTREST_LINK = "";

export const QR_CODE_TYPE="qr_code";
export const ONLINE_TYPE="online";
export const WALLET_TYPE="wallet";
export const OPEN_TYPE_NEW="new";
export const OPEN_TYPE_EDIT="edit";
export const USER_DELETE_ACCOUNT=1;

export const CUSTOMER_TO_BUSINESS="cst_to_bus";
export const DEALER_TO_CUSTOMER="dealer_to_cst";
export const BUSINESS_TO_CUSTOMER="bus_to_cst";
export const CUSTOMER_TO_DEALER="cst_to_dealer";

// Image sizes

export const FILE_SIZE_1MB=1024;
export const FILE_SIZE_80KB=80;
export const MIME_TYPE_JPG="image/jpeg";
export const MIME_TYPE_PNG="image/png";
export const MIME_TYPE_PDF="application/pdf";

export const NONE_VALUE="none";
export const PERCENTAGE_VALUE="per";
export const FIXED_VALUE="fix";

export const FREE_TRIAL_RADIO="trial";
export const DISCOUNT_RADIO="dis";
export const PRICE_RADIO="price";

export const REGISTRATION_TYPE="reg";
export const RENEWAL_TYPE="ren";
export const ADVANCE_PAYMENT="pay_advance";
export const PENDING_PAYMENT="pay_pending";
export const FREETRIAL_PAYEMNT="pay_freetrial";
export const RENEWAL_PAYMENT="pay_renewal";



// formdata print
// formData.forEach((value, key, parent) => {
//     LogData("key...", key);
//     LogData("value...", value);
//   });