export const environment = {
  production: true,
  appVersion: "5.0.0",
  tokenFlag: 0, // 0 means header with token,1 means url with token,2 means both

  //public
  // apiUrl: "http://103.140.191.31/amarucity-api/api/",
  // domainUrl: "http://103.140.191.31/amarucity-api/",
  // paymentRedirectionUrl: "http://103.140.191.31:8100/#/login",
  // displayUrl:"103.140.191.31:8100"

  //Local
  // apiUrl:"http://192.168.1.100/amarucity-api/api/",
  // domainUrl:"http://192.168.1.100/amarucity-api/",
  // paymentRedirectionUrl: "http://192.168.1.110:8100/#/login",
  // displayUrl:"192.168.1.110:8100"

  //  BK
  // apiUrl: "http://192.168.1.103/amarucity-api/api/",
  // domainUrl: "http://192.168.1.103/amarucity-api/",

  // //demo url
  // apiUrl: "https://www.demo.amarucity.com/api/",
  // domainUrl: "https://www.demo.amarucity.com/",
  // paymentRedirectionUrl: "https://www.web.amarucity.com/#/login",
  // displayUrl: "www.web.amarucity.com",

  // // Live url
  apiUrl:"https://www.api.amarucity.com/api/",
  domainUrl:"https://www.api.amarucity.com/",
  paymentRedirectionUrl:"https://www.amarucity.com/#/login",
  displayUrl:"www.amarucity.com"
};
