import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ADVERTISEMENT_PIC_KEY } from 'src/app/utils/params-data';
import { LogData } from 'src/app/utils/utils';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { PhotoUtilityComponent } from '../photo-utility/photo-utility.component';

@Component({
  selector: 'app-upload-pic-card',
  templateUrl: './upload-pic-card.component.html',
  styleUrls: ['./upload-pic-card.component.scss'],
})
export class UploadPicCardComponent implements OnInit {

  @Input() selectedCoverImg;
  userProfileImg;
  coverImgObj;
  @Input() title;
  @Input() discription;
  @Input() editTxt;
  @Input()marginTop='mt-12';
  @Input() displayLbl;
  @Input() index;
  @Input() isMain:boolean=false;
  @Output() getselectedFile:EventEmitter<any> = new EventEmitter();
  CONST_DATA = {
    ADVERTISEMENT_PIC_KEY
  }
  @ViewChild(PhotoUtilityComponent, { static: false }) photoUtilityComponent: PhotoUtilityComponent;
  constructor(private popCtrl: PopoverController) { }

  ngOnInit() { }
  selectedFile(ev) {
    LogData("selectedFile", ev);
    this.selectedCoverImg=ev;
    this.getselectedFile.emit(ev ? (this.index ? {index:this.index,data:ev}:ev):(this.index ? {index:this.index,data:''}:''))
  }

  openProfileImage() {
    // this.photoUtilityComponent.takePhoto();
    this.photoUtilityComponent.takePhoto((this.userProfileImg &&
      this.userProfileImg.id) ?
      this.userProfileImg.id : null);
  }


  removImage(item, isMultiple, keyindex, index) {
    if (item.id) {
      this.openConfirmation(item, isMultiple, keyindex, index);
    } else {
      this.selectedCoverImg = null;
      this.coverImgObj = null;
      this.getselectedFile.emit(null);
    }

  }

  async openConfirmation(item, isMultiple, keyIndex, index) {
    const modal = await this.popCtrl.create({
      component: ConfirmationComponent,
      componentProps: { msg: "are_you_sure_want_to_delete_the_photo", icon: 'ic_delete' },
      cssClass: 'custom-popover',
      showBackdrop: true,
      backdropDismiss: false,
      // breakpoints: [0, 0.3, 0.5, 0.8],
      // initialBreakpoint: 0.5
    });

    modal.onDidDismiss().then((data) => {
      LogData("onDidDismiss...", data);
      if (data.data != null) {
        this.selectedCoverImg = null;
        this.coverImgObj = null;
        this.getselectedFile.emit(null);
      }
    });

    await modal.present();
  }

}
