<ion-grid >
  <ion-row class="center-row">
    <ion-col style="align-self: center" class="no-margin-padding" [attrSizeXl]="'5'" appSizeAttribute>
      <div class="flex-col-center services-bg">
        
        <div class="bus-details-card" *ngIf="businessDetail.productcompany_id && businessDetail.productcompany_id.length > 0">
          <ion-row>
            <ion-col class="mt-12">
              <!-- [lable2]="'lbl_bus_de_comp_disc'" -->
              <app-name-icon [icon]="'compney_bl'" [lable1]="'lbl_bus_de_comp'"></app-name-icon>
              <hr class="divider2 w-100 mt-16 mb-8" />
              <ng-container >
                <ngx-slick-carousel class="carousel" 
                #slickModal="slick-carousel" 
                [config]="servicesCardCompneySliderConfig">
              <div ngxSlickItem *ngFor="let slide of businessDetail.productcompany_id;let isLast=last" class="slide">
                <app-product-compney-card [productCompneyData]="slide" [isLast]="isLast"></app-product-compney-card>
              </div>
              </ngx-slick-carousel>
              </ng-container>
              <!-- <ng-template #noProcom>
                <div>
                  <app-no-data-found [width]="'100'"></app-no-data-found>
                </div>
              </ng-template> -->
            </ion-col>
          </ion-row>
        </div>

        <div class="bus-details-card mt-16" *ngIf="businessDetail.TradingtypesSales && businessDetail.TradingtypesSales.data &&
        businessDetail.TradingtypesSales.data.length > 0">
          <ion-row>
            <ion-col class="mt-12">
              <!-- [lable2]="'lbl_bus_de_sale_disc'" -->
              <app-name-icon  [icon]="'sale_bl'" [lable1]="'lbl_bus_de_sales_product'" ></app-name-icon>
              <hr class="divider2 w-100 mt-16 mb-8" />
             <app-according-card  [dataArray]="businessDetail.TradingtypesSales.data"></app-according-card>
             <!-- <ng-template #noTradsales>
              <div>
                <app-no-data-found [width]="'100'"></app-no-data-found>
              </div>
            </ng-template> -->
            </ion-col>
          </ion-row>
        </div>

        <div class="bus-details-card mt-16" *ngIf="businessDetail.TradingtypesService && businessDetail.TradingtypesService.data && 
        businessDetail.TradingtypesService.data.length > 0">
          <ion-row>
            <ion-col class="mt-12">
              <app-name-icon  [icon]="'services_bl'" [lable1]="'lbl_bus_de_services'"></app-name-icon>
              <hr class="divider2 w-100 mt-16 mb-8" />
             <app-according-card  [dataArray]="businessDetail.TradingtypesService.data"></app-according-card>
             <!-- <ng-template #noTradservices>
              <div>
                <app-no-data-found [width]="'100'"></app-no-data-found>
              </div>
            </ng-template> -->
            </ion-col>
          </ion-row>
        </div>

        <!-- <ng-container *ngIf="!isAnyServices()">
          <div>
            <app-no-data-found [width]="'100'" [message]="'msg_no_bus_services'"></app-no-data-found>
          </div>
        </ng-container>
        -->
        </div>
    
    </ion-col>
  </ion-row>
</ion-grid>