import { COMPLAINS_MC, INQUIRIES_MC } from "./menu-code";
import {
  ACCOUNT_LADGER_REPORT,BUSINESS_TYPE,CHANGE_PASSWORD,COMPLAINS_LIST,INQUIRE_LIST,INVITE_EARN_RT,PRIVACY_POLICY,REG_STEP1,REG_STEP2,TAB1_ROUTE,TAB2_ROUTE,TAB6_ROUTE,TERMS_CONDITION,
} from "../../utils/routes";
import { BUSINESS_REG_SLUG, CUSTOMER_REG_SLUG } from "src/app/utils/constant";
import { Capacitor } from "@capacitor/core";

export const CustomerMenuData = [
    {
      id: 1000,
      title: "mn_visit_website",
      url: TAB1_ROUTE,
      icon: "ic_visit_web",
      isChecked: false,
  
    },
    {
      id: 1,
      title: "profile",
      url: TAB2_ROUTE,
      icon: "profile_sm",
      isChecked: false,
    },
    {
      id: 2,
      title: 'mn_contact_information',
      icon: 'ic_pur_info',
      url: REG_STEP1,
      isChecked: false,
    },
    {
      id: 3,
      title: 'address',
      icon: 'mn_address',
      url: REG_STEP2,
      isChecked: false,
    },
    {
      id: 4,
      title: 'change_password',
      icon: 'mn_cp',
      url: CHANGE_PASSWORD,
      isChecked: false,
    },
    // {
    //   id: 5,
    //   title: "mn_kyc_bank_details",
    //   url: "",
    //   icon: "mn_kyc_bank",
    //   isChecked: false,
    //   subMenu: [
    //     {
    //       id: 51,
    //       title: "mn_kyc",
    //       // url: KYC_DETAILS_RT,
    //       url:'',
    //       icon: "mn_kyc",
    //       isChecked: false,
    //     },
    //     {
    //       id: 52,
    //       title: "mn_bank_details",
    //       // url: BANK_DETAILS_RT,
    //       url:'',
    //       icon: "mn_kyc_bank",
    //       isChecked: false,
    //     },
    //   ],
    // },
    // {
    //   id: 53,
    //   title: 'mn_wallet',
    //   icon: 'mn_wallet',
    //   //url: WALLET_RT,
    //  url:"",
    //   isChecked: false,
    // },
    {
        id: 80,
        title: "accounts",
        url: "",
        icon: "acc_sm",
        isExpand: false,
        isChecked: false,
        // menuCode: TRANSACTIONS_MC,
        subMenu: [
          {
            id: 81,
            title: "report",
            url: "",
            icon: "report_sm",
            innerSubMenu: [
              { id: 831, title: "account_ledger", url: ACCOUNT_LADGER_REPORT },
              // { id: 842, title: "item_ledger", url: ITEM_LADGER_REPORT },
            ],
          },
        ],
      },
      {
        id: 99,
        title: "inquiries",
        url: INQUIRE_LIST,
        icon: "inq_sm",
        isChecked: false,
        menuCode: INQUIRIES_MC
      },
      {
        id: 100,
        title: "mn_tickets",
        url: COMPLAINS_LIST,
        icon: "loadge_sm",
        isChecked: false,
        menuCode: COMPLAINS_MC
      },
    {
      id: 6,
      title: 'mn_business_reg',
      icon: 'mn_bus_reg',
      // url: BUSINESS_TYPE,
      url: Capacitor.getPlatform() !== 'ios' ? BUSINESS_TYPE:'',
      isBrowser:true,
      param:{
        type:BUSINESS_REG_SLUG
      },
      isChecked: false,
    },
    {
      id: 7,
      title: 'mn_customer_reg',
      icon: 'mn_cst_reg',
      url: REG_STEP1,
      param:{
        type:CUSTOMER_REG_SLUG
      },
      isChecked: false,
    },
    {
      id: 8,
      title: 'mn_invite_earn',
      icon: 'mn_invite',
       url: INVITE_EARN_RT,
      //url: '',
      isChecked: false,
    },
    // {
    //   id: 9,
    //   title: "notification",
    //   // url: TAB4_ROUTE,
    //   url: '',
    //   icon: "notification_sm",
    //   isChecked: false,
    // },
    {
      id: 10,
      title: "mn_contactus",
      url: TAB6_ROUTE,
      icon: "mn_con_us",
      isChecked: false
    },
    {
      id: 11,
      title: "privacy",
      url: PRIVACY_POLICY,
      icon: "privacy_sm",
      isChecked: false,
    },
    {
      id: 12,
      title: "terms_of_use",
      url: TERMS_CONDITION,
      icon: "term_sm",
      isChecked: false,
    },
    // {
    //   id: 13,
    //   title: "mn_pricing",
    //   url: TABS_PRICING,
    //   icon: "mn_pricing",
    //   isExpand: false,
    //   isChecked: false,
    // },
  ];
  