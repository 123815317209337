import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoadingController, PopoverController } from '@ionic/angular';
import { element } from 'protractor';
import { finalize } from 'rxjs/operators';
import { BusinessFilter } from 'src/app/models/business-filter';
import { CommonData } from 'src/app/models/common-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { UserServices } from 'src/app/services/user.services';
import { businesslists, getProductcompaniesArr, getTradingtypesSalesArr, getTradingtypesServiceArr, 
  RATING_DESC,RATING_ASC,AZSORT_ASC,AZSORT_DESC,GST_YES,GST_NO,GST_BOTH } from 'src/app/utils/constant';
import { LogData } from 'src/app/utils/utils';
import { AddressComponent } from '../address/address.component';

@Component({
  selector: 'app-business-filter',
  templateUrl: './business-filter.component.html',
  styleUrls: ['./business-filter.component.scss'],
})
export class BusinessFilterComponent implements OnInit {

  // @Input() title;
  // sortFilterList: Array<CommonData> = [];
  // selectedSortBy = 'HtoL';
  // @Input() filterIndex: CommonData;
  // businessType = [{ value: 1, text: 'Retailer', isChecked: false },
  // { value: 2, text: 'Distributor', isChecked: false },
  // { value: 3, text: 'Service Provider', isChecked: false },
  // { value: 4, text: 'Wholesaler', isChecked: false }];
  // saleslist: Array<CommonData> = [];
  // serviceslist: Array<CommonData> = [];
  // gstlist: Array<CommonData> = [{ value: 1, text: 'Business Accept GST', isChecked: false },
  // { value: 2, text: 'Business Not Accpet GST', isChecked: false }];
  // postalCode;

  commonform: FormGroup;
  @Input() title;
  sortFilterList: Array<CommonData> = [];
  selectedSortBy = 'HtoL';
  @Input() selectedFilter: BusinessFilter;
  @Input() pageName;
  filterIndex:CommonData
  businessType: Array<CommonData> = [];
  productCompney: Array<CommonData> = [];
  saleslist: Array<CommonData> = [];
  serviceslist: Array<CommonData> = [];
  gstlist: Array<CommonData> = [{ value: 1, text: 'Business Accept GST', isChecked: false },
  { value: 2, text: 'Business Not Accpet GST', isChecked: false }];
  postalCode;
  CONST_DATA={
    RATING_DESC,RATING_ASC,AZSORT_ASC,AZSORT_DESC,GST_YES,GST_NO,GST_BOTH
  }
  
  @ViewChild(AddressComponent, { static: false }) addressComponent: AddressComponent;
  constructor(private popoverCtrl: PopoverController,
    private loadingCtrl: LoadingController,
    private utilsService: UtilsService,
    private userServices: UserServices,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    // this.saleslist = SalesData;
    // this.serviceslist = SalesData;
    this.buildForm();
    this.setFilterList();
    this.filterIndex = this.sortFilterList[0];
    this.getMasters();
  }

  buildForm() {
    this.commonform = this.formBuilder.group({
      sortby: [],
      businesslist_id: [],
      businesstype_id_sales: [1],
      businesstype_id_services: [2],
      tradingtype_id_sales: [],
      tradingtype_id_service: [],
      productcompany_id: [],
      isgst: [GST_BOTH],

    });
  }
  updateBuildForm(){
    LogData("updateBuildForm",this.selectedFilter);
    if(this.selectedFilter){
      if(this.selectedFilter.commonForm){
        this.commonform.patchValue(this.selectedFilter.commonForm);
      }
    }
  }

  getBusinessType(ev) {

  }

  getSearchValue(ev) {

  }

  setFilterList() {
    this.sortFilterList = [
      { value: 1, text: 'lbl_fl_sort', isChecked: true },
      { value: 2, text: 'lbl_fl_bus_type', isChecked: false },
      { value: 3, text: 'lbl_fl_sales', isChecked: false },
      { value: 4, text: 'lbl_fl_services', isChecked: false },
      { value: 5, text: 'lbl_fl_address', isChecked: false },
      // { value: 6, text: 'lbl_fl_prod_comp', isChecked: false },
      { value: 7, text: 'lbl_fl_gst', isChecked: false }
    ];
  }

  getSelectedBusinessTypeItem(ev) {
    LogData("getSelectedBusinessTypeItem", ev);
    if (ev && ev.data) {
      this.getCheckedValue(ev.data, 1);
    }
  }

  getSelectedSalesItem(ev) {
    LogData("getSelectedSalesItem", ev);
    if (ev && ev.data) {
      this.getCheckedValue(ev.data, 2);
    }
  }

  getSelectedServicesItem(ev) {
    LogData("getSelectedServicesItem", ev);
    if (ev && ev.data) {
      this.getCheckedValue(ev.data, 3);
    }
  }

  getSelectedProdCompItem(ev) {
    LogData("getSelectedProdCompItem", ev);
    if (ev && ev.data) {
      this.getCheckedValue(ev.data, 4);
    }
  }

  onClickItem(item: CommonData) {
    item.isChecked = true;
    this.filterIndex = item;
    this.sortFilterList.forEach(element => {
      if (element.value !== item.value) {
        element.isChecked = false;
      }
    });
  }

  getCheckedValue(data: Array<CommonData>, index) {
    const ckArray = data.filter((element) => element.isChecked);
    LogData("ckArray", ckArray);
    switch (index) {
      case 1:
        this.commonform.patchValue({ businesslist_id: ckArray });
        break;
      case 2:
        this.commonform.patchValue({ tradingtype_id_sales: ckArray });
        break;
      case 3:
        this.commonform.patchValue({ tradingtype_id_service: ckArray });
        break;
      case 4:
        this.commonform.patchValue({ productcompany_id: ckArray });
        break;
    }
  }

  closeModal(ev?) {
    this.popoverCtrl.dismiss();
  }

  showBusiness(ev?) {
    LogData("commonForm",this.commonform.value);
    LogData("addressForm", this.addressComponent.commonForm.value.addressDetails[0]);
    this.popoverCtrl.dismiss({
      commonForm: this.commonform.value,
      addressForm: this.addressComponent.commonForm.value.addressDetails[0]
    });
  }

  clearAll(ev?) {
    this.addressComponent.clearForm();
    this.buildForm();
    this.utilsService.setAddressMaster({
      page: this.pageName,
      value: true,
    });
    this.closeModal();
  }

  setCheckedBusinessType(){
    if(this.selectedFilter && this.selectedFilter.commonForm.businesslist_id && 
      this.selectedFilter.commonForm.businesslist_id){
        if(this.businessType && this.businessType.length > 0){
          this.businessType.forEach(element => {
            const findObj=this.selectedFilter.commonForm.businesslist_id.find((ckel)=> element.value === ckel.value);
            if(findObj){
              element.isChecked=true;
            }
          });
        }
    }
  }

  setCheckedSalesTradingType(){
    if(this.selectedFilter && this.selectedFilter.commonForm.tradingtype_id_sales && 
      this.selectedFilter.commonForm.tradingtype_id_sales){
        if(this.saleslist && this.saleslist.length > 0){
          this.saleslist.forEach(element => {
            const findObj=this.selectedFilter.commonForm.tradingtype_id_sales.find((ckel)=> element.value === ckel.value);
            if(findObj){
              element.isChecked=true;
            }
          });
        }
    }
  }

  setCheckedServicesTradingType(){
    if(this.selectedFilter && this.selectedFilter.commonForm.tradingtype_id_service && 
      this.selectedFilter.commonForm.tradingtype_id_service){
        if(this.serviceslist && this.serviceslist.length > 0){
          this.serviceslist.forEach(element => {
            const findObj=this.selectedFilter.commonForm.tradingtype_id_service.find((ckel)=> element.value === ckel.value);
            if(findObj){
              element.isChecked=true;
            }
          });
        }
    }
  }

  setCheckedProductCompnayType(){
    if(this.selectedFilter && this.selectedFilter.commonForm.productcompany_id && 
      this.selectedFilter.commonForm.productcompany_id){
        if(this.productCompney && this.productCompney.length > 0){
          this.productCompney.forEach(element => {
            const findObj=this.selectedFilter.commonForm.productcompany_id.find((ckel)=> element.value === ckel.value);
            if(findObj){
              element.isChecked=true;
            }
          });
        }
    }
  }

  //api call
  async getMasters() {
    const loading = await this.loadingCtrl.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    const list = businesslists + "," + getProductcompaniesArr + "," + getTradingtypesSalesArr + "," + getTradingtypesServiceArr;
    const formData = new FormData();
    formData.append("master", list);
    this.userServices
      .getMasters(formData)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("getMasters_res", res);
          res = this.utilsService.getDecriptReasponse(res);
          if (res && res.result && res.result.responce) {
            this.updateBuildForm();
            if (res.result.responce.businesslists) {
              this.businessType = res.result.responce.businesslists;
              this.setCheckedBusinessType();
            }
            if (res.result.responce.getProductcompaniesArr) {
              this.productCompney = res.result.responce.getProductcompaniesArr;
              this.setCheckedProductCompnayType();
            }
            if (res.result.responce.getTradingtypesSalesArr) {
              this.saleslist = res.result.responce.getTradingtypesSalesArr;
              this.setCheckedSalesTradingType();
            }
            if (res.result.responce.getTradingtypesServiceArr) {
              this.serviceslist = res.result.responce.getTradingtypesServiceArr;
              this.setCheckedServicesTradingType();
            }
          }

        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("getMasters_error", err);
        }
      );
  }

}
