import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { BusinessDirectoryDetails } from 'src/app/models/business-director-data';
import { AuthServices } from 'src/app/services/auth.services';
import { enterAnimation } from 'src/app/utils/enterAnimation';
import { LogData } from 'src/app/utils/utils';
import { CommentPopoverComponent } from '../comment-popover/comment-popover.component';
import {animate, AnimationBuilder, style} from '@angular/animations';
import { ReviewDetails } from 'src/app/models/comments-data';
import { UtilsService } from 'src/app/services/uitils.services';

@Component({
  selector: 'app-review-card',
  templateUrl: './review-card.component.html',
  styleUrls: ['./review-card.component.scss'],
})
export class ReviewCardComponent implements OnInit {

  comment="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.";
  bus_com="Thanks for your valuable feedback joy sid.";
  @Input() businessDetail:BusinessDirectoryDetails;
  @Input() commentsList:Array<ReviewDetails>=[];
  @Output() onReviewUpdate: EventEmitter<any> = new EventEmitter();
  isSame:boolean=false;
  constructor(public authServices:AuthServices,
    private popCtrl:PopoverController,
    private _builder: AnimationBuilder,
    public utilsService:UtilsService) { }

  ngOnInit() {
    this.isSame=this.authServices.getForId() === this.businessDetail.id ?true:false;
  }

  async openComment(comment) {
    const modal = await this.popCtrl.create({
      component: CommentPopoverComponent,
      animated:true,
      componentProps: {
        title: "dg_lbl_comment",
        btn1: "submit",
        btn2: "cancel",
        isSame:this.isSame,
        isCommentLyt:true,
        isReply:this.isSame ? true:false,
        isOnlyView:this.isSame ? false:true,
        commentData:comment,
        businessDetail:this.businessDetail,
        businessDetailid:this.businessDetail.id
        
      },
      cssClass: "custom-popover pop-width-50",
      showBackdrop: true,
      backdropDismiss: false,
    });
  
    modal.onDidDismiss().then((data) => {
      LogData("onDidDismiss...", data);
      if (data.data != null) {
        this.onReviewUpdate.emit(true);
      }
    });
  
    await modal.present();
  }

  // makeAnimation(element: any) {
  //   // first define a reusable animation
  //   const myAnimation = this._builder.build([
  //     style({ width: 0 }),
  //     animate(1000, style({ width: '100px' }))
  //   ]);

  //   // use the returned factory object to create a player
  //   const player = myAnimation.create(element);

  //   player.play();
  //   return myAnimation;
  // }

}
