import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActionSheetController, AlertController, MenuController, ModalController, Platform, PopoverController } from "@ionic/angular";
import { TestsamComponent } from "./components/testsam/testsam.component";
import { AuthServices } from "./services/auth.services";
import { UtilsService } from "./services/uitils.services";
import { SplashScreen } from "@capacitor/splash-screen";
import { Observable, Subscription } from "rxjs";
import { LogData } from "./utils/utils";
import { CurrentUserData } from "./models/user-data";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";
import { Router } from "@angular/router";
import { WEB } from "./utils/constant";
import { ADMIN_TAB1_ROUTE, ADMIN_TAB2_ROUTE, ADMIN_TAB3_ROUTE, ADMIN_TAB4_ROUTE, ADMIN_TAB5_ROUTE, BUSINESS_INFORMATION, BUSINESS_SUBSCRIPTION, CONGRATULATION, DEALER_TAB1_ROUTE, DEALER_TAB2_ROUTE, DEALER_TAB3_ROUTE, DEALER_TAB4_ROUTE, DEALER_TAB5_ROUTE, LOGIN_ROUTE, RECEIVED_PAYMENTS, STAFF_TAB1_ROUTE, STAFF_TAB2_ROUTE, STAFF_TAB3_ROUTE, STAFF_TAB4_ROUTE, STAFF_TAB5_ROUTE, SUCCESS_ERROR_MSG, TAB1_ROUTE, TAB2_ROUTE, TAB3_ROUTE, TAB4_ROUTE, TAB5_ROUTE, UPGRADE_BUSINESS, UPGRADE_BUSINESS_BUS, VISITORS_TAB1_ROUTE, VISITORS_TAB2_ROUTE, VISITORS_TAB3_ROUTE, VISITORS_TAB4_ROUTE, VISITORS_TAB5_ROUTE } from "./utils/routes";
import { ConfirmationComponent } from "./components/confirmation/confirmation.component";
import { finalize } from "rxjs/operators";
import { UserServices } from "./services/user.services";
import { CommonData } from "./models/common-data";
import { Location } from "@angular/common";
import { AppVesrionData, VersionData, VersionMsgData } from "./models/appversion";
import { VersionUpdateComponent } from "./components/version-update/version-update.component";
import { FcmServices } from "./services/fcm.services";

const setStatusBarStyleDark = async () => {
  // try{
  //   await StatusBar.setStyle({ style: Style.Light });
  //   LogData("setStatusBarStyleDark");
  // }catch(err){
  //   LogData("setStatusBarStyleDark_err",err);
  // }
  
  
};

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  isLogin: boolean = false;
  userData$: Observable<CurrentUserData>;
  userDataSubscription: Subscription;
  userData: CurrentUserData;
  public unsubscribeBackEvent: any;
  isOpen: boolean = false;
  // @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
  constructor(
    private authServices: AuthServices,
    public utilsService: UtilsService,
    private modalCtrl: ModalController,
    private router: Router,
    private popCtrl: PopoverController,
    private alertController: AlertController,
    private actionSheetCtrl: ActionSheetController,
    private menuCtrl: MenuController,
    private platform: Platform,
    private userServices: UserServices,
    private location: Location,
    private fcmService: FcmServices
  ) {
    if (Capacitor.getPlatform() !== "web") {
      setStatusBarStyleDark();
    }
    this.userData = this.authServices.getUserLoginData();
    this.subScriptionCall();
  }

  ngOnInit() {
    LogData("CAP_PLATFORM => ", Capacitor.getPlatform());
    this.getLanguage();
    if (Capacitor.getPlatform() !== WEB) {
      // this.initBackButton();
      this.initializeBackButtonCustomHandler();
    }
    // this.openSingleSelection();
    // Trigger the push setup
    // this.fcmService.initPush();
    if (!this.utilsService.isWeb()) {
      this.getVersionFromServer();
    }

    setTimeout(() => {
      SplashScreen.hide();
    }, 500);
  }


  getRoutes() {
    const routerURL = this.router.url;
    return routerURL === VISITORS_TAB1_ROUTE || routerURL === VISITORS_TAB2_ROUTE ||
      routerURL === VISITORS_TAB3_ROUTE || routerURL === VISITORS_TAB4_ROUTE ||
      routerURL === VISITORS_TAB5_ROUTE || routerURL === TAB1_ROUTE ||
      routerURL === TAB2_ROUTE || routerURL === TAB3_ROUTE ||
      routerURL === TAB4_ROUTE || routerURL === TAB5_ROUTE || routerURL === ADMIN_TAB1_ROUTE ||
      routerURL === ADMIN_TAB2_ROUTE || routerURL === ADMIN_TAB3_ROUTE ||
      routerURL === ADMIN_TAB4_ROUTE || routerURL === ADMIN_TAB5_ROUTE || routerURL === STAFF_TAB1_ROUTE ||
      routerURL === STAFF_TAB2_ROUTE || routerURL === STAFF_TAB3_ROUTE ||
      routerURL === STAFF_TAB4_ROUTE || routerURL === STAFF_TAB5_ROUTE || routerURL === DEALER_TAB1_ROUTE ||
      routerURL === DEALER_TAB2_ROUTE || routerURL === DEALER_TAB3_ROUTE ||
      routerURL === DEALER_TAB4_ROUTE || routerURL === DEALER_TAB5_ROUTE ||
      routerURL === "/" + LOGIN_ROUTE;
  }

  setInitLanguage() {

  }

  initializeBackButtonCustomHandler(): void {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(999999, async () => {
      const routerURL = this.router.url;
      LogData("routerURL", routerURL);
      const menu = await this.menuCtrl.isOpen();
      const modal = await this.modalCtrl.getTop();
      const popModal = await this.popCtrl.getTop();
      const alertModel = await this.alertController.getTop();
      const actionsheetController = await this.actionSheetCtrl.getTop();
      const picker_ionic = window.document.querySelector('ion-picker');
      const date_picker: any = document.querySelector('mat-datepicker-content');
      // const date_picker1 = window.document.querySelector('picker');

      if (this.getRoutes()) {
        if (date_picker) {
          // this.picker.close();
          this.utilsService.setCloseDateTimePicker(true);
        } else if (menu) {
          this.menuCtrl.close();
        } else if (picker_ionic) {
          picker_ionic.dismiss();
        } else if (modal) {
          modal.dismiss();
        } else if (popModal) {
          popModal.dismiss();
        }
        else if (alertModel) {
          alertModel.dismiss();
        } else if (actionsheetController) {
          actionsheetController.dismiss();
        } else {
          this.alertForExit();
        }
      }
      else {
        if (date_picker) {
          this.utilsService.setCloseDateTimePicker(true);
        } else if (menu) {
          this.menuCtrl.close();
        } else if (picker_ionic) {
          picker_ionic.dismiss();
        } else if (modal) {
          modal.dismiss();
        } else if (popModal) {
          popModal.dismiss();
        }
        else if (alertModel) {
          alertModel.dismiss();
        } else if (actionsheetController) {
          actionsheetController.dismiss();
        } else {
          if (!this.isOpen) {
            // this.location.back();
            LogData("BACK");
            if (routerURL === "/" + CONGRATULATION || routerURL === "/" + UPGRADE_BUSINESS) {
              this.utilsService.setNavigationRoute(LOGIN_ROUTE);
              LogData("initBackButton_3");
            } else if (routerURL === "/" + BUSINESS_INFORMATION) {
              LogData("initBackButton_4");
              if (this.utilsService.prevRouteForBusinessInformaton !== "/" + LOGIN_ROUTE) {
                window.history.back();
                // this.routerOutlet.pop();
                // this.location.back();
              }
            } else if (routerURL === "/" + UPGRADE_BUSINESS_BUS) {
              LogData("initBackButton_5");
              this.utilsService.setNavigationRoute(TAB2_ROUTE);
            } else if (routerURL === "/" + SUCCESS_ERROR_MSG) {
              LogData("initBackButton_6");
              this.utilsService.setNavigationRoute(LOGIN_ROUTE);
            } else if (routerURL === "/" + RECEIVED_PAYMENTS || routerURL === "/" + BUSINESS_SUBSCRIPTION) {
              LogData("initBackButton_7");
              this.utilsService.setNavigationRoute(LOGIN_ROUTE);
            } else {
              LogData("initBackButton_8");
              window.history.back();
              // this.routerOutlet.pop();
              // this.location.back();
            }

          }
        }
      }
    });
  }

  async getVersionFromServer() {
    this.userServices.getServerAppVersion()
      .pipe(
        finalize(() => {

        })
      )
      .subscribe(async (res) => {
        LogData("getServerAppVersion_res", res);
        res = this.utilsService.getDecriptReasponse(res);
        if (res && res.result && res.result.responce) {
          if (this.platform.is('android')) {
            const androidAppversion = res.result.responce.android;
            this.checkAppVersion(androidAppversion);
          } else if (this.platform.is('ios')) {
            const iosAppversion = res.result.responce.ios;
            this.checkAppVersion(iosAppversion);
          } else {
            const androidAppversion = res.result.responce.android;
            this.checkAppVersion(androidAppversion);
          }
        }

      }, err => {
        LogData("getServerAppVersion_error", err);
      });
  }

  async checkAppVersion(appVersionData: AppVesrionData) {
    LogData("app__VersionData...", appVersionData);

    const currentVesrion = await this.utilsService.getAppversion();
    if (appVersionData.versionCheck) {
      if (appVersionData.maintenance) {
        this.openAppVersionUpdateDialog(true, appVersionData.maintenanceMsg, appVersionData.maintenance, appVersionData.playStoreLink);
      } else {
        const splittedVersion = currentVesrion.split('.');
        const serverVersion = appVersionData.version.split('.');
        LogData("app__currentVesrion", currentVesrion);
        LogData("app__splittedVersion", splittedVersion);
        LogData("app__serverVersion", serverVersion);
        if (serverVersion[0] > splittedVersion[0]) {
          this.openAppVersionUpdateDialog(true, appVersionData.majorMsg, appVersionData.maintenance, appVersionData.playStoreLink);
        } else if (serverVersion[1] > splittedVersion[1]) {
          this.openAppVersionUpdateDialog(false, appVersionData.minorMsg, appVersionData.maintenance, appVersionData.playStoreLink);
          // this.openModal();
        } else {
          // this.openModal();
        }
      }
    } else {
      // this.openModal(); 
    }
  }

  async openAppVersionUpdateDialog(isMajor, versionMsgData: VersionMsgData, isMaintenance, urlLink) {
    const pop_modal = await this.popCtrl.getTop();
    console.log("pop_modal..", pop_modal);

    if (!pop_modal) {
      const modal = await this.popCtrl.create({
        component: VersionUpdateComponent,
        componentProps: {
          versionMsgData: versionMsgData,
          isMaintenance: isMaintenance,
          isMajor: isMajor,
          playStoreLink: urlLink
        },
        cssClass: 'custom-popover',
        backdropDismiss: isMajor ? false : true,
      });

      modal.onDidDismiss().then((data) => {
        LogData("onDidDismiss_VersionUpdate", data);
        if (isMajor) {
          navigator['app'].exitApp();
        }
        // this.router.navigate();
        // if (data.data != null) {

        // }
      });

      await modal.present();
    }

  }

  async alertForExit() {
    const modal = await this.popCtrl.create({
      component: ConfirmationComponent,
      componentProps: { msg: "are_you_sure_you_want_to_exit_app", icon: 'logout_menu' },
      // componentProps: { message1: "Confirmation", message2: "Are you sure you want to exit App?" },
      cssClass: 'custom-popover',
      // enterAnimation:modalEnterAnimation
      // cssClass: 'my-custom-modal-css'
    });

    modal.onDidDismiss().then((data) => {
      LogData("onDidDismiss...", data);
      if (data.data != null && data.data === "Yes") {
        navigator['app'].exitApp();
      }

    });

    await modal.present();
  }

  subScriptionCall() {
    //when onDismiss
    this.userData$ = this.utilsService.userData$;
    this.userDataSubscription = this.userData$.subscribe((value) => {
      LogData("userDataSubscription....call", value);
      if (value) {
        this.userData = value;
      }
    });
  }

  async openSingleSelection() {
    const modal = await this.modalCtrl.create({
      component: TestsamComponent,
      cssClass: "my-custom-class",
    });
    return await modal.present();
  }

  getLanguage() {
    this.userServices.getLanguage()
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          // loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("getLanguages_res", res);
          res = this.utilsService.getDecriptReasponse(res);
          if (res && res.result && res.result.responce) {
            if (
              res.result.responce.getLanguagesArr &&
              res.result.responce.getLanguagesArr.length > 0
            ) {
              const langList: Array<CommonData> =
                res.result.responce.getLanguagesArr;
              if (langList && langList.length > 0) {
                this.authServices.setLanguageArr(langList);
                const findObj = langList.find(
                  (element) => element.value === res.result.lang
                );
                LogData("res_AppLang_findObj", findObj);
                this.authServices.setLanguage(findObj);
                this.getPoFileApi(findObj.value);
              }
            }
          }
        },
        (err) => {
          LogData("loader_dismiss");
          // loading.dismiss();
          LogData("getLanguages_error", err);
        }
      );
  }

  async getPoFileApi(lang) {
    LogData("loader_start");
    this.userServices.getPoFile(lang)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          //   loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("getPoFile_res", res);
          res = this.utilsService.getDecriptReasponse(res);
          if (
            res &&
            res.result &&
            res.result.responce &&
            res.result.responce.getpo
          ) {
            this.authServices.setLanguageData(res.result.responce.getpo);
            this.utilsService.setUpdateSideMenu(true);
            this.utilsService.setSuccessResponse(true);
            // setTimeout(()=>{
            //   this.utilsService.setUpdateuserProfile(true);
            // },5000)

          }
        },
        (err) => {
          LogData("loader_dismiss");
          // loading.dismiss();
          LogData("getPoFile_error", err);
        }
      );
  }


  ngOnDestroy(): void {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
  }
}
