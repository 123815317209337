import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountsDetails } from 'src/app/models/accounts-data';
import { UserprofileData } from 'src/app/models/user-profile-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { LogData } from 'src/app/utils/utils';
import { GSTNoValidation, ValidationMessages } from 'src/app/validators/validations';

@Component({
  selector: 'app-payment-summry',
  templateUrl: './payment-summry.component.html',
  styleUrls: ['./payment-summry.component.scss'],
})
export class PaymentSummryComponent implements OnInit {

  commonForm: FormGroup;
  @Input() amountDetails: AccountsDetails;
  @Input() userprofileData: UserprofileData;
  validationMessages=ValidationMessages;
  isFormSubmit:boolean=false;
  constructor(private formBuilder: FormBuilder,
    public utilsService:UtilsService) { }

  ngOnInit() { 
    this.buildForm();
  }

  buildForm() {
    this.commonForm = this.formBuilder.group({
      gstno: [(this.userprofileData && this.userprofileData.gstno) ? this.userprofileData.gstno:""],
      isgst: [(this.userprofileData && this.userprofileData.isgst === 1) ? true:false],
    });
  }

  onChangeCheckboxValue(ev) {
    LogData("onChangeCheckboxValue",ev);
    if(ev.detail.checked){
      this.commonForm.get('gstno').setValidators(GSTNoValidation);
    }else{
      this.commonForm.get('gstno').clearValidators();
    }
    this.commonForm.get('gstno').updateValueAndValidity();
  }

}
