import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { IonicModule } from "@ionic/angular";
import { ArrayToStringPipe } from "../pipes/arraytostring.pipe";
import { GetImagePipe } from "../pipes/get-image-pipe";
import { SizeAttributeDirective } from "../directives/size-attribute.directive";
import { TrimTextDirective } from "../directives/trim-text.directive";
import { OnlyDecimalDirective } from "../directives/only-decimal.directive";
import { ValidationComponent } from "../components/validation/validation.component";
import { UiCmpComponent } from "../components/ui-cmp/ui-cmp.component";
import { SingleSearchFilterComponent } from "../components/single-search-filter/single-search-filter.component";
import { NoDataFoundComponent } from "../components/no-data-found/no-data-found.component";
import { TestsamComponent } from "../components/testsam/testsam.component";
import { VarifyAccountComponent } from "../components/varify-account/varify-account.component";
import { SuccessErrorComponent } from "../components/success-error/success-error.component";
import { ValidationPipe } from "../pipes/validation.pipe";
import { CustomDateTimeComponent } from "../components/custom-date-time/custom-date-time.component";
import { NgOtpInputModule } from "ng-otp-input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { DateAdapter, MatNativeDateModule } from "@angular/material/core";
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from "@angular-material-components/datetime-picker";
import { SideMenuComponent } from "../components/side-menu/side-menu.component";
import { NgCircleProgressModule } from "ng-circle-progress";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS } from "./format-datepicker";
import { BusinessNameComponent } from "../components/business-name/business-name.component";
import { SortDataComponent } from "../components/sort-data/sort-data.component";
import { ListPopoverComponent } from "../components/list-popover/list-popover.component";
import { ConfirmationComponent } from "../components/confirmation/confirmation.component";
import { IonicGestureConfig } from "../services/ionic-geuster-config";
import { HammerModule, HAMMER_GESTURE_CONFIG } from "@angular/platform-browser";
import { ProfileImageComponent } from "../components/profile-image/profile-image.component";
import { AutocomplateListComponent } from "../components/autocomplate-list/autocomplate-list.component";
import { SelectedChipComponent } from "../components/selected-chip/selected-chip.component";
import { PhotoUtilityComponent } from "../components/photo-utility/photo-utility.component";
import { BusinessCardComponent } from "../components/business-card/business-card.component";
import { SearchbarComponent } from "../components/searchbar/searchbar.component";
import { ItemCardComponent } from "../components/item-card/item-card.component";
import { InquirieFilterComponent } from "../components/inquirie-filter/inquirie-filter.component";
import { InquirieCardComponent } from "../components/inquirie-card/inquirie-card.component";
import { CategoryCardComponent } from "../components/category-card/category-card.component";
import { BusinessServicesCardComponent } from "../components/business-services-card/business-services-card.component";
import { StarRatingModule } from "ionic5-star-rating";
import { OverviewCardComponent } from "../components/overview-card/overview-card.component";
import { LocationCardComponent } from "../components/location-card/location-card.component";
import { DetailsCardComponent } from "../components/details-card/details-card.component";
import { ServicesCardComponent } from "../components/services-card/services-card.component";
import { ContactUsCardComponent } from "../components/contact-us-card/contact-us-card.component";
import { RatingCardComponent } from "../components/rating-card/rating-card.component";
import { NameIconComponent } from "../components/name-icon/name-icon.component";
import { AccordingCardComponent } from "../components/according-card/according-card.component";
import { BbsCardComponent } from "../components/bbs-card/bbs-card.component";
import { DmbCardComponent } from "../components/dmb-card/dmb-card.component";
import { BenefitsCardComponent } from "../components/benefits-card/benefits-card.component";
import { UserSayComponent } from "../components/user-say/user-say.component";
import { FooterCardComponent } from "../components/footer-card/footer-card.component";
import { WebCategoryComponent } from "../components/web-category/web-category.component";
import { SeeAllComponent } from "../components/see-all/see-all.component";
import { BusinessFilterComponent } from "../components/business-filter/business-filter.component";
import { WithoutLoginHeaderComponent } from "../components/without-login-header/without-login-header.component";
import { ItemSelectionComponent } from "../components/item-selection/item-selection.component";
import { AddressComponent } from "../components/address/address.component";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { ImageviewerComponent } from "../components/imageviewer/imageviewer.component";
import { DebouncedInputDirective } from "../directives/debounce-input.directive";
import { UserAgreeComponent } from "../components/user-agree/user-agree.component";
import { GetServerImgDisplay } from "../pipes/get-sever-img-display.pipe";
import { GetLanguagePipe } from "../pipes/get-language.pipe";
import { PlatformInputPipe } from "../pipes/platform-input.pipe";
import { RefresherComponent } from "../components/refresher/refresher.component";
import { PaginationComponent } from "../components/pagination/pagination.component";
import { FirstLatterPipe } from "../pipes/first-latter.pipe";
import { AddressDataComponent } from "../components/address-data/address-data.component";
import { AddressFilterComponent } from "../components/address-filter/address-filter.component";
import { WebHomeComponent } from "../components/web-home/web-home.component";
import { MobileHomeComponent } from "../components/mobile-home/mobile-home.component";
import { LanguagePopoverComponent } from "../components/language-popover/language-popover.component";
import { WebTopBarComponent } from "../components/web-top-bar/web-top-bar.component";
import { DynamicRoutePipe } from "../pipes/dynamic.route.pipe";
import { BdComponent } from "../components/bd/bd.component";
import { GroupFilterComponent } from "../components/group-filter/group-filter.component";
import { GroupNameComponent } from "../components/group-name/group-name.component";
import { SubjectComponent } from "../components/subject/subject.component";
import { TemplateCardComponent } from "../components/template-card/template-card.component";
import { StaffCardComponent } from "../components/staff-card/staff-card.component";
import { ToolbarComponent } from "../components/toolbar/toolbar.component";
import { CommonFilterComponent } from "../components/common-filter/common-filter.component";
import { AccountCardComponent } from "../components/account-card/account-card.component";
import { GallarySettingCardComponent } from "../components/gallary-setting-card/gallary-setting-card.component";
import { SbsCardComponent } from "../components/sbs-card/sbs-card.component";
import { AdvCardComponent } from "../components/adv-card/adv-card.component";
import { PaymentOptionComponent } from "../components/payment-option/payment-option.component";
import { UploadPicCardComponent } from "../components/upload-pic-card/upload-pic-card.component";
import { RoleCardComponent } from "../components/role-card/role-card.component";
import { NotificationCardComponent } from "../components/notification-card/notification-card.component";
import { MessageCardComponent } from "../components/message-card/message-card.component";
import { ChatCardComponent } from "../components/chat-card/chat-card.component";
import { TransactionCardComponent } from "../components/transaction-card/transaction-card.component";
import { EntryCardComponent } from "../components/entry-card/entry-card.component";
import { EntryItemComponent } from "../components/entry-item/entry-item.component";
import { EntryFieldComponent } from "../components/entry-field/entry-field.component";
import { LadgerCardComponent } from "../components/ladger-card/ladger-card.component";
import { ReportItemComponent } from "../components/report-item/report-item.component";
import { NotePopoverComponent } from "../components/note-popover/note-popover.component";
import { LadgerDetailsComponent } from "../components/ladger-details/ladger-details.component";
import { LanguageCardComponent } from "../components/language-card/language-card.component";
import { LongPressDirective } from "../directives/long-press.directive";
import { GetKeysByPipe } from "../pipes/getkeysbyvalue.pipe";
import { ComplainsFilterComponent } from "../components/complains-filter/complains-filter.component";
import { DynamicDateTimePipe } from "../pipes/dynamic.datetime.pipe";
import { DiffernceDatePipe } from "../pipes/diff-date.pipe";
import { MultiSelectionComponent } from "../components/multi-selection/multi-selection.component";
import { GalleryCardComponent } from "../components/gallery-card/gallery-card.component";
import { HiddenNumberPipe } from "../pipes/hidden-number.pipe";
import { ProductCompneyCardComponent } from "../components/product-compney-card/product-compney-card.component";
import { GetUserImagePipe } from "../pipes/user.img.pipe";
import { AdvFilterComponent } from "../components/adv-filter/adv-filter.component";
import { HasAccesPipe } from "../pipes/has-access.pipe";
import { GetAddressStrPipe } from "../pipes/address-str.pipe";
import { TabbarComponent } from "../components/tabbar/tabbar.component";
import { CharacterCountPipe } from "../pipes/character-count.pipe";
import { CharacterCountComponent } from "../components/character-count/character-count.component";
import { BusinessHoursComponent } from "../components/business-hours/business-hours.component";
import { AccLadgerFilterComponent } from "../components/acc-ladger-filter/acc-ladger-filter.component";
import { SwiperModule } from 'swiper/angular';
import { TransactionFilterComponent } from "../components/transaction-filter/transaction-filter.component";
import { LedgerReportFilterComponent } from "../components/ledger-report-filter/ledger-report-filter.component";
import { onlyNumberDirective } from "../directives/number-only-directive";
import { PositiveNumberDirective } from "../directives/positive-number-directive";
import { TransDetailsPopoverComponent } from "../components/trans-details-popover/trans-details-popover.component";
import { ItemLedCardComponent } from "../components/item-led-card/item-led-card.component";
import { CRDRDisplayPipe } from "../pipes/crdr-display.pipe";
import { PaymentSummryComponent } from "../components/payment-summry/payment-summry.component";
import { ReceivedPaymentsCardComponent } from "../components/received-payments-card/received-payments-card.component";
import { PaymentsDetailsCardComponent } from "../components/payments-details-card/payments-details-card.component";
import { SalesPurchaseCardComponent } from "../components/sales-purchase-card/sales-purchase-card.component";
import { AddReferalidComponent } from "../components/add-referalid/add-referalid.component";
import { MessageComponent } from "../components/message/message.component";
import { VersionUpdateComponent } from "../components/version-update/version-update.component";
import { WarningCardComponent } from "../components/warning-card/warning-card.component";
import { WalletCardComponent } from "../components/wallet-card/wallet-card.component";
import { TransferBalPopoverComponent } from "../components/transfer-bal-popover/transfer-bal-popover.component";
import { WalletDataComponent } from "../components/wallet-data/wallet-data.component";
import { PricingCardComponent } from "../components/pricing-card/pricing-card.component";
import { SettingCardComponent } from "../components/setting-card/setting-card.component";
import { ImgCropUtilComponent } from "../components/img-crop-util/img-crop-util.component";
import { ImageCropperModule } from 'ngx-image-cropper';
import { CurrentGeolocationComponent } from "../components/current-geolocation/current-geolocation.component";
import { DgHeaderComponent } from "../components/dg-header/dg-header.component";
import { DgButtonsComponent } from "../components/dg-buttons/dg-buttons.component";
import { GalleryPopoverComponent } from "../components/gallery-popover/gallery-popover.component";
import { AmctInputComponent } from "../components/amct-input/amct-input.component";
import { DoughnutChartComponent } from "../components/doughnut-chart/doughnut-chart.component";
import { AutoSuggestComponent } from "../components/auto-suggest/auto-suggest.component";
import { PromocodeCardComponent } from "../components/promocode-card/promocode-card.component";
import { PromoFilterComponent } from "../components/promo-filter/promo-filter.component";
import { AfterConvertComponent } from "../components/after-convert/after-convert.component";
import { EnddateChangeComponent } from "../components/enddate-change/enddate-change.component";
import { CommentCardComponent } from "../components/comment-card/comment-card.component";
import { CommentsFilterComponent } from "../components/comments-filter/comments-filter.component";
import { SendCommentCardComponent } from "../components/send-comment-card/send-comment-card.component";
import { SocialBtnComponent } from "../components/social-btn/social-btn.component";
import { ReviewCardComponent } from "../components/review-card/review-card.component";
import { CommentPopoverComponent } from "../components/comment-popover/comment-popover.component";
import { CommentAvtarComponent } from "../components/comment-avtar/comment-avtar.component";
import { ReadMoreComponent } from "../components/read-more/read-more.component";
import { DaysAgo } from "../pipes/days-ago.pipe";

// export const MY_DATE_FORMATS = {
//     parse: {
//       dateInput: 'DD/MM/YYYY',
//     },
//     display: {
//       dateInput: 'DD-MM-YYYY',
//       // monthYearLabel: 'MMMM YYYY',
//       // dateA11yLabel: 'LL',
//       // monthYearA11yLabel: 'MMMM YYYY'
//     },
// };
const components = [
  SingleSearchFilterComponent,
  MultiSelectionComponent,
  TestsamComponent,
  SuccessErrorComponent,
  VarifyAccountComponent,
  SortDataComponent,
  ListPopoverComponent,
  ConfirmationComponent,
  ProfileImageComponent,
  InquirieFilterComponent,
  BusinessFilterComponent,
  ImageviewerComponent,
  UserAgreeComponent,
  AddressFilterComponent,
  GroupFilterComponent,
  CommonFilterComponent,
  EntryFieldComponent,
  ComplainsFilterComponent,
  AdvFilterComponent,
  AccLadgerFilterComponent,
  TransactionFilterComponent,
  LedgerReportFilterComponent,
  TransDetailsPopoverComponent,
  AddReferalidComponent,
  MessageComponent,
  VersionUpdateComponent,
  TransferBalPopoverComponent,
  ImgCropUtilComponent,
  CurrentGeolocationComponent,
  GalleryPopoverComponent,
  PromoFilterComponent,
  AfterConvertComponent,
  EnddateChangeComponent,
  CommentsFilterComponent,
  CommentPopoverComponent
];

const declationCompo = [
  ValidationComponent,
  UiCmpComponent,
  NoDataFoundComponent,
  CustomDateTimeComponent,
  SideMenuComponent,
  BusinessNameComponent,
  AutocomplateListComponent,
  SelectedChipComponent,
  PhotoUtilityComponent,
  BusinessCardComponent,
  SearchbarComponent,
  ItemCardComponent,
  InquirieCardComponent,
  CategoryCardComponent,
  BusinessServicesCardComponent,
  OverviewCardComponent,
  LocationCardComponent,
  DetailsCardComponent,
  ServicesCardComponent,
  ContactUsCardComponent,
  RatingCardComponent,
  NameIconComponent,
  AccordingCardComponent,
  BbsCardComponent,
  DmbCardComponent,
  BenefitsCardComponent,
  UserSayComponent,
  FooterCardComponent,
  WebCategoryComponent,
  SeeAllComponent,
  WithoutLoginHeaderComponent,
  ItemSelectionComponent,
  AddressComponent,
  RefresherComponent,
  PaginationComponent,
  AddressDataComponent,
  WebHomeComponent,
  MobileHomeComponent,
  LanguagePopoverComponent,
  WebTopBarComponent,
  BdComponent,
  GroupNameComponent,
  SubjectComponent,
  TemplateCardComponent,
  StaffCardComponent,
  ToolbarComponent,
  AccountCardComponent,
  GallarySettingCardComponent,
  SbsCardComponent,
  AdvCardComponent,
  PaymentOptionComponent,
  UploadPicCardComponent,
  RoleCardComponent,
  NotificationCardComponent,
  MessageCardComponent,
  ChatCardComponent,
  TransactionCardComponent,
  EntryCardComponent,
  EntryItemComponent,
  LadgerCardComponent,
  ReportItemComponent,
  NotePopoverComponent,
  LadgerDetailsComponent,
  LanguageCardComponent,
  GalleryCardComponent,
  ProductCompneyCardComponent,
  TabbarComponent,
  CharacterCountComponent,
  BusinessHoursComponent,
  ItemLedCardComponent,
  PaymentSummryComponent,
  ReceivedPaymentsCardComponent,
  PaymentsDetailsCardComponent,
  SalesPurchaseCardComponent,
  WarningCardComponent,
  WalletCardComponent,
  WalletDataComponent,
  PricingCardComponent,
  SettingCardComponent,
  DgHeaderComponent,
  DgButtonsComponent,
  AmctInputComponent,
  DoughnutChartComponent,
  AutoSuggestComponent,
  PromocodeCardComponent,
  CommentCardComponent,
  SendCommentCardComponent,
  SocialBtnComponent,
  ReviewCardComponent,
  CommentAvtarComponent,
  ReadMoreComponent
];

const pipes = [
  ArrayToStringPipe,
  GetImagePipe,
  ValidationPipe,
  GetServerImgDisplay,
  GetLanguagePipe,
  PlatformInputPipe,
  FirstLatterPipe,
  DynamicRoutePipe,
  GetKeysByPipe,
  DynamicDateTimePipe,
  DiffernceDatePipe,
  HiddenNumberPipe,
  GetUserImagePipe,
  HasAccesPipe,
  GetAddressStrPipe,
  CharacterCountPipe,
  CRDRDisplayPipe,
  DaysAgo
];

const directives = [
  SizeAttributeDirective,
  TrimTextDirective,
  OnlyDecimalDirective,
  DebouncedInputDirective,
  LongPressDirective,
  onlyNumberDirective,
  PositiveNumberDirective
];

const mat_module = [
  MatFormFieldModule,
  MatButtonModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxMatNativeDateModule,
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule,
    NgOtpInputModule,
    mat_module,
    NgCircleProgressModule,
    HammerModule,
    StarRatingModule,
    SlickCarouselModule,
    SwiperModule,
    ImageCropperModule
  ],
  declarations: [components, declationCompo, pipes, directives],
  exports: [
    declationCompo,
    pipes,
    directives,
    ReactiveFormsModule,
    mat_module,
    NgCircleProgressModule,
  ],
  providers: [
    ArrayToStringPipe,
    GetKeysByPipe,
    GetLanguagePipe,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: IonicGestureConfig,
    },
  ],
  entryComponents: [components],
})
export class CommonComponentModule {}
