// This param user in whole application
export const USERNAME_KEY = "username";
export const USER_LANGUAGE_KEY = "user_lang";
export const USER_LANGUAGE_ARR_KEY = "user_lang_arr";
export const USER_LANGUAGE_DATA_KEY = "user_lang_data";
// export const MALE="Male"; //1
// export const FEMALE="Female"; //2
export const F_NAME_KEY = "f_name";
export const L_NAME_KEY = "l_name";
export const PHONE_KEY = "phone";
export const EMAIL_KEY = "email";
export const PASSWORD_KEY = "password";
export const DATEOFBIRTH_KEY = "dateofbirth";
export const GENDER_KEY = "gender";
export const STATE_ID_KEY = "state_id";
export const DISTRICT_ID_KEY = "district_id";
export const TALUKA_ID_KEY = "taluka_id";
export const VILLAGE_ID_KEY = "village_id";
export const VILLAGE_ID_OTHER_KEY = "village_id_other";
export const VILLAGEAREA_ID_OTHER_KEY = "villagearea_id_other";
export const ADDI_ADDRESS_KEY = "addi_address";
export const PINCODE_KEY = "pincode";
export const OTP_EMAIL_KEY = "otp_email";
export const OTP_MOBILE_KEY = "otp_mobile";
export const VILLAGEAREA_ID_KEY = "villagearea_id";
export const USER_TYPE_KEY = "user_type";
export const BUSINESSLIST_ID_KEY = "businesslist_id";
export const BUSINESS_NAME_KEY = "business_name";
export const REFERENCE_KEY = "reference";
export const RESETTOKEN_KEY = "resettoken";
export const PASSWORD_CONFIRM = "password_confirm";
export const MOBILE_KEY = "mobile";
export const REFERREL_CODE_KEY = "referral_code";
export const USER_CODE_KEY = "usercode";
export const CURRENT_PASSWORD_KEY = "current_password";
export const USERPROFILES_IMG_KEY = "userprofiles_img";
export const BUSINESS_LOGO_IMG_KEY = "business_logo";
export const BUSINESS_GALLERY_IMG_KEY = "business_gallery";
export const BUSINESS_COVER_PHOTO_KEY = "business_cover_photo";
export const PRODUCT_COMPNEY_LOGO_KEY = "company_logo";
export const ADVERTISEMENT_PIC_KEY="advertisement_pic";
export const TRADINGTYPE_ICON_KEY="tradingtype_icon";
export const DESCRIPTION_KEY = "description";
export const PRODUCTCOMPANY_ID_KEY = "productcompany_id";
export const ISGST_KEY = "isgst";
export const GST_TAXPAYER_KEY = "gst_taxpayer";
export const GST_NO_KEY = "gstno";
export const GSTNO_ON_key = "gstno_on";
export const WEBSITE_KEY = "website";
export const BUSINESSHOURS_KEY = "businesshours";
export const BUSINESSCURRENTLY_KEY = "businesscurrently";
export const BUSINESSCURRENTLYTEXT_KEY = "businesscurrentlytext";
export const BUSINESS_TAG_KEY = "business_tag";
export const BUSINESS_KEYWORD_KEY = "business_keyword";
// export const TRADINGTYPE_ID_SALES_KEY="tradingtype_id_sales";
// export const TRADINGTYPE_ID_SERVICE_KEY="tradingtype_id_service";
// export const PRODUCTLIST_ID_SALES_KEY="productlist_id_sales";
// export const PRODUCTLIST_ID_SERVICE_KEY="productlist_id_service";
export const TRADINGTYPE_ID_SALES_KEY = "tradingtype_id[1]";
export const TRADINGTYPE_ID_SERVICE_KEY = "tradingtype_id[2]";
export const PRODUCTLIST_ID_SALES_KEY = "productlist_id[1]";
export const PRODUCTLIST_ID_SERVICE_KEY = "productlist_id[2]";
export const FB_URL_KEY = "fb_url";
export const INSTA_URL_KEY = "insta_url";
export const TWITTER_URL_KEY = "twitter_url";
export const LINKDIN_URL_KEY = "linkdin_url";
export const GOOGLE_BUSINESS_URL_KEY = "google_business_url";
export const YOUTUBE_URL_KEY = "youtube_url";
export const PHONE_ON_KEY = "phone_on";
export const WHATS_NO_KEY = "whats_no";
export const WHATS_NO_ON_KEY = "whats_no_on";
export const EMAIL_ON_KEY = "email_on";
export const DESIGNATION_KEY = "designation";
export const DESIGNATION_ID_KEY = "designation_id";
// export const CONTACT_PERSONS_KEY="contact_persons";
export const USERPROFILEDETAILS_KEY = "userprofiledetails";
export const USERREGISTERAREAS_KEY = "userregisterareas";
export const VIEW_ON_WEB_KEY = "view_on_web";
export const SEARCH_KEY = "search";
export const GET_ID_KEY = "get_id";
export const GET_ID1_KEY = "get_id1";
export const AGREE_KEY = "agree";
export const DELETED_KEY = "deleted";
export const ID_KEY = "id";
export const IS_CLEAR_KEY = "IS_CLEAR_KEY";
export const JOINING_DATE_KEY = "joining_date";
export const LEAV_DATE_KEY = "leav_date";
export const SALARY_KEY = "salary";
export const ROLE_ID_KEY="role_id";
export const OTP_CHECK_KEY="otp_check";

//Image upload
export const USER_IMG_ID_0 = "documents[0][id]";
export const USER_IMG_TITLE_0 = "documents[0][title]";
export const USER_IMG_DELETED_0 = "documents[0][deleted]";
export const USER_IMG_USERPROFILES_IMG_0 = "documents[0][userprofiles_img]";

// Uniq Data
//mobile
export const UNIQUEDATA_FIELD_0 = "uniquedata[0][field]";
export const UNIQUEDATA_MODEL_0 = "uniquedata[0][model]";
export const UNIQUEDATA_FIELD_VAL_0 = "uniquedata[0][field_val]";
export const UNIQUEDATA_ID_0 = "uniquedata[0][id]";
export const USERS_KEY = "users";

//email
export const UNIQUEDATA_FIELD_1 = "uniquedata[1][field]";
export const UNIQUEDATA_MODEL_1 = "uniquedata[1][model]";
export const UNIQUEDATA_FIELD_VAL_1 = "uniquedata[1][field_val]";
export const UNIQUEDATA_ID_1 = "uniquedata[1][id]";

//usercode
export const UNIQUEDATA_FIELD_2 = "uniquedata[2][field]";
export const UNIQUEDATA_MODEL_2 = "uniquedata[2][model]";
export const UNIQUEDATA_FIELD_VAL_2 = "uniquedata[2][field_val]";
export const UNIQUEDATA_ID_2 = "uniquedata[2][id]";

export const REQUESTARR_ID_KEY = "requestarr_id";
export const NAME_KEY = "name";
export const SUBSCRIPTION_COST_KEY = "subscription_cost";
export const STATUS_KEY = "status";
export const BUSINESSTYPE_ID_KEY = "businesstype_id";
export const TRADINGTYPE_ID_KEY = "tradingtype_id";
export const PRODUCT_NAME_KEY = "product_name";
export const PRODUCT_KEYWORD_KEY="product_keyword";
export const TRADING_TYPE_KEY = "trading_type";
export const DISPLAY_HOME_KEY="display_home";

export const COM_SHORT_NAME_KEY = "com_short_name";
export const COMPANY_NAME_KEY = "company_name";
export const START_DATE_KEY = "start_date";
export const END_DATE_KEY = "end_date";

export const RECORDID_KEY = "RecordId";
export const CURRENTSTATUS_KEY = "CurrentStatus";
export const DELETE_KEY = "delete";
export const INACTIVE_STATUS_KEY = "2";
export const ACTIVE_STATUS_KEY = "1";

export const FILTER_STATUS_KEY = "filter[status]";
export const FILTER_SEARCH_KEY = "filter[search]";
export const FILTER_BUSINESSTYPE_ID_KEY = "filter[businesstype_id]";
export const FILTER_TRADINGTYPE_ID_KEY = "filter[tradingtype_id]";
export const FILTER_PAYMENT_MODE_KEY = "filter[payment_mode]";

export const FILTER_STATE_ID_KEY = "filter[state_id]";
export const FILTER_DISTRICT_ID_KEY = "filter[district_id]";
export const FILTER_TALUKA_ID_KEY = "filter[taluka_id]";
export const FILTER_VILLAGE_ID_KEY = "filter[village_id]";
export const FILTER_VILLAGEAREA_ID_KEY = "filter[villagearea_id]";
export const FILTER_USER_TYPE_KEY = "filter[user_type]";
export const FILTER_ROLE_ID_KEY = "filter[role_id]";
export const FILTER_END_DATE_KEY = "filter[end_date]";
export const FILTER_START_DATE_KEY = "filter[start_date]";
export const FILTER_INQUIRY_FOR_KEY="filter[inquiry_for]"

export const FILTER_USERPROFILE_ID_KEY = "filter[userprofile_id]";
export const FILTER_ASSIGN_USERPROFILE_ID_KEY = "filter[assign_userprofile_id]";
export const FILTER_DATA_FOR_KEY="filter[data_for]";

export const FILTER_PRODUCTCOMPANY_ID_KEY="filter[productcompany_id]";
export const FILTER_ISGST_KEY="filter[isgst]";
export const FILTER_BUSINESSLIST_ID_KEY="filter[businesslist_id]";
export const FILTER_DISPLAY_KEY="filter[display]";
export const FILTER_PARENT_ID_KEY="filter[parent_id]";
export const FILTER_GROUP_ID_KEY="filter[group_id]";
export const FILTER_ITEMGROUP_ID_KEY="filter[itemgroup_id]";
export const FILTER_ADVERTISE_SPACE_KEY="filter[advertise_space]";

export const SORT_AZSORT_KEY="sort[azsort]";
export const SORT_RATING_KEY="sort[rating]"
export const ASC_SORT_KEY="ASC";
export const DESC_SORT_KEY="DESC";


export const COUNTRY_ID_KEY = "country_id";
export const STATE_NAME_KEY = "state_name";
export const STATE_CODE_KEY = "state_code";
export const DISTRICT_NAME_KEY = "district_name";
export const TALUKA_NAME_KEY = "taluka_name";
export const VILLAGE_NAME_KEY = "village_name";
export const AREA_NAME_KEY = "area_name";
export const IS_MAPPING_KEY = "ismaping";
export const IS_CITY_KEY = "is_city";
export const MTALUKA_ID_KEY = "mtaluka_id";
export const HAS_ZONE_KEY = "has_zone";
export const ZONE_NAME_KEY = "zone_name";
export const MZONE_NAME_KEY = "mzone_name";

export const DATA_FOR_KEY = "data_for";
export const DATA_FOR_COMPLAIN_KEY = "Complains";
export const DATA_FOR_INQUIRY_KEY = "Inquiries";
export const NOOTHER_KEY = "noother";
export const USER_ID_KEY="user_id";
export const SUBJECT_ID_KEY="subject_id";
export const MESSAGE_KEY="message";
export const ON_DATE_KEY="on_date";
export const ASSIGN_USER_ID_KEY="assign_user_id";
export const CLOSED_DATE_KEY="closed_date";
export const COMMENT_KEY="comment";
export const USERPROFILE_ID_KEY="userprofile_id";
export const ASSIGN_USERPROFILE_ID_KEY="assign_userprofile_id";
export const SUBJECT_ID_OTHER_KEY="subject_id_other";
export const USERPROFILE_ID_FOR_KEY="userprofile_id_for";
export const NEW_USER_TYPE_KEY="new_user_type";


export const MSGID_KEY = "msgid";
export const MSGSTR_KEY = "msgstr";
export const USED_KEY = "used";
export const MSGKEY_KEY = "msgkey";
export const LOCALE_KEY = "locale";
export const LANG_NAME_KEY = "lang_name";

export const RATING_KEY="rating";
export const REVIEW_KEY="review";
export const MODEL_ID_KEY="model_id";
export const MODEL_KEY="model";
export const USERPROFILES_MODEL_KEY="Userprofiles";
export const LAT_KEY="lat";
export const LNG_KEY="lng";
export const MASTER_KEY="master";
export const DISPLAY_KEY="display";
export const ADVERTISE_SPACE_KEY="advertise_space";
export const NOOFNOTIFICATION_KEY="noofnotification";
export const COST_KEY="cost";
export const ROLE_ACCESS_KEY="role_access";
export const EXTRA_NOTE_KEY="extra_note";
export const ADDRESSLINE1_KEY="addressline1";

export const INQUIRY_FOR_KEY="inquiry_for";
// export const INQUIRY_FOR_BUSINESS_KEY="Business";
// export const INQUIRY_FOR_ADMIN_KEY="Admins";
export const INQUIRY_FOR_BUSINESS_KEY="2";
export const INQUIRY_FOR_ADMIN_KEY="1";
export const PARENT_ID_KEY="parent_id";

export const GROUP_ID_KEY="group_id";
export const AUTHORIZE_PERSON1_KEY="authorize_person1";
export const AUTHORIZE_PERSON2_KEY="authorize_person2";
export const CONTACT1_KEY="contact1";
export const CONTACT2_KEY="contact2";
export const PANNO_KEY="panno";
export const ADDRESS_KEY="address";
export const OPENING_BALANCE_DATE_KEY="opening_balance_date";
export const OPENING_BALANCE_KEY="opening_balance";
export const OPENING_BALANCE_TYPE_KEY="opening_balance_type";

export const CR_KEY="cr";
export const DR_KEY="dr";
export const LEDGER_ID_KEY="ledger_id";
export const AMOUNT_KEY="amount";
export const PAYMENT_MODE_KEY="payment_mode";
export const IFSCCODE_KEY="ifsccode";
export const BANKNAME_KEY="bankname";
export const BRANCHNAME_KEY="branchname";
export const CHEQUENO_KEY="chequeno";
export const CHEQUEDATE_KEY="chequedate";
export const NARRATION_KEY="narration";
export const TRANSACTIONTYPE_ID_KEY="transactiontype_id";
export const TR_DATE_KEY="tr_date";
export const TRANSACTIONDETAILS_KEY="transactiondetails";
export const CHEQUE_STATUS_KEY="cheque_status";
export const CLEAR_DATE_KEY="chequecleardate";
export const NARRATION_APPROVE_KEY="narration_approve";
export const TRANSACTION_ID_KEY="transaction_id";

export const SENDLINK_KEY="sendlink";
export const GROUPNAME_KEY="groupname";

export const ITEMGROUP_ID_KEY="itemgroup_id";
export const UNIT_ID_KEY="unit_id";
export const OPSTOCKQUANTITY_KEY="opstockquantity";
export const OPSTOCKVALUE_KEY="opstockvalue";
export const TAXCATEGORY_ID_KEY="taxcategory_id";
export const HSNSACCODE_KEY="hsnsaccode";
export const SALESPRICE_KEY="salesprice";
export const PURCHASEPRICE_KEY="purchaseprice";
export const MRP_KEY="mrp";
export const MINIMUMSALESPRICE_KEY="minimumsalesprice";

export const QRPAY_DOC_KEY="qrpay_doc";
export const REFERENCE_NO_KEY="reference_no";
export const NOTES_KEY="notes";
export const PAYMENT_TYPE="payment_type";
export const ACCOUNT_NOTES_KEY="account_notes";

export const NEW_REFERRAL_CODE_KEY="new_referral_code";
export const ADVSETSDETAILS_KEY="advertise";
export const PUSHDETAILS_KEY="push";
export const NOOFADDS_KEY="noofadds";

export const CLOSEPROFILE_KEY="closeprofile";
export const ADVSTYPE_KEY="advstype";
export const FILTER_ADVSTYPE_KEY = "filter[advstype]";
export const USER_FOR_ID_KEY="user_for_id";
export const BUSINESSLISTSDETAILS_KEY="businesslistsdetails";
export const PROFILE_UPDATE_KEY="profile_update";
export const ACCOUNT_ID_KEY="account_id";
export const FOLLOWED_KEY="followed";
export const LIKED_KEY="liked";
export const CALL_FROM_BTN_KEY="call_from_btn";
