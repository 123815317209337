import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { ADVERTISEBANNERS_MC,BUSINESSLISTS_MC,COMPLAINS_MC,DEALERS_MC,DISTRICTS_MC,GROUPS_MC,
  INQUIRIES_MC,
  ITEMS_MC,
  LEDGERS_MC,
  PRODUCTCOMPANIES_MC,
  PRODUCTLISTS_MC,
  PUSHADVERTISEBANNERS_MC,
  ROLES_MC,
  STAFFS_MC,  STATES_MC,TALUKAS_MC,
  TRADINGTYPES_MC,
  VILLAGEAREAS_MC,
  VILLAGES_MC,
  SUBJECTS_MC,
  EMPLOYEES_MC,} from "./models/app-menu/menu-code";
import { ContactHomePage } from "./pages/contact-home/contact-home.page";
import { PrivacyPolicyPage } from "./pages/privacy-policy/privacy-policy.page";
import { AuthGuard } from "./services/auth.guard";
import { LoginAuthGuard } from "./services/login.auth.guard.services";
import { UtilsService } from "./services/uitils.services";
import {
  ADMIN_ROLE_ID,
  BUSINESS_ROLE_ID,
  CUSTOMER_ROLE_ID,
  DEALER_ROLE_ID,
  EMPLOYEE_ROLL_ID,
  STAFFS_ROLL_ID,
  SUPER_ADMIN_ROLE_ID,
  WEB,
} from "./utils/constant";
// import { AuthGuard } from './services/auth.guard';
import {
  ABOUT_US,
  ACCOUNT_LADGER,
  ACCOUNT_LADGER_REPORT,
  ACCOUNT_SETTING,
  ADDRESS_DETAILS_ROUTE,
  ADDRESS_MASTER,
  ADDRESS_MASTER_ID,
  ADD_EDIT_EMPLOYEEE,
  ADD_LANGUAGE,
  ADD_ROLE,
  ADMIN_BUSINESS_DETAIL,
  ADMIN_BUSINESS_DETAIL_ID,
  ADMIN_CUSTOMER_DETAIL_ROUTE,
  ADMIN_CUSTOMER_DETAIL_ROUTE_ID,
  ADMIN_TABS,
  ADVERTISE_BANNER,
  ADVERTISE_PUSH_BANNER,
  ADVERTISE_SETTINGS,
  ADV_UPLOAD_PIC,
  APPLY_GALLRY_SETTING,
  BANK_DETAILS_RT,
  BANK_ENTRY,
  // BANK_PAYMENT_METHOD,
  BANK_TRANSACTIONS,
  BRANCH_DEALER,
  BRANCH_DEALER_PROFILE,
  BRANCH_DEALER_PROFILE_ID,
  BUSINESS_DETAIL,
  BUSINESS_DETAIL_ID,
  BUSINESS_INFORMATION,
  BUSINESS_INFORMATION_ID,
  BUSINESS_LIST,
  BUSINESS_SUBSCRIPTION,
  BUSINESS_SUBSCRIPTION_ID,
  BUSINESS_TYPE,
  BUSINESS_TYPE_PROFILE,
  CHANGE_PASSWORD,
  CHAT_CONVERSATION,
  COMMENTS_RT,
  COMPLAINS_LIST,
  COMPLAIN_SUBJECTS,
  CONGRATULATION,
  CONTACTUS_HOME_ROUTE,
  CONTACTUS_ROUTE,
  CONTACT_INFORMATION,
  CONTACT_INFORMATION_ID,
  CREATE_ACCOUNT_SETTING,
  CREATE_ADVERTISE,
  CREATE_ADVERTISE_ID,
  CREATE_ADVERTISE_SETTINGS,
  CREATE_ADV_BANNER,
  CREATE_ADV_SCHEDULE,
  CREATE_ALLOCATE_AREA,
  CREATE_BD_STEP1,
  CREATE_BD_STEP2,
  CREATE_BD_STEP3,
  CREATE_BUSINESS,
  CREATE_COMPLAINS,
  CREATE_COMPLAIN_SUBJECTS,
  CREATE_DISTRICT,
  CREATE_GALLRY_SETTING,
  CREATE_GROUP,
  CREATE_INQUIRE,
  CREATE_ITEM_GROUP,
  CREATE_ITEM_LADGER,
  CREATE_PRODUCT,
  CREATE_PRODUCT_COMPNEY,
  CREATE_STAFF,
  CREATE_STATE,
  CREATE_TALUKA,
  CREATE_TRADING,
  CREATE_VILLAGE,
  CREATE_VILLAGESAREA,
  DEALER_TABS,
  DISCOUNTS_VIEW_RT,
  DISTRCT_ROUTE,
  EDIT_ACCOUNT_SETTING,
  EDIT_ACCOUNT_SETTING_ID,
  EDIT_ADVERTISE_SETTINGS_ID,
  EDIT_ADV_BANNER,
  EDIT_ADV_BANNER_ID,
  EDIT_ALLOCATED_AREA,
  EDIT_ALLOCATED_AREA_ID,
  EDIT_BANK_ENTRY_ID,
  // EDIT_BANK_PAYMENT_METHOD,
  // EDIT_BANK_PAYMENT_METHOD_ID,
  EDIT_BD_STEP1,
  EDIT_BD_STEP1_ID,
  EDIT_BD_STEP2,
  EDIT_BD_STEP2_ID,
  EDIT_BD_STEP3,
  EDIT_BD_STEP3_ID,
  EDIT_BUSINESS_ID,
  EDIT_BUSINESS_TYPE,
  EDIT_BUSINESS_TYPE_ID,
  EDIT_COMPLAINS,
  EDIT_COMPLAINS_ID,
  EDIT_COMPLAIN_SUBJECTS,
  EDIT_COMPLAIN_SUBJECTS_ID,
  EDIT_DEALER_CONTACT_BD,
  EDIT_DEALER_DETAILS_BD,
  EDIT_DISTRICT,
  EDIT_DISTRICT_ID,
  EDIT_EMPLOYEE_DETAILS,
  EDIT_GROUP,
  EDIT_GROUP_ID,
  EDIT_INQUIRE,
  EDIT_INQUIRE_ID,
  EDIT_ITEM_GROUP,
  EDIT_ITEM_GROUP_ID,
  EDIT_ITEM_LADGER_ID,
  EDIT_JOURNAL_ENTRY_ID,
  EDIT_LADGER_STEP1,
  EDIT_LADGER_STEP1_ID,
  EDIT_LADGER_STEP2,
  EDIT_LADGER_STEP2_ID,
  EDIT_LADGER_STEP3,
  EDIT_LADGER_STEP3_ID,
  EDIT_LANGUAGE,
  EDIT_LANGUAGE_ID,
  EDIT_PAYMENT_ENTRY_ID,
  EDIT_PAYMENT_METHOD,
  EDIT_PRODUCT_COMPNEY_ID,
  EDIT_PRODUCT_ID,
  EDIT_RECEIPT_ENTRY_ID,
  EDIT_RECEIVED_PAYMENTS,
  EDIT_ROLE,
  EDIT_ROLE_ID,
  EDIT_STAFF,
  EDIT_STAFF_DETAILS,
  EDIT_STAFF_ID,
  EDIT_STATE,
  EDIT_STATE_ID,
  EDIT_TALUKA,
  EDIT_TALUKA_ID,
  EDIT_TRADING_ID,
  EDIT_TRANSACTION_ROUTE_ID,
  EDIT_VILLAGE,
  EDIT_VILLAGESAREA,
  EDIT_VILLAGESAREA_ID,
  EDIT_VILLAGE_ID,
  EMAIL_ROUTE,
  EMPLOYEES_ROUTE,
  EMPLOYEE_TABS,
  FORGOT_MEMBER_ID,
  FORGOT_PASSWORD,
  FREE_TRIAL_DISCOUNT_RT,
  GALARRY_SETTINGS_ROUTE,
  GROUP_LIST,
  INQUIRE_LIST,
  INVITE_EARN_RT,
  ITEM_GROUP,
  ITEM_LADGER,
  ITEM_LADGER_REPORT,
  JOURNAL_ENTRY,
  // JOURNAL_PAYMENT_METHOD,
  JOURNAL_TRANSACTIONS,
  KYC_DETAILS_RT,
  LADGER_STEP1,
  LADGER_STEP2,
  LADGER_STEP3,
  LANGUAGE,
  LANGUAGE_LIST,
  LOGIN_ROUTE,
  PAYMENT_ENTRY,
  PAYMENT_METHOD,
  PAYMENT_PROOF,
  PAYMENT_SUMMARY,
  PAYMENT_SUMMARY_ID,
  PAYMENT_TRANSACTIONS,
  // PAY_PAYMENT_METHOD,
  PERSONAL_INFO,
  PRICING_RT,
  PRIVACY_POLICY,
  PRODUCT_COMPNEY_LIST,
  PRODUCT_LIST,
  PROMO_CODES_RT,
  PURCHASE_ENTRY,
  PURCHASE_RETURN,
  PUSH_ADVERTISE_SETTINGS,
  RECEIPT_ENTRY,
  // RECEIPT_PAYMENT_METHOD,
  RECEIPT_TRANSACTIONS,
  RECEIVED_PAYMENTS,
  REGISTRATION_REPORT,
  REG_STEP1,
  REG_STEP1_ID,
  REG_STEP2,
  RESET_PASSWORD,
  ROLES_ROUTE,
  SALES_ENTRY,
  SALES_RETURN,
  SEARCH_BUSINESS_LIST,
  SELECT_METHOD_RT,
  SELECT_TYPE,
  SMS_ROUTE,
  SOCIAL_MEDIA_LINKS,
  SOCIAL_MEDIA_LINKS_ID,
  STAFF_ROUTE,
  STAFF_TABS,
  STATES_ROUTE,
  SUCCESS_ERROR_MSG,
  TAB1_ROUTE,
  TABS,
  TALUKAS_ROUTE,
  TERMS_CONDITION,
  TRADING_LIST,
  TRADING_TYPE,
  TRADING_TYPE_ID,
  TRANSACTION_ROUTE,
  UPGRADE_BUSINESS,
  UPGRADE_BUSINESS_BUS,
  UPLOAD_PICTURES,
  UPLOAD_PICTURES_ID,
  VILLAGES_AREA_ROUTE,
  VILLAGES_ROUTE,
  WITHDRAW_MONEY,
} from "./utils/routes";
import {
  BUSINESS_DIRECTORY_LIST,
  VISITORS_TAB,
  VISITORS_TAB1_ROUTE,
} from "./utils/routes";

const routes: Routes = [
  {
    path: "",
    redirectTo: VISITORS_TAB1_ROUTE,
    // redirectTo:Capacitor.getPlatform() === WEB ? MAIN_HOME : VISITORS_TAB1_ROUTE,
    pathMatch: "full",
  },
  {
    path: TABS,
    // path: '',
    loadChildren: () =>
      import("./pages/tabs/tabs.module").then((m) => m.TabsPageModule),
    canActivate: [AuthGuard],
    data: { roles: [BUSINESS_ROLE_ID, CUSTOMER_ROLE_ID] },
  },
  {
    path: ADMIN_TABS,
    // path: '',
    loadChildren: () =>
      import("./pages/admin/admin-tabs/admin-tabs.module").then(
        (m) => m.AdminTabsPageModule
      ),
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID] },
  },
  {
    path: VISITORS_TAB,
    loadChildren: () =>
      import("./pages/visitor-tabs/visitor-tabs.module").then(
        (m) => m.VisitorTabsPageModule
      ),
    canActivate: [LoginAuthGuard],
  },
  {
    path: LOGIN_ROUTE,
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginPageModule),
    canActivate: [LoginAuthGuard],
  },
  // {
  //   path: 'registration',
  //   loadChildren: () => import('./pages/registration/registration.module').then( m => m.RegistrationPageModule)
  // },
  {
    path: SELECT_TYPE,
    loadChildren: () =>
      import("./pages/select-type/select-type.module").then(
        (m) => m.SelectTypePageModule
      ),
    //canActivate: [LoginAuthGuard],
  },
  {
    path: REG_STEP1,
    loadChildren: () =>
      import("./pages/reg-step1/reg-step1.module").then(
        (m) => m.RegStep1PageModule
      ),
    //canActivate: [LoginAuthGuard],
  },
  {
    path: REG_STEP1_ID,
    loadChildren: () =>
      import("./pages/reg-step1/reg-step1.module").then(
        (m) => m.RegStep1PageModule
      ),
    //canActivate: [LoginAuthGuard],
  },
  {
    path: REG_STEP2,
    loadChildren: () =>
      import("./pages/reg-steps2/reg-steps2.module").then(
        (m) => m.RegSteps2PageModule
      ),
    //canActivate: [LoginAuthGuard],
  },
  {
    path: ADDRESS_DETAILS_ROUTE,
    loadChildren: () =>
      import("./pages/reg-steps2/reg-steps2.module").then(
        (m) => m.RegSteps2PageModule
      ),
    //canActivate: [LoginAuthGuard],
  },
  {
    path: BUSINESS_TYPE,
    loadChildren: () =>
      import("./pages/business-type/business-type.module").then(
        (m) => m.BusinessTypePageModule
      ),
    //canActivate: [LoginAuthGuard],
  },
  {
    path: CONGRATULATION,
    loadChildren: () =>
      import("./pages/congratulation/congratulation.module").then(
        (m) => m.CongratulationPageModule
      ),
    //canActivate: [LoginAuthGuard],
  },
  {
    path: UPGRADE_BUSINESS,
    loadChildren: () =>
      import("./pages/upgrade-business/upgrade-business.module").then(
        (m) => m.UpgradeBusinessPageModule
      ),
    //canActivate: [LoginAuthGuard],
  },
  {
    path: FORGOT_PASSWORD,
    loadChildren: () =>
      import("./pages/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordPageModule
      ),
    //canActivate: [LoginAuthGuard],
  },
  {
    path: RESET_PASSWORD,
    loadChildren: () =>
      import("./pages/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordPageModule
      ),
    //canActivate: [LoginAuthGuard],
  },
  {
    path: SUCCESS_ERROR_MSG,
    loadChildren: () =>
      import("./pages/success-error-msg/success-error-msg.module").then(
        (m) => m.SuccessErrorMsgPageModule
      ),
    //canActivate: [LoginAuthGuard],
  },
  {
    path: PAYMENT_PROOF,
    loadChildren: () =>
      import("./pages/payment-proof/payment-proof.module").then(
        (m) => m.PaymentProofPageModule
      ),
    //canActivate: [LoginAuthGuard],
  },
  {
    path: FORGOT_MEMBER_ID,
    loadChildren: () =>
      import("./pages/forgot-memberid/forgot-memberid.module").then(
        (m) => m.ForgotMemberidPageModule
      ),
    //canActivate: [LoginAuthGuard],
  },
  {
    path: CREATE_BUSINESS,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: BUSINESSLISTS_MC },
    loadChildren: () =>
      import("./pages/create-business/create-business.module").then(
        (m) => m.CreateBusinessPageModule
      ),
  },
  {
    path: EDIT_BUSINESS_ID,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: BUSINESSLISTS_MC },
    loadChildren: () =>
      import("./pages/create-business/create-business.module").then(
        (m) => m.CreateBusinessPageModule
      ),
  },
  {
    path: BUSINESS_LIST,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: BUSINESSLISTS_MC },
    loadChildren: () =>
      import("./pages/business-list/business-list.module").then(
        (m) => m.BusinessListPageModule
      ),
  },
  {
    path: TRADING_LIST,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: TRADINGTYPES_MC },
    loadChildren: () =>
      import("./pages/trading-list/trading-list.module").then(
        (m) => m.TradingListPageModule
      ),
  },
  {
    path: CREATE_TRADING,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: TRADINGTYPES_MC },
    loadChildren: () =>
      import("./pages/create-trading/create-trading.module").then(
        (m) => m.CreateTradingPageModule
      ),
  },
  {
    path: EDIT_TRADING_ID,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: TRADINGTYPES_MC },
    loadChildren: () =>
      import("./pages/create-trading/create-trading.module").then(
        (m) => m.CreateTradingPageModule
      ),
  },
  {
    path: PRODUCT_LIST,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: PRODUCTLISTS_MC },
    loadChildren: () =>
      import("./pages/product-list/product-list.module").then(
        (m) => m.ProductListPageModule
      ),
  },
  {
    path: CREATE_PRODUCT,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: PRODUCTLISTS_MC },
    loadChildren: () =>
      import("./pages/create-product/create-product.module").then(
        (m) => m.CreateProductPageModule
      ),
  },
  {
    path: EDIT_PRODUCT_ID,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: PRODUCTLISTS_MC },
    loadChildren: () =>
      import("./pages/create-product/create-product.module").then(
        (m) => m.CreateProductPageModule
      ),
  },
  {
    path: BUSINESS_INFORMATION,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID,BUSINESS_ROLE_ID] },
    loadChildren: () =>
      import("./pages/business-information/business-information.module").then(
        (m) => m.BusinessInformationPageModule
      ),
  },
  {
    path: BUSINESS_INFORMATION_ID,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID,BUSINESS_ROLE_ID] },
    loadChildren: () =>
      import("./pages/business-information/business-information.module").then(
        (m) => m.BusinessInformationPageModule
      ),
  },
  {
    path: TRADING_TYPE,
    loadChildren: () =>
      import("./pages/trading-type/trading-type.module").then(
        (m) => m.TradingTypePageModule
      ),
  },
  {
    path: TRADING_TYPE_ID,
    loadChildren: () =>
      import("./pages/trading-type/trading-type.module").then(
        (m) => m.TradingTypePageModule
      ),
  },
  {
    path: UPLOAD_PICTURES,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID,BUSINESS_ROLE_ID] },
    loadChildren: () =>
      import("./pages/upload-pictures/upload-pictures.module").then(
        (m) => m.UploadPicturesPageModule
      ),
  },
  {
    path: UPLOAD_PICTURES_ID,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID,BUSINESS_ROLE_ID] },
    loadChildren: () =>
      import("./pages/upload-pictures/upload-pictures.module").then(
        (m) => m.UploadPicturesPageModule
      ),
  },
  {
    path: CONTACT_INFORMATION,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID,BUSINESS_ROLE_ID] },
    loadChildren: () =>
      import("./pages/contact-information/contact-information.module").then(
        (m) => m.ContactInformationPageModule
      ),
  },
  {
    path: CONTACT_INFORMATION_ID,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID,BUSINESS_ROLE_ID] },
    loadChildren: () =>
      import("./pages/contact-information/contact-information.module").then(
        (m) => m.ContactInformationPageModule
      ),
  },
  {
    path: UPGRADE_BUSINESS_BUS,
    loadChildren: () =>
      import("./pages/upgrade-business/upgrade-business.module").then(
        (m) => m.UpgradeBusinessPageModule
      ),
  },
  {
    path: CHANGE_PASSWORD,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/change-password/change-password.module").then(
        (m) => m.ChangePasswordPageModule
      ),
   
  },
  {
    path: PERSONAL_INFO,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/reg-step1/reg-step1.module").then(
        (m) => m.RegStep1PageModule
      ),
    
  },
  {
    path: ADDRESS_MASTER,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/reg-steps2/reg-steps2.module").then(
        (m) => m.RegSteps2PageModule
      ),
   
  },
  {
    path: ADDRESS_MASTER_ID,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/reg-steps2/reg-steps2.module").then(
        (m) => m.RegSteps2PageModule
      ),
    
  },
  // {
  //   path: BUSINESS_SUBSCRIPTION,
  //   // canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import("./pages/business-subscription/business-subscription.module").then(
  //       (m) => m.BusinessSubscriptionPageModule
  //     ),
  
  // },
  // {
  //   path: BUSINESS_SUBSCRIPTION_ID,
  //   // canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import("./pages/business-subscription/business-subscription.module").then(
  //       (m) => m.BusinessSubscriptionPageModule
  //     ),
   
  // },
   {
    path: PAYMENT_SUMMARY,
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/business-subscription/business-subscription.module").then(
        (m) => m.BusinessSubscriptionPageModule
      ),
  
  },
  {
    path: PAYMENT_SUMMARY_ID,
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/business-subscription/business-subscription.module").then(
        (m) => m.BusinessSubscriptionPageModule
      ),
  
  },
  {
    path: BUSINESS_SUBSCRIPTION,
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/received-payments/received-payments.module").then(
        (m) => m.ReceivedPaymentsPageModule
      ),
  
  },
  {
    path: BUSINESS_TYPE_PROFILE,
    loadChildren: () =>
      import("./pages/business-type/business-type.module").then(
        (m) => m.BusinessTypePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: ADMIN_BUSINESS_DETAIL,
    loadChildren: () =>
      import("./pages/admin-business-detail/admin-business-detail.module").then(
        (m) => m.AdminBusinessDetailPageModule
      ),
  },
  {
    path: ADMIN_BUSINESS_DETAIL_ID,
    loadChildren: () =>
      import("./pages/admin-business-detail/admin-business-detail.module").then(
        (m) => m.AdminBusinessDetailPageModule
      ),
  },
  {
    path: CREATE_INQUIRE,
    canActivate: [AuthGuard],
    // data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: INQUIRIES_MC },
    data: { hasAccess: INQUIRIES_MC },
    loadChildren: () =>
      import("./pages/create-inquiries/create-inquiries.module").then(
        (m) => m.CreateInquiriesPageModule
      ),
  },
  {
    path: EDIT_INQUIRE_ID,
    canActivate: [AuthGuard],
    // data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: INQUIRIES_MC },
    data: { hasAccess: INQUIRIES_MC },
    loadChildren: () =>
      import("./pages/create-inquiries/create-inquiries.module").then(
        (m) => m.CreateInquiriesPageModule
      ),
  },
  {
    path: EDIT_INQUIRE,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: INQUIRIES_MC },
    loadChildren: () =>
      import("./pages/create-inquiries/create-inquiries.module").then(
        (m) => m.CreateInquiriesPageModule
      ),
  },
  {
    path: CREATE_COMPLAINS,
    canActivate: [AuthGuard],
    // data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: COMPLAINS_MC },
    data: { hasAccess: COMPLAINS_MC },
    loadChildren: () =>
      import("./pages/create-complains/create-complains.module").then(
        (m) => m.CreateComplainsPageModule
      ),
  },
  {
    path: EDIT_COMPLAINS_ID,
    canActivate: [AuthGuard],
    // data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: COMPLAINS_MC },
    data: { hasAccess: COMPLAINS_MC },
    loadChildren: () =>
      import("./pages/create-complains/create-complains.module").then(
        (m) => m.CreateComplainsPageModule
      ),
  },
  {
    path: EDIT_COMPLAINS,
    canActivate: [AuthGuard],
    // data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: COMPLAINS_MC },
    data: { hasAccess: COMPLAINS_MC },
    loadChildren: () =>
      import("./pages/create-complains/create-complains.module").then(
        (m) => m.CreateComplainsPageModule
      ),
  },
  {
    path: COMPLAINS_LIST,
    canActivate: [AuthGuard],
    // data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID,BUSINESS_ROLE_ID], hasAccess: COMPLAINS_MC },
    data: { hasAccess: COMPLAINS_MC },
    loadChildren: () =>
      import("./pages/complains-list/complains-list.module").then(
        (m) => m.ComplainsListPageModule
      ),
  },
  {
    path: INQUIRE_LIST,
    canActivate: [AuthGuard],
    // data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: INQUIRIES_MC },
    data: {  hasAccess: INQUIRIES_MC },
    loadChildren: () =>
      import("./pages/inquirie-list/inquirie-list.module").then(
        (m) => m.InquirieListPageModule
      ),
  },
  {
    path: LANGUAGE,
    loadChildren: () =>
      import("./pages/languge/languge.module").then((m) => m.LangugePageModule),
  },
  {
    path: BUSINESS_DETAIL,
    loadChildren: () =>
      import("./pages/business-detail/business-detail.module").then(
        (m) => m.BusinessDetailPageModule
      ),
  },
  {
    path: BUSINESS_DETAIL_ID,
    loadChildren: () =>
      import("./pages/business-detail/business-detail.module").then(
        (m) => m.BusinessDetailPageModule
      ),
  },
  {
    path: SEARCH_BUSINESS_LIST,
    loadChildren: () => import('./pages/business-directory/business-directory.module').then( m => m.BusinessDirectoryPageModule)
  },
  {
    path: PRODUCT_COMPNEY_LIST,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: PRODUCTCOMPANIES_MC },
    loadChildren: () =>
      import("./pages/product-compney-list/product-compney-list.module").then(
        (m) => m.ProductCompneyListPageModule
      ),
  },
  {
    path: CREATE_PRODUCT_COMPNEY,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: PRODUCTCOMPANIES_MC },
    loadChildren: () =>
      import(
        "./pages/create-product-compney/create-product-compney.module"
      ).then((m) => m.CreateProductCompneyPageModule),
  },
  {
    path: EDIT_PRODUCT_COMPNEY_ID,
     canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: PRODUCTCOMPANIES_MC },
    loadChildren: () =>
      import(
        "./pages/create-product-compney/create-product-compney.module"
      ).then((m) => m.CreateProductCompneyPageModule),
  },
  {
    path: "admin/profile",
    loadChildren: () =>
      import("./pages/admin-profile/admin-profile.module").then(
        (m) => m.AdminProfilePageModule
      ),
  },
  {
    path: GROUP_LIST,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: GROUPS_MC },
    loadChildren: () =>
      import("./pages/group-list/group-list.module").then(
        (m) => m.GroupListPageModule
      ),
  },
  {
    path: ITEM_GROUP,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: ITEMS_MC },
    loadChildren: () =>
      import("./pages/item-group/item-group.module").then(
        (m) => m.ItemGroupPageModule
      ),
  },
  {
    path: BRANCH_DEALER,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: DEALERS_MC },
    loadChildren: () =>
      import("./pages/branch-dealer/branch-dealer.module").then(
        (m) => m.BranchDealerPageModule
      ),
  },
  {
    path: STAFF_ROUTE,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: STAFFS_MC },
    loadChildren: () =>
      import("./pages/staff/staff.module").then((m) => m.StaffPageModule),

  },
  
  {
    path: ROLES_ROUTE,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: ROLES_MC },
    loadChildren: () =>
      import("./pages/roles/roles.module").then((m) => m.RolesPageModule),
  },
  {
    path: ADVERTISE_BANNER,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: ADVERTISEBANNERS_MC },
    loadChildren: () =>
      import("./pages/advertise-banner/advertise-banner.module").then(
        (m) => m.AdvertiseBannerPageModule
      ),
  },
  {
    path: SMS_ROUTE,
    loadChildren: () =>
      import("./pages/sms/sms.module").then((m) => m.SmsPageModule),
  },
  {
    path: EMAIL_ROUTE,
    loadChildren: () =>
      import("./pages/email/email.module").then((m) => m.EmailPageModule),
  },
  {
    path: GALARRY_SETTINGS_ROUTE,
    loadChildren: () =>
      import("./pages/gallary-settings/gallary-settings.module").then(
        (m) => m.GallarySettingsPageModule
      ),
  },
  {
    path: STATES_ROUTE,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: STATES_MC },
    loadChildren: () =>
      import("./pages/states/states.module").then((m) => m.StatesPageModule),
  },
  {
    path: DISTRCT_ROUTE,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: DISTRICTS_MC },
    loadChildren: () =>
      import("./pages/districts/districts.module").then(
        (m) => m.DistrictsPageModule
      ),
  },
  {
    path: TALUKAS_ROUTE,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: TALUKAS_MC },
    loadChildren: () =>
      import("./pages/talukas/talukas.module").then((m) => m.TalukasPageModule),
  },
  {
    path: VILLAGES_ROUTE,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: VILLAGES_MC },
    loadChildren: () =>
      import("./pages/villages/villages.module").then(
        (m) => m.VillagesPageModule
      ),
  },
  {
    path: VILLAGES_AREA_ROUTE,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: VILLAGEAREAS_MC },
    loadChildren: () =>
      import("./pages/villages-area/villages-area.module").then(
        (m) => m.VillagesAreaPageModule
      ),
  },
  {
    path: CREATE_STATE,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: STATES_MC },
    loadChildren: () =>
      import("./pages/create-states/create-states.module").then(
        (m) => m.CreateStatesPageModule
      ),
  },
  {
    path: EDIT_STATE_ID,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: STATES_MC },
    loadChildren: () =>
      import("./pages/create-states/create-states.module").then(
        (m) => m.CreateStatesPageModule
      ),
  },
  {
    path: EDIT_STATE,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: STATES_MC },
    loadChildren: () =>
      import("./pages/create-states/create-states.module").then(
        (m) => m.CreateStatesPageModule
      ),
  },
  {
    path: CREATE_DISTRICT,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: DISTRICTS_MC },
    loadChildren: () =>
      import("./pages/create-districts/create-districts.module").then(
        (m) => m.CreateDistrictsPageModule
      ),
  },
  {
    path: EDIT_DISTRICT_ID,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: DISTRICTS_MC },
    loadChildren: () =>
      import("./pages/create-districts/create-districts.module").then(
        (m) => m.CreateDistrictsPageModule
      ),
  },
  {
    path: EDIT_DISTRICT,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: DISTRICTS_MC },
    loadChildren: () =>
      import("./pages/create-districts/create-districts.module").then(
        (m) => m.CreateDistrictsPageModule
      ),
  },
  {
    path: CREATE_TALUKA,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: TALUKAS_MC },
    loadChildren: () =>
      import("./pages/create-talukas/create-talukas.module").then(
        (m) => m.CreateTalukasPageModule
      ),
  },
  {
    path: EDIT_TALUKA_ID,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: TALUKAS_MC },
    loadChildren: () =>
      import("./pages/create-talukas/create-talukas.module").then(
        (m) => m.CreateTalukasPageModule
      ),
  },
  {
    path: EDIT_TALUKA,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: TALUKAS_MC },
    loadChildren: () =>
      import("./pages/create-talukas/create-talukas.module").then(
        (m) => m.CreateTalukasPageModule
      ),
  },
  {
    path: CREATE_VILLAGE,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: VILLAGES_MC },
    loadChildren: () =>
      import("./pages/create-villages/create-villages.module").then(
        (m) => m.CreateVillagesPageModule
      ),
  },
  {
    path: EDIT_VILLAGE_ID,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: VILLAGES_MC },
    loadChildren: () =>
      import("./pages/create-villages/create-villages.module").then(
        (m) => m.CreateVillagesPageModule
      ),
  },
  {
    path: EDIT_VILLAGE,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: VILLAGES_MC },
    loadChildren: () =>
      import("./pages/create-villages/create-villages.module").then(
        (m) => m.CreateVillagesPageModule
      ),
  },
  {
    path: CREATE_VILLAGESAREA,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: VILLAGEAREAS_MC },
    loadChildren: () =>
      import("./pages/create-villages-area/create-villages-area.module").then(
        (m) => m.CreateVillagesAreaPageModule
      ),
  },
  {
    path: EDIT_VILLAGESAREA_ID,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: VILLAGEAREAS_MC },
    loadChildren: () =>
      import("./pages/create-villages-area/create-villages-area.module").then(
        (m) => m.CreateVillagesAreaPageModule
      ),
  },
  {
    path: EDIT_VILLAGESAREA,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: VILLAGEAREAS_MC },
    loadChildren: () =>
      import("./pages/create-villages-area/create-villages-area.module").then(
        (m) => m.CreateVillagesAreaPageModule
      ),
  },
  {
    path: EDIT_DEALER_DETAILS_BD,
    loadChildren: () =>
      import("./pages/bd-step1/bd-step1.module").then(
        (m) => m.BdStep1PageModule
      ),
  },
  {
    path: CREATE_BD_STEP1,
    loadChildren: () =>
      import("./pages/bd-step1/bd-step1.module").then(
        (m) => m.BdStep1PageModule
      ),
  },
  {
    path: EDIT_BD_STEP1_ID,
    loadChildren: () =>
      import("./pages/bd-step1/bd-step1.module").then(
        (m) => m.BdStep1PageModule
      ),
  },
  {
    path: EDIT_BD_STEP1,
    loadChildren: () =>
      import("./pages/bd-step1/bd-step1.module").then(
        (m) => m.BdStep1PageModule
      ),
  },
  {
    path: CREATE_BD_STEP2,
    loadChildren: () =>
      import("./pages/bd-step2/bd-step2.module").then(
        (m) => m.BdStep2PageModule
      ),
  },
  {
    path: EDIT_BD_STEP2_ID,
    loadChildren: () =>
      import("./pages/bd-step2/bd-step2.module").then(
        (m) => m.BdStep2PageModule
      ),
  },
  {
    path: EDIT_BD_STEP2,
    loadChildren: () =>
      import("./pages/bd-step2/bd-step2.module").then(
        (m) => m.BdStep2PageModule
      ),
  },
  {
    path: EDIT_DEALER_CONTACT_BD,
    loadChildren: () =>
      import("./pages/bd-step3/bd-step3.module").then(
        (m) => m.BdStep3PageModule
      ),
  },
  {
    path: CREATE_BD_STEP3,
    loadChildren: () =>
      import("./pages/bd-step3/bd-step3.module").then(
        (m) => m.BdStep3PageModule
      ),
  },
  {
    path: EDIT_BD_STEP3_ID,
    loadChildren: () =>
      import("./pages/bd-step3/bd-step3.module").then(
        (m) => m.BdStep3PageModule
      ),
  },
  {
    path: EDIT_BD_STEP3,
    loadChildren: () =>
      import("./pages/bd-step3/bd-step3.module").then(
        (m) => m.BdStep3PageModule
      ),
  },
  {
    path: BRANCH_DEALER_PROFILE_ID,
    loadChildren: () =>
      import("./pages/bd-profile/bd-profile.module").then(
        (m) => m.BdProfilePageModule
      ),
  },
  {
    path: CREATE_GROUP,
    loadChildren: () =>
      import("./pages/create-group/create-group.module").then(
        (m) => m.CreateGroupPageModule
      ),
  },
  {
    path: EDIT_GROUP_ID,
    loadChildren: () =>
      import("./pages/create-group/create-group.module").then(
        (m) => m.CreateGroupPageModule
      ),
  },
  {
    path: EDIT_GROUP,
    loadChildren: () =>
      import("./pages/create-group/create-group.module").then(
        (m) => m.CreateGroupPageModule
      ),
  },
  {
    path: CREATE_ITEM_GROUP,
    loadChildren: () =>
      import("./pages/create-item-group/create-item-group.module").then(
        (m) => m.CreateItemGroupPageModule
      ),
  },
  {
    path: EDIT_ITEM_GROUP_ID,
    loadChildren: () =>
      import("./pages/create-item-group/create-item-group.module").then(
        (m) => m.CreateItemGroupPageModule
      ),
  },
  {
    path: EDIT_ITEM_GROUP,
    loadChildren: () =>
      import("./pages/create-item-group/create-item-group.module").then(
        (m) => m.CreateItemGroupPageModule
      ),
  },
  {
    path: COMPLAIN_SUBJECTS,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: SUBJECTS_MC },
    loadChildren: () =>
      import("./pages/complain-subjects/complain-subjects.module").then(
        (m) => m.ComplainSubjectsPageModule
      ),
  },
  {
    path: CREATE_COMPLAIN_SUBJECTS,
    loadChildren: () =>
      import("./pages/create-subjects/create-subjects.module").then(
        (m) => m.CreateSubjectsPageModule
      ),
  },
  {
    path: EDIT_COMPLAIN_SUBJECTS_ID,
    loadChildren: () =>
      import("./pages/create-subjects/create-subjects.module").then(
        (m) => m.CreateSubjectsPageModule
      ),
  },
  {
    path: EDIT_COMPLAIN_SUBJECTS,
    loadChildren: () =>
      import("./pages/create-subjects/create-subjects.module").then(
        (m) => m.CreateSubjectsPageModule
      ),
  },
  {
    path: EDIT_STAFF_DETAILS,
    loadChildren: () =>
      import("./pages/create-staff/create-staff.module").then(
        (m) => m.CreateStaffPageModule
      ),
  },
  {
    path: CREATE_STAFF,
    loadChildren: () =>
      import("./pages/create-staff/create-staff.module").then(
        (m) => m.CreateStaffPageModule
      ),
  },
  {
    path: EDIT_STAFF_ID,
    loadChildren: () =>
      import("./pages/create-staff/create-staff.module").then(
        (m) => m.CreateStaffPageModule
      ),
  },
  {
    path: EDIT_STAFF,
    loadChildren: () =>
      import("./pages/create-staff/create-staff.module").then(
        (m) => m.CreateStaffPageModule
      ),
  },
  {
    path: REGISTRATION_REPORT,
    loadChildren: () =>
      import("./pages/registration-report/registration-report.module").then(
        (m) => m.RegistrationReportPageModule
      ),
  },
  {
    path: ADVERTISE_PUSH_BANNER,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: PUSHADVERTISEBANNERS_MC },
    loadChildren: () =>
      import("./pages/advertise-push-banner/advertise-push-banner.module").then(
        (m) => m.AdvertisePushBannerPageModule
      ),
  },
  {
    path: ACCOUNT_LADGER,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: LEDGERS_MC },
    loadChildren: () =>
      import("./pages/account-ladger/account-ladger.module").then(
        (m) => m.AccountLadgerPageModule
      ),

  },
  {
    path: ITEM_LADGER,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: LEDGERS_MC },
    loadChildren: () =>
      import("./pages/item-ladger/item-ladger.module").then(
        (m) => m.ItemLadgerPageModule
      ),
  },
  {
    path: SALES_ENTRY,
    loadChildren: () =>
      import("./pages/sales-entry/sales-entry.module").then(
        (m) => m.SalesEntryPageModule
      ),
  },
  {
    path: PURCHASE_ENTRY,
    loadChildren: () =>
      import("./pages/purchase-entry/purchase-entry.module").then(
        (m) => m.PurchaseEntryPageModule
      ),
  },
  {
    path: BANK_ENTRY,
    loadChildren: () =>
      import("./pages/bank-entry/bank-entry.module").then(
        (m) => m.BankEntryPageModule
      ),
  },
  {
    path: EDIT_BANK_ENTRY_ID,
    loadChildren: () =>
      import("./pages/bank-entry/bank-entry.module").then(
        (m) => m.BankEntryPageModule
      ),
  },
  {
    path: PAYMENT_ENTRY,
    loadChildren: () =>
      import("./pages/payment-entry/payment-entry.module").then(
        (m) => m.PaymentEntryPageModule
      ),
  },
  {
    path: EDIT_PAYMENT_ENTRY_ID,
    loadChildren: () =>
      import("./pages/payment-entry/payment-entry.module").then(
        (m) => m.PaymentEntryPageModule
      ),
  },
  {
    path: RECEIPT_ENTRY,
    loadChildren: () =>
      import("./pages/receipt-entry/receipt-entry.module").then(
        (m) => m.ReceiptEntryPageModule
      ),
  },
  {
    path: EDIT_RECEIPT_ENTRY_ID,
    loadChildren: () =>
      import("./pages/receipt-entry/receipt-entry.module").then(
        (m) => m.ReceiptEntryPageModule
      ),
  },
  {
    path: JOURNAL_ENTRY,
    loadChildren: () =>
      import("./pages/journal-entry/journal-entry.module").then(
        (m) => m.JournalEntryPageModule
      ),
  },
  {
    path: EDIT_JOURNAL_ENTRY_ID,
    loadChildren: () =>
      import("./pages/journal-entry/journal-entry.module").then(
        (m) => m.JournalEntryPageModule
      ),
  },
  {
    path: SALES_RETURN,
    loadChildren: () =>
      import("./pages/sales-return/sales-return.module").then(
        (m) => m.SalesReturnPageModule
      ),
  },
  {
    path: PURCHASE_RETURN,
    loadChildren: () =>
      import("./pages/purchase-return/purchase-return.module").then(
        (m) => m.PurchaseReturnPageModule
      ),
  },
  {
    path: ACCOUNT_LADGER_REPORT,
    loadChildren: () =>
      import("./pages/account-ladger-report/account-ladger-report.module").then(
        (m) => m.AccountLadgerReportPageModule
      ),
    canActivate: [AuthGuard],
    // data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID,BUSINESS_ROLE_ID] },
  },
  {
    path: ITEM_LADGER_REPORT,
    loadChildren: () =>
      import("./pages/item-ladger-report/item-ladger-report.module").then(
        (m) => m.ItemLadgerReportPageModule
      ),
  },
  {
    path: ACCOUNT_SETTING,
    loadChildren: () =>
      import("./pages/account-setting/account-setting.module").then(
        (m) => m.AccountSettingPageModule
      ),
  },
  {
    path: CREATE_ACCOUNT_SETTING,
    loadChildren: () =>
      import(
        "./pages/create-account-setting/create-account-setting.module"
      ).then((m) => m.CreateAccountSettingPageModule),
  },
  {
    path: EDIT_ACCOUNT_SETTING_ID,
    loadChildren: () =>
      import(
        "./pages/create-account-setting/create-account-setting.module"
      ).then((m) => m.CreateAccountSettingPageModule),
  },
  {
    path: EDIT_ACCOUNT_SETTING,
    loadChildren: () =>
      import(
        "./pages/create-account-setting/create-account-setting.module"
      ).then((m) => m.CreateAccountSettingPageModule),
  },
  {
    path: CONTACTUS_HOME_ROUTE,
    loadChildren: () =>
      import("./pages/contact-home/contact-home.module").then(
        (m) => m.ContactHomePageModule
      ),
  },
  {
    path: CREATE_GALLRY_SETTING,
    loadChildren: () =>
      import(
        "./pages/create-gallery-setting/create-gallery-setting.module"
      ).then((m) => m.CreateGallerySettingPageModule),
  },
  {
    path: APPLY_GALLRY_SETTING,
    loadChildren: () =>
      import("./pages/apply-gallery-setting/apply-gallery-setting.module").then(
        (m) => m.ApplyGallerySettingPageModule
      ),
  },
  {
    path: CREATE_ADV_BANNER,
    loadChildren: () =>
      import("./pages/create-adv-banner/create-adv-banner.module").then(
        (m) => m.CreateAdvBannerPageModule
      ),
  },
  {
    path: EDIT_ADV_BANNER_ID,
    loadChildren: () =>
      import("./pages/create-adv-banner/create-adv-banner.module").then(
        (m) => m.CreateAdvBannerPageModule
      ),
  },
  {
    path: EDIT_ADV_BANNER,
    loadChildren: () =>
      import("./pages/create-adv-banner/create-adv-banner.module").then(
        (m) => m.CreateAdvBannerPageModule
      ),
  },
  {
    path: CREATE_ADVERTISE_ID,
    loadChildren: () =>
      import("./pages/create-adv-banner/create-adv-banner.module").then(
        (m) => m.CreateAdvBannerPageModule
      ),
  },
  {
    path: CREATE_ADVERTISE,
    loadChildren: () =>
      import("./pages/create-adv-banner/create-adv-banner.module").then(
        (m) => m.CreateAdvBannerPageModule
      ),
  },
  {
    path: ADV_UPLOAD_PIC,
    loadChildren: () =>
      import("./pages/adv-upload-pic/adv-upload-pic.module").then(
        (m) => m.AdvUploadPicPageModule
      ),
  },
  {
    path: CHAT_CONVERSATION,
    loadChildren: () =>
      import("./pages/chat-conversation/chat-conversation.module").then(
        (m) => m.ChatConversationPageModule
      ),
  },
  {
    path: BANK_TRANSACTIONS,
    loadChildren: () =>
      import("./pages/bank-transactions/bank-transactions.module").then(
        (m) => m.BankTransactionsPageModule
      ),
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID] },
  },
  {
    path: PAYMENT_TRANSACTIONS,
    loadChildren: () =>
      import("./pages/payment-transactions/payment-transactions.module").then(
        (m) => m.PaymentTransactionsPageModule
      ),
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID] },
  },
  {
    path: RECEIPT_TRANSACTIONS,
    loadChildren: () =>
      import("./pages/receipt-transactions/receipt-transactions.module").then(
        (m) => m.ReceiptTransactionsPageModule
      ),
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID] },
  },
  {
    path: JOURNAL_TRANSACTIONS,
    loadChildren: () =>
      import("./pages/journal-transactions/journal-transactions.module").then(
        (m) => m.JournalTransactionsPageModule
      ),
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID] },
  },
  // {
  //   path: BANK_PAYMENT_METHOD,
  //   loadChildren: () =>
  //     import("./pages/payment-method/payment-method.module").then(
  //       (m) => m.PaymentMethodPageModule
  //     ),
  // },
  // {
  //   path: EDIT_BANK_PAYMENT_METHOD_ID,
  //   loadChildren: () =>
  //     import("./pages/payment-method/payment-method.module").then(
  //       (m) => m.PaymentMethodPageModule
  //     ),
  // },
  // {
  //   path: EDIT_BANK_PAYMENT_METHOD,
  //   loadChildren: () =>
  //     import("./pages/payment-method/payment-method.module").then(
  //       (m) => m.PaymentMethodPageModule
  //     ),
  // },
  {
    path: PAYMENT_METHOD,
    loadChildren: () =>
      import("./pages/payment-method/payment-method.module").then(
        (m) => m.PaymentMethodPageModule
      ),
  },
  {
    path: EDIT_PAYMENT_METHOD,
    loadChildren: () =>
      import("./pages/payment-method/payment-method.module").then(
        (m) => m.PaymentMethodPageModule
      ),
  },
  // {
  //   path: PAY_PAYMENT_METHOD,
  //   loadChildren: () =>
  //     import("./pages/payment-method/payment-method.module").then(
  //       (m) => m.PaymentMethodPageModule
  //     ),
  // },
  // {
  //   path: RECEIPT_PAYMENT_METHOD,
  //   loadChildren: () =>
  //     import("./pages/payment-method/payment-method.module").then(
  //       (m) => m.PaymentMethodPageModule
  //     ),
  // },
  // {
  //   path: JOURNAL_PAYMENT_METHOD,
  //   loadChildren: () =>
  //     import("./pages/payment-method/payment-method.module").then(
  //       (m) => m.PaymentMethodPageModule
  //     ),
  // },
  {
    path: LADGER_STEP1,
    loadChildren: () =>
      import("./pages/ladger-step1/ladger-step1.module").then(
        (m) => m.LadgerStep1PageModule
      ),
  },
  {
    path: EDIT_LADGER_STEP1,
    loadChildren: () =>
      import("./pages/ladger-step1/ladger-step1.module").then(
        (m) => m.LadgerStep1PageModule
      ),
  },
  {
    path: EDIT_LADGER_STEP1_ID,
    loadChildren: () =>
      import("./pages/ladger-step1/ladger-step1.module").then(
        (m) => m.LadgerStep1PageModule
      ),
  },
  {
    path: LADGER_STEP2,
    loadChildren: () =>
      import("./pages/ladger-step2/ladger-step2.module").then(
        (m) => m.LadgerStep2PageModule
      ),
  },
  {
    path: EDIT_LADGER_STEP2,
    loadChildren: () =>
      import("./pages/ladger-step2/ladger-step2.module").then(
        (m) => m.LadgerStep2PageModule
      ),
  },
  {
    path: EDIT_LADGER_STEP2_ID,
    loadChildren: () =>
      import("./pages/ladger-step2/ladger-step2.module").then(
        (m) => m.LadgerStep2PageModule
      ),
  },
  {
    path: LADGER_STEP3,
    loadChildren: () =>
      import("./pages/ladger-step3/ladger-step3.module").then(
        (m) => m.LadgerStep3PageModule
      ),
  },
  {
    path: EDIT_LADGER_STEP3,
    loadChildren: () =>
      import("./pages/ladger-step3/ladger-step3.module").then(
        (m) => m.LadgerStep3PageModule
      ),
  },
  {
    path: EDIT_LADGER_STEP3_ID,
    loadChildren: () =>
      import("./pages/ladger-step3/ladger-step3.module").then(
        (m) => m.LadgerStep3PageModule
      ),
  },
  {
    path: TRANSACTION_ROUTE,
    loadChildren: () =>
      import("./pages/transaction/transaction.module").then(
        (m) => m.TransactionPageModule
      ),
  },
  {
    path: EDIT_TRANSACTION_ROUTE_ID,
    loadChildren: () =>
      import("./pages/transaction/transaction.module").then(
        (m) => m.TransactionPageModule
      ),
  },
  {
    path: LANGUAGE_LIST,
    loadChildren: () =>
      import("./pages/language-list/language-list.module").then(
        (m) => m.LanguageListPageModule
      ),
  },
  {
    path: ADD_LANGUAGE,
    loadChildren: () =>
      import("./pages/add-language/add-language.module").then(
        (m) => m.AddLanguagePageModule
      ),
  },
  {
    path: EDIT_LANGUAGE_ID,
    loadChildren: () =>
      import("./pages/add-language/add-language.module").then(
        (m) => m.AddLanguagePageModule
      ),
  },
  {
    path: EDIT_LANGUAGE,
    loadChildren: () =>
      import("./pages/add-language/add-language.module").then(
        (m) => m.AddLanguagePageModule
      ),
  },
  {
    path: CREATE_ALLOCATE_AREA,
    loadChildren: () => import('./pages/create-allocated-area/create-allocated-area.module').then(m => m.CreateAllocatedAreaPageModule)
  },
  {
    path: EDIT_ALLOCATED_AREA_ID,
    loadChildren: () => import('./pages/create-allocated-area/create-allocated-area.module').then(m => m.CreateAllocatedAreaPageModule)
  },
  {
    path: EDIT_ALLOCATED_AREA,
    loadChildren: () => import('./pages/create-allocated-area/create-allocated-area.module').then(m => m.CreateAllocatedAreaPageModule)
  },
  {
    path: SOCIAL_MEDIA_LINKS,
    loadChildren: () =>
      import("./pages/social-media-links/social-media-links.module").then(
        (m) => m.SocialMediaLinksPageModule
      ),
  },
  {
    path: SOCIAL_MEDIA_LINKS_ID,
    loadChildren: () =>
      import("./pages/social-media-links/social-media-links.module").then(
        (m) => m.SocialMediaLinksPageModule
      ),
  },
  {
    path: ADMIN_CUSTOMER_DETAIL_ROUTE,
    loadChildren: () => import('./pages/admin-cutomer-details/admin-cutomer-details.module').then(m => m.AdminCutomerDetailsPageModule)
  },
  {
    path: ADMIN_CUSTOMER_DETAIL_ROUTE_ID,
    loadChildren: () => import('./pages/admin-cutomer-details/admin-cutomer-details.module').then(m => m.AdminCutomerDetailsPageModule)
  },
  {
    path: ADD_ROLE,
    loadChildren: () => import('./pages/create-role/create-role.module').then(m => m.CreateRolePageModule)
  },
  {
    path: EDIT_ROLE_ID,
    loadChildren: () => import('./pages/create-role/create-role.module').then(m => m.CreateRolePageModule)
  },
  {
    path: EDIT_ROLE,
    loadChildren: () => import('./pages/create-role/create-role.module').then(m => m.CreateRolePageModule)
  },
  {
    path: INVITE_EARN_RT,
    loadChildren: () => import('./pages/invite-earn/invite-earn.module').then(m => m.InviteEarnPageModule)
  },
  {
    path: KYC_DETAILS_RT,
    loadChildren: () => import('./pages/kyc-details/kyc-details.module').then(m => m.KycDetailsPageModule)
  },
  {
    path: BANK_DETAILS_RT,
    loadChildren: () => import('./pages/bank-details/bank-details.module').then(m => m.BankDetailsPageModule)
  },
  {
    path: STAFF_TABS,
    loadChildren: () => import('./pages/staff-tabs/staff-tabs.module').then(m => m.StaffTabsPageModule)
  },
  {
    path: DEALER_TABS,
    loadChildren: () => import('./pages/dealer-tabs/dealer-tabs.module').then(m => m.DealerTabsPageModule)
  },
  {
    path: PRIVACY_POLICY,
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
  },
  {
    path: TERMS_CONDITION,
    loadChildren: () => import('./pages/terms-condition/terms-condition.module').then(m => m.TermsConditionPageModule)
  },
  {
    path: ABOUT_US,
    loadChildren: () => import('./pages/about-us/about-us.module').then(m => m.AboutUsPageModule)
  },
  {
    path:CREATE_ITEM_LADGER,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: LEDGERS_MC },
    loadChildren: () => import('./pages/create-item-ledger/create-item-ledger.module').then( m => m.CreateItemLedgerPageModule)
  },
  {
    path:EDIT_ITEM_LADGER_ID,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID], hasAccess: LEDGERS_MC },
    loadChildren: () => import('./pages/create-item-ledger/create-item-ledger.module').then( m => m.CreateItemLedgerPageModule)
  },
  {
    path: RECEIVED_PAYMENTS,
    loadChildren: () => import('./pages/received-payments/received-payments.module').then( m => m.ReceivedPaymentsPageModule),
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, BUSINESS_ROLE_ID, STAFFS_ROLL_ID], hasAccess: LEDGERS_MC },
  },
  {
    path: EDIT_RECEIVED_PAYMENTS,
    loadChildren: () => import('./pages/edit-received-payments/edit-received-payments.module').then( m => m.EditReceivedPaymentsPageModule),
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, BUSINESS_ROLE_ID, STAFFS_ROLL_ID], hasAccess: LEDGERS_MC },
  },
  {
    path: 'wallet',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path:WITHDRAW_MONEY,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/withdraw-money/withdraw-money.module').then( m => m.WithdrawMoneyPageModule)
  },
  {
    path: ADVERTISE_SETTINGS,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/advertise-settings/advertise-settings.module').then( m => m.AdvertiseSettingsPageModule)
  },
  {
    path: CREATE_ADVERTISE_SETTINGS,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/create-advertise-settings/create-advertise-settings.module').then( m => m.CreateAdvertiseSettingsPageModule)
  },
  {
    path: EDIT_ADVERTISE_SETTINGS_ID,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/create-advertise-settings/create-advertise-settings.module').then( m => m.CreateAdvertiseSettingsPageModule)
  },
  {
    path: PUSH_ADVERTISE_SETTINGS,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/push-adv-setting/push-adv-setting.module').then( m => m.PushAdvSettingPageModule)
  },
  {
    path: CREATE_ADV_SCHEDULE,
    loadChildren: () => import('./pages/create-adv-schedule/create-adv-schedule.module').then( m => m.CreateAdvSchedulePageModule)
  },
  {
    path: PROMO_CODES_RT,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/promocodes/promocodes.module').then( m => m.PromocodesPageModule)
  },
  {
    path:FREE_TRIAL_DISCOUNT_RT,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/free-trial/free-trial.module').then( m => m.FreeTrialPageModule)
  },
  {
    path: SELECT_METHOD_RT,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/select-trial/select-trial.module').then( m => m.SelectTrialPageModule)
  },
  {
    path: EMPLOYEES_ROUTE,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID,BUSINESS_ROLE_ID], hasAccess: EMPLOYEES_MC },
    loadChildren: () => import('./pages/employess/employess.module').then( m => m.EmployessPageModule)

  },
  {
    path: ADD_EDIT_EMPLOYEEE,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID,BUSINESS_ROLE_ID], hasAccess: EMPLOYEES_MC },
    loadChildren: () => import('./pages/create-employee/create-employee.module').then( m => m.CreateEmployeePageModule)
  },
  {
    path: EDIT_EMPLOYEE_DETAILS,
    canActivate: [AuthGuard],
    data: { roles: [SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID,BUSINESS_ROLE_ID,EMPLOYEE_ROLL_ID] },
    loadChildren: () => import('./pages/create-employee/create-employee.module').then( m => m.CreateEmployeePageModule)
  },
  {
    path: EMPLOYEE_TABS,
    loadChildren: () => import('./pages/employee-tabs/employee-tabs.module').then( m => m.EmployeeTabsPageModule)
  },
  {
    path: DISCOUNTS_VIEW_RT,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/discounts-view/discounts-view.module').then( m => m.DiscountsViewPageModule)
  },
  {
    path: COMMENTS_RT,
    loadChildren: () => import('./pages/review-comments/review-comments.module').then( m => m.ReviewCommentsPageModule)
  },
  
  // {
  //   path: PRICING_RT,
  //   loadChildren: () => import('./pages/pricing/pricing.module').then( m => m.PricingPageModule)
  // },
  // {
  //   path: "**",
  //   pathMatch: "full",
  //   redirectTo: NO_PAGE_FOUND,
  // },
  // {
  //   path: NO_PAGE_FOUND,
  //   loadChildren: () =>
  //     import("./pages/no-page-found/no-page-found.module").then(
  //       (m) => m.NoPageFoundPageModule
  //     ),
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private utilsService: UtilsService) { }
}
