export function LogData(lbl, msg?) {
  let flag = false;
  if (flag) {
    if (msg) {
      console.log(lbl + " ======> ", msg);
    } else {
      console.log(lbl);
    }
  }
}
export function LogDataApi(lbl, msg?) {
  let flag = false
  if (flag) {
    if (msg) {
      console.log(lbl + " ======> ", msg);
    } else {
      console.log(lbl);
    }
  }
}

/* 
<ion-row class="ion-margin-top">
<ion-col>
 <ion-label class="lbl-med-12">button:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="button">
   </ion-input>
 </ion-item>

 <ion-label class="lbl-med-12">checkbox:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="checkbox">
   </ion-input>
 </ion-item>


 <ion-label class="lbl-med-12">color:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="color">
   </ion-input>
 </ion-item>

 <ion-label class="lbl-med-12">date:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="date">
   </ion-input>
 </ion-item>

 <ion-label class="lbl-med-12">datetime-local:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="datetime-local">
   </ion-input>
 </ion-item>

 <ion-label class="lbl-med-12">email:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="email">
   </ion-input>
 </ion-item>

 <ion-label class="lbl-med-12">file:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="file">
   </ion-input>
 </ion-item>

 <ion-label class="lbl-med-12">hidden:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="hidden">
   </ion-input>
 </ion-item>

 <ion-label class="lbl-med-12">image:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="image">
   </ion-input>
 </ion-item>

 <ion-label class="lbl-med-12">month:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="month">
   </ion-input>
 </ion-item>

 <ion-label class="lbl-med-12">password:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="password">
   </ion-input>
 </ion-item>

 <ion-label class="lbl-med-12">radio:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="radio">
   </ion-input>
 </ion-item>

 <ion-label class="lbl-med-12">reset:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="reset">
   </ion-input>
 </ion-item>

 <ion-label class="lbl-med-12">Date:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="date">
   </ion-input>
 </ion-item>

 <ion-label class="lbl-med-12">search:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="search">
   </ion-input>
 </ion-item>

 <ion-label class="lbl-med-12">submit:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="submit">
   </ion-input>
 </ion-item>

 <ion-label class="lbl-med-12">tel:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="tel">
   </ion-input>
 </ion-item>

 <ion-label class="lbl-med-12">text:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="text">
   </ion-input>
 </ion-item>

 <ion-label class="lbl-med-12">time:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="time">
   </ion-input>
 </ion-item>

 <ion-label class="lbl-med-12">url:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="url">
   </ion-input>
 </ion-item>

 <ion-label class="lbl-med-12">week:</ion-label>
 <ion-item lines="none" class="mt-12 ion-item-bg"  >
   <ion-input  type="week">
   </ion-input>
 </ion-item>
  </ion-col>
</ion-row> */