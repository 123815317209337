import { ADVERTISEBANNERS_MC, BUSINESSES_MC, BUSINESSLISTS_MC, COMPLAINS_MC, CUSTOMERS_MC, DEALERS_MC, DISTRICTS_MC, EMAILTEMPLATES_MC, EMPLOYEES_MC, GROUPS_MC, INQUIRIES_MC, ITEMGROUPS_MC, LEDGERS_MC, PRODUCTCOMPANIES_MC, PRODUCTLISTS_MC, PUSHADVERTISEBANNERS_MC, ROLES_MC, SMSTEMPLATES_MC, STAFFS_MC, STATES_MC, SUBJECTS_MC, TALUKAS_MC, TRADINGTYPES_MC, TRANSACTIONS_MC, TRANSLATIONS_MC, VILLAGEAREAS_MC, VILLAGES_MC } from "./menu-code";
import {
  ACCOUNT_LADGER_REPORT,
  ADDRESS_MASTER,
  ADVERTISE_BANNER,
  ADVERTISE_PUSH_BANNER,
  BUSINESS_INFORMATION,
  BUSINESS_SUBSCRIPTION,
  BUSINESS_TYPE,
  CHANGE_PASSWORD,
  COMMENTS_RT,
  COMPLAINS_LIST,
  CONTACT_INFORMATION,
  EMPLOYEES_ROUTE,
  INQUIRE_LIST,
  INVITE_EARN_RT,
  PRIVACY_POLICY,
  REG_STEP1,
  SOCIAL_MEDIA_LINKS,
  TAB1_ROUTE,
  TAB2_ROUTE,
  TAB6_ROUTE,
  TERMS_CONDITION,
  TRADING_TYPE,
  UPLOAD_PICTURES,
} from "../../utils/routes";
import { BUSINESS_REG_SLUG, CUSTOMER_REG_SLUG } from "src/app/utils/constant";
import { Capacitor } from "@capacitor/core";

export const BusinessMenuData = [
    {
      id: 1000,
      title: "mn_visit_website",
      url: TAB1_ROUTE,
      icon: "ic_visit_web",
      isChecked: false,
  
    },
    {
      id: 1,
      title: "profile",
      url: TAB2_ROUTE,
      icon: "profile_sm",
      isChecked: false,
    },
    {
      id: 2,
      title: 'business_information',
      icon: 'ic_bus_info',
      url: BUSINESS_INFORMATION
    },
    {
      id: 3,
      title: 'mn_contact_information',
      icon: 'ic_pur_info',
      url: CONTACT_INFORMATION
    },
    {
      id: 4,
      title: 'address',
      icon: 'mn_address',
      url: ADDRESS_MASTER
    },
    {
      id: 4,
      title: 'change_password',
      icon: 'mn_cp',
      url: CHANGE_PASSWORD
    },
    {
      id: 44,
      title: 'mn_employees',
      icon: 'mn_emp',
      url: EMPLOYEES_ROUTE,
      menuCode: EMPLOYEES_MC
    },
    // {
    //   id: 5,
    //   title: "mn_kyc_bank_details",
    //   url: "",
    //   icon: "mn_kyc_bank",
    //   isChecked: false,
    //   subMenu: [
    //     {
    //       id: 51,
    //       title: "mn_kyc",
    //       // url: KYC_DETAILS_RT,
    //       url:'',
    //       icon: "mn_kyc",
    //       isChecked: false,
    //     },
    //     {
    //       id: 52,
    //       title: "mn_bank_details",
    //       // url: BANK_DETAILS_RT,
    //       url:'',
    //       icon: "mn_kyc_bank",
    //       isChecked: false,
    //     },
    //   ],
    // },
    // {
    //   id: 53,
    //   title: 'mn_wallet',
    //   icon: 'mn_wallet',
    //   //url: WALLET_RT,
    //  url:"",
    //   isChecked: false,
    // },
    {
      id: 80,
      title: "accounts",
      url: "",
      icon: "acc_sm",
      isExpand: false,
      isChecked: false,
      subMenu: [
        {
          id: 81,
          title: "report",
          url: "",
          icon: "report_sm",
          // menuCode: TRANSACTIONS_MC,
          innerSubMenu: [
            { id: 831, title: "account_ledger", url: ACCOUNT_LADGER_REPORT},
            // { id: 842, title: "item_ledger", url: ITEM_LADGER_REPORT },
          ],
        },
      ],
    },
    {
        id: 99,
        title: "inquiries",
        url: INQUIRE_LIST,
        icon: "inq_sm",
        isChecked: false,
        menuCode: INQUIRIES_MC
      },
    // {
    //   id: 6,
    //   title: "advertise",
    //   url: "",
    //   icon: "adv_sm",
    //   isChecked: false,
    //   subMenu: [
    //     {
    //       id: 61,
    //       title: "advertise_banners",
    //       url: ADVERTISE_BANNER,
    //       icon: "adv_banner_sm",
    //       isChecked: false,
    //       menuCode: ADVERTISEBANNERS_MC
    //     },
    //     {
    //       id: 62,
    //       title: "push_notification",
    //       url: ADVERTISE_PUSH_BANNER,
    //       icon: "push_add_sm",
    //       isChecked: false,
    //       menuCode: PUSHADVERTISEBANNERS_MC
    //     },
    //   ],
    // },
    {
      id: 7,
      title: 'trading_type',
      icon: 'mn_trad',
      url: TRADING_TYPE
    },
    {
      id: 8,
      title: 'gallery',
      icon: 'mn_gal',
      url: UPLOAD_PICTURES
    },
    {
      id: 9,
      title: 'social_media',
      icon: 'mn_social',
      url: SOCIAL_MEDIA_LINKS
    },
    {
      id: 10,
      title: 'mn_business_reg',
      icon: 'mn_bus_reg',
      // url: BUSINESS_TYPE,
      url: Capacitor.getPlatform() !== 'ios' ? BUSINESS_TYPE:'',
      isBrowser:true,
      param:{
        type:BUSINESS_REG_SLUG
      }
    },
    {
      id: 11,
      title: 'mn_customer_reg',
      icon: 'mn_cst_reg',
      url: REG_STEP1,
      param:{
        type:CUSTOMER_REG_SLUG
      }
    },
    {
      id: 111,
      title: "pg_tl_comments",
      url: COMMENTS_RT,
      // url: "",
      icon: "com_sm",
      isExpand: false,
      isChecked: false,
      isCounter:true
    },
    {
      id: 12,
      title: 'mn_invite_earn',
      icon: 'mn_invite',
      url: INVITE_EARN_RT
      // url: '',
    },
    // {
    //   id: 121,
    //   title: 'mn_wallet',
    //   icon: 'mn_wallet',
    //   // url: WALLET_RT
    //   url: '',
    // },
    {
      id: 13,
      title: 'mn_billing_history',
      icon: 'mn_subs',
      url: Capacitor.getPlatform() !== 'ios' ? BUSINESS_SUBSCRIPTION:'',
      isBrowser:true
      // url: RECEIVED_PAYMENTS
    },
    // {
    //   id: 14,
    //   title: "notifications",
    //   // url: TAB4_ROUTE,
    //   url: '',
    //   icon: "notification_sm",
    //   isChecked: false,
    // },
    {
      id: 15,
      title: "mn_contactus",
      url: TAB6_ROUTE,
      icon: "mn_con_us",
      isChecked: false,
    },
    // {
    //   id: 16,
    //   title: "chats",
    //   // url: TAB5_ROUTE,
    //   url: '',
    //   icon: "mn_chat",
    //   isChecked: false,
    // },
    {
      id: 17,
      title: "mn_tickets",
      url: COMPLAINS_LIST,
      icon: "loadge_sm",
      isChecked: false,
      menuCode: COMPLAINS_MC
    },
    {
      id: 18,
      title: "privacy",
      url: PRIVACY_POLICY,
      icon: "privacy_sm",
      isChecked: false,
    },
    {
      id: 19,
      title: "terms_of_use",
      url: TERMS_CONDITION,
      icon: "term_sm",
      isChecked: false,
    },
    // {
    //   id: 20,
    //   title: "mn_pricing",
    //   url: TABS_PRICING,
    //   icon: "mn_pricing",
    //   isExpand: false,
    //   isChecked: false,
    // },
  ];
  