import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoadingController, ModalController, PopoverController } from "@ionic/angular";
import { finalize } from "rxjs/operators";
import { AuthServices } from "./auth.services";
import { Router } from "@angular/router";
import {
  ACCOUNT_LADGER,
  BANK_TRANSACTIONS,
  EDIT_STATE,
  FORGOT_MEMBER_ID,
  JOURNAL_TRANSACTIONS,
  MAIN_HOME,
  PAYMENT_TRANSACTIONS,
  RECEIPT_TRANSACTIONS,
  VISITORS_TAB1_ROUTE,
} from "../utils/routes";
import {
  ADD_BUSINESSLISTS_API,
  ADD_PRODUCTLISTS_API,
  ADD_TRADINGTYPES_API,
  GET_MASTERS_API,
  CHANGEPASSWORD_API,
  EDIT_BUSINESSLISTS_API,
  EDIT_PRODUCTLISTS_API,
  EDIT_TRADINGTYPES_API,
  FORGOTMEMBERID_API,
  FORGOTPASSWORDOTPVERIFY_API,
  FORGOTPASSWORD_API,
  GETPO_API,
  GET_BUSINESSLISTS_API,
  GET_BUSINESSLISTS_INDEX_API,
  GET_BUSINESSTYPES_API,
  GET_DISTRICT_BY_ID_API,
  GET_LANGUAGES_API,
  GET_PRODUCTLISTS_INDEX_API,
  GET_STATE_API,
  GET_STATUS_API,
  GET_TALUKA_BY_ID_API,
  GET_TRADINGTYPES_API,
  GET_TRADINGTYPES_INDEX_API,
  GET_VILLAGES_AREA_BY_ID_API,
  GET_VILLAGES_BY_ID_API,
  LOGIN_API,
  LOGOUT_API,
  REFERRALCODES_CHECK_API,
  RESETPASSWORD_API,
  UPDATE_STATUS_BUSINESS_LIST_API,
  USER_EXIT_API,
  USER_IMAGE_UPLOAD_API,
  USER_PROFILE_API,
  USER_REGISTRATION_API,
  USER_SEND_OTP_API,
  UPDATE_STATUS_TRADING_LIST_API,
  UPDATE_STATUS_PRODUCTLISTS_API,
  GET_PRODUCTCOMPANIES_INDEX_API,
  ADD_PRODUCTCOMPANIES_API,
  EDIT_PRODUCTCOMPANIES_API,
  UPDATE_STATUS_PRODUCTCOMPANIES_API,
  GET_STATE_INDEX_API,
  GET_DISTRICTS_INDEX_API,
  GET_TALUKAS_INDEX_API,
  GET_VILLAGES_INDEX_API,
  GET_VILLAGEAREAS_INDEX_API,
  STATES_EDIT_API,
  STATES_ADD_API,
  UPDATE_STATUS_STATES_LIST_API,
  DISTRICT_EDIT_API,
  TALUKAS_EDIT_API,
  VILLAGES_EDIT_API,
  VILLAGEAREAS_EDIT_API,
  GET_GROUPS_INDEX_API,
  GET_ITEM_GROUPS_INDEX_API,
  GET_SUBJECTS_INDEX_API,
  SUBJECTS_EDIT_API,
  SUBJECTS_ADD_API,
  GET_STAFF_INDEX_API,
  USERPROFILES_ADD_API,
  GET_USERPROFILES_INDEX_API,
  TRANSLATIONS_INDEX_API,
  TRANSLATIONS_UPDATE_API,
  UPDATE_STATUS_DISTRICTS_LIST_API,
  UPDATE_STATUS_TALUKA_LIST_API,
  UPDATE_STATUS_VILLAGES_LIST_API,
  UPDATE_STATUS_VILLAGES_AREA_LIST_API,
  UPDATE_STATUS_SUBJECTS_LIST_API,
  UPDATE_STATUS_USERPROFILES_LIST_API,
  GET_HOME_API,
  GET_BUSINESSDIRECTORY_API,
  EDIT_USERREGISTERAREAS_API,
  GET_USERREGISTERAREAS_INDEX_API,
  UPDATE_STATUS_USERREGISTERAREAS_API,
  GET_BUSINESSVIEW_API,
  RATING_API,
  GET_ROLES_INDEX_API,
  EDIT_ROLE_API,
  ADD_ROLE_API,
  GET_ADVERTISEMENTS_INDEX_API,
  ADD_ADVERTISEMENTS_API,
  EDIT_ADVERTISEMENTS_API,
  GETALLOWPERMISSION_API,
  DELETE_BUSINESS_LIST_API,
  DELETE_TRADING_LIST_API,
  DELETE_PRODUCTLISTS_API,
  DELETE_PRODUCTCOMPANIES_API,
  DELETE_STATES_LIST_API,
  DELETE_DISTRICTS_LIST_API,
  DELETE_TALUKA_LIST_API,
  DELETE_VILLAGES_LIST_API,
  DELETE_VILLAGES_AREA_LIST_API,
  DELETE_SUBJECTS_LIST_API,
  DELETE_USERPROFILES_LIST_API,
  DELETE_USERREGISTERAREAS_API,
  ADD_USERREGISTERAREAS_API,
  HOMECONTACT_API,
  ADD_GROUPS_API,
  UPDATE_STATUS_GROUPS_API,
  DELETE_GROUPS_API,
  GET_LEDGERS_INDEX_API,
  UPDATE_STATUS_LEDGERS_API,
  DELETE_LEDGERS_API,
  GET_BANK_TRANS_INDEX_API,
  GETAPPLINK_API,
  GET_TRANSACTIONS_LEDGER_API,
  TRANS_APPROVEPAYMENT_API,
  UPDATE_STATUS_ITEM_GROUPS_API,
  DELETE_ITEM_GROUPS_API,
  GET_ITEM_LEDGERS_INDEX_API,
  UPDATE_STATUS_ITEM_LEDGERS_API,
  DELETE_ITEM_LEDGERS_API,
  ACCOUNTS_ADD_API,
  USER_PLAN_DETAILS,
  GET_ACCOUNTS_INDEX_API,
  ACCOUNTS_EDIT_API,
  ACCOUNTS_CREATEORDER_API,
  GET_PRIVACY_API,
  GET_TERMS_API,
  EDIT_REFERALCODE,
  GET_APP_VERSION,
  GET_REGISTRATIONPLANS,
  GET_ADVSETS_INDEX_API,
  UPDATE_STATUS_ADVSETS_LIST_API,
  DELETE_ADVSETS_LIST_API,
  UPDATE_STATUS_COMPLAINS_API,
  UPDATE_STATUS_INQUIRIES_API,
  EDIT_COMPLAINS_API,
  EDIT_INQUIRIES_API,
  ADD_COMPLAINS_API,
  ADD_INQUIRIES_API,
  GET_COMPLAINS_INDEX_API,
  GET_INQUIRIES_INDEX_API,
  DELETE_COMPLAINS_API,
  DELETE_INQUIRIES_API,
  CLOSE_PROFILE_API,
  USER_OTPSENDEDIT_API,
  ADD_EMPLOYEES_API,
  GET_EMPLOYEES_INDEX_API,
  UPDATE_STATUS_EMPLOYEES_API,
  DELETE_EMPLOYEES_API,
  USER_PROFILE_UPDATE_API,
  ACCOUNTS_APPLY_FREETRIAL_API,
  USERPROFILES_CONVERTPROFILE_API,
  ACCOUNTS_UPDATEENDDATE_API,
  GETSTATISTICS_API,
  GETREVIEWS_API,
  GET_BUSINESSREVIEWS_API,
  UPDATE_STATUS_COMMENTS_LIST_API,
  DELETE_COMMENTS_LIST_API,
  // GET_COMPLAINS_INDEX_API,
  // GET_INQUIRIES_LIST_API,
} from "../utils/api-end-points";
import { environment } from "src/environments/environment";
import { ResponseBean } from "../models/responsebean";
import { MasterData } from "../models/master-data";
import { CurrentUserData, UserData } from "../models/user-data";
import { LogData } from "../utils/utils";
import { ForgotDataResponse } from "../models/forgot-data";
import * as moment from "moment";
import {
  GetDataSection,
  UserprofileData,
  UserProfileList,
  UserProfileResponce,
  UserregisterareasData,
  UserregisterareasListData,
} from "../models/user-profile-data";
import { UniqueResponce } from "../models/unique-data";
import {
  ACTIVE_STATUS_KEY,
  BUSINESSTYPE_ID_KEY,
  CURRENTSTATUS_KEY,
  DELETE_KEY,
  EMAIL_KEY,
  INACTIVE_STATUS_KEY,
  NEW_USER_TYPE_KEY,
  PHONE_KEY,
  RECORDID_KEY,
  REQUESTARR_ID_KEY,
  STATUS_KEY,
  TRADING_TYPE_KEY,
  USERPROFILE_ID_KEY,
} from "../utils/params-data";
import { CommonData } from "../models/common-data";
import { BusinessData, BusinessListData, ProductCompaniesData, ProductListData, TradingListData } from "../models/business-data";
import { ConfirmationComponent } from "../components/confirmation/confirmation.component";
import {
  ADD_DATA,
  BUSINESS_NAME_LST_KEY,
  BUSINESS_STATUS_LST_KEY,
  DELETE_DATA,
  EDIT_DATA,
  PAGE_ACC_GROUP,
  PAGE_ACC_LEDGER,
  PAGE_ADV_SETTING,
  PAGE_ALLOC_AREA,
  PAGE_BANK_TRANS,
  PAGE_COMPLAINS,
  PAGE_COMPL_SUB,
  PAGE_DISTRCIT,
  PAGE_EMPLOYEE,
  PAGE_INQ,
  PAGE_ITEM_GROUP,
  PAGE_ITEM_LADGER,
  PAGE_JOURNAL_TRANS,
  PAGE_PAYMENT_TRANS,
  PAGE_RECEIPT_TRANS,
  PAGE_REVIEW_COMMENTS,
  PAGE_STAFF,
  PAGE_STATE,
  PAGE_TALUKA,
  PAGE_VILLAGE,
  PAGE_VILLAGE_AREA,
  PER_PAGE,
  WITHOUT_PAGE,
} from "../utils/constant";
import {
  AddressDetails,
  DistrictsData,
  StatesData,
  TalukasData,
  VillageareasData,
  VillagesData,
} from "../models/address-details";
import { PoData } from "../models/po-data";
import { BDData } from "../models/branch-dealer-data";
import { GroupResponse, GroupsData, ItemgroupsData } from "../models/group-data";
import {
  TranslationResponce,
  TranslationResponceId,
} from "../models/translation-data";
import { ComplaininquiriesResponse, ComplaininquiryListResponse, SubjectListData, SubjectsData } from "../models/complaints";
import { BusinessDirectoryDetails, BusinessDirectoryDetailsResponce, RatingResponce } from "../models/business-director-data";
import { RoleData, RoleResponce } from "../models/roles-data";
import { AdvDetailResponse, AdvertisementsResponse } from "../models/advertisements-data";
import { GetAllowPermissionResponce } from "../models/get-allow-permission";
import { LadgerData, LadgerResponce } from "../models/ladger-data";
import { TransactionResponce, TransactionResponceSingle } from "../models/transaction-data";
import { HomeDataResponce } from "../models/home-data";
import { LedgerReportResponce, TransactionPaymentResponse } from "../models/ledger-report-data";
import { UtilsService } from "./uitils.services";
import { PlanResponce } from "../models/plan-details";
import { AccountsData, AccountsResponce } from "../models/accounts-data";
import { UserDataResult } from "../models/user-data-result";
import { PrivacyTermsResponce } from "../models/privacy-terms-data";
import { ReferalCodeResponce } from "../models/referalcode-data";
import { VersionData } from "../models/appversion";
import { RegisterPlanResponce } from "../models/registerplans";
import { AdvsetsData, AdvsetsListResponse, AdvSettingsResponce } from "../models/adv-settings-data";
import { VarifyAccountComponent } from "../components/varify-account/varify-account.component";
import { AnyCnameRecord } from "dns";
import { StatisticsResponce } from "../models/report-statistics";
import { SocialResponseObj } from "../models/social-obj";
import { ReviewResponce } from "../models/comments-data";

@Injectable({
  providedIn: "root",
})
export class UserServices {
  // TOKEN_STR = "?token=";
  TOKEN_STR = "?token=";
  SLASE = "/";

  constructor(
    private http: HttpClient,
    private authservices: AuthServices,
    private utilServices: UtilsService,
    private router: Router,
    private lodingCtrl: LoadingController,
    private popCtrl: PopoverController,
    private modalCtrl: ModalController
  ) { }

  getPoFile(language) {
    return this.http.post<ResponseBean<PoData>>(
      `${environment.apiUrl}` + `${GETPO_API}` + language,
      {}
    );
  }

  getPrivacyData() {
    return this.http.get<ResponseBean<PrivacyTermsResponce>>(
      `${environment.apiUrl}` + `${GET_PRIVACY_API}`,
      {}
    );
  }

  getTermsData() {
    return this.http.get<ResponseBean<PrivacyTermsResponce>>(
      `${environment.apiUrl}` + `${GET_TERMS_API}`,
      {}
    );
  }

  getMasters(data, module?) {
    return this.http.post<ResponseBean<MasterData>>(
      `${environment.apiUrl}` + `${GET_MASTERS_API}` +
      this.SLASE +
      this.getTokenPaginationStr(this.authservices.getUserToken()) + this.getModule(module, this.authservices.getUserToken()),
      data
    );
  }

  downloadFile(data) {
    return this.http.get(data, { responseType: "blob" });
  }

  getServerAppVersion() {
    return this.http.get<ResponseBean<VersionData>>(`${environment.apiUrl}` + `${GET_APP_VERSION}` + this.getTokenPaginationStr(this.authservices.getUserToken()));

  }


  // getPoFile(language) {
  //     return this.http.post<ResponseBean<any>>(`${environment.apiUrl}` + `${GETPO_API}`,{});
  // }

  getBusinessDirectoryList(postData, pageNo) {
    return this.http.post<ResponseBean<UserProfileList>>(
      `${environment.apiUrl}` + `${GET_BUSINESSDIRECTORY_API}` +
      this.SLASE +
      this.getTokenPaginationStr((this.authservices.getUserToken() ? this.authservices.getUserToken() : null), pageNo, PER_PAGE), postData
    );
  }

  getBusinessView(id, token) {
    return this.http.get<ResponseBean<BusinessDirectoryDetailsResponce>>(
      `${environment.apiUrl}` + `${GET_BUSINESSVIEW_API}` + this.SLASE + id + (token ? this.SLASE +
        this.getTokenPaginationStr(token) : '')
    );
  }

  getHomeData(apimode, token) {
    return this.http.get<ResponseBean<HomeDataResponce>>(
      `${environment.apiUrl}` + `${GET_HOME_API}` + this.SLASE + this.getTokenPaginationStr(token) + this.getApiMode(apimode, token)
    );
  }


  getLanguage() {
    return this.http.get<ResponseBean<MasterData>>(
      `${environment.apiUrl}` + `${GET_LANGUAGES_API}`
    );
  }

  login(user: any) {
    return this.http.post<UserDataResult>(
      `${environment.apiUrl}` + `${LOGIN_API}`,
      user
    );
  }
  logout(token?) {
    return this.http.get<any>(`${environment.apiUrl}` + `${LOGOUT_API}`, {});
  }
  getState(data) {
    return this.http.post<ResponseBean<MasterData>>(
      `${environment.apiUrl}` + `${GET_STATE_API}`,
      data
    );
  }

  getDistrictById(id, data, module?) {
    return this.http.post<ResponseBean<MasterData>>(
      `${environment.apiUrl}` + `${GET_DISTRICT_BY_ID_API}` + this.SLASE + id +
      this.SLASE +
      this.getTokenPaginationStr(this.authservices.getUserToken()) + this.getModule(module, this.authservices.getUserToken()),
      data
    );
  }

  getTalukaById(id, data, module?) {
    return this.http.post<ResponseBean<MasterData>>(
      `${environment.apiUrl}` + `${GET_TALUKA_BY_ID_API}` + this.SLASE + id +
      this.SLASE +
      this.getTokenPaginationStr(this.authservices.getUserToken()) + this.getModule(module, this.authservices.getUserToken()),
      data
    );
  }

  getVillagesById(id, data, module?) {
    return this.http.post<ResponseBean<MasterData>>(
      `${environment.apiUrl}` + `${GET_VILLAGES_BY_ID_API}` + this.SLASE + id +
      this.SLASE +
      this.getTokenPaginationStr(this.authservices.getUserToken()) + this.getModule(module, this.authservices.getUserToken()),
      data
    );
  }

  getVillageAreaById(id, data, module?) {
    return this.http.post<ResponseBean<MasterData>>(
      `${environment.apiUrl}` +
      `${GET_VILLAGES_AREA_BY_ID_API}` +
      this.SLASE +
      id + this.SLASE +
      this.getTokenPaginationStr(this.authservices.getUserToken()) + this.getModule(module, this.authservices.getUserToken()),
      data
    );
  }

  getBusinessType() {
    return this.http.get<ResponseBean<MasterData>>(
      `${environment.apiUrl}` + `${GET_BUSINESSLISTS_API}`,
      {}
    );
  }

  sendOTP(postData) {
    return this.http.post<any>(
      `${environment.apiUrl}` + `${USER_SEND_OTP_API}`,
      postData
    );
  }

  sendOTPForEdit(postData, token) {
    return this.http.post<any>(
      `${environment.apiUrl}` + `${USER_OTPSENDEDIT_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  //This api nused for varify otp
  userRegistration(postData) {
    // UserData in place of UserDataResult
    return this.http.post<UserDataResult>(
      `${environment.apiUrl}` + `${USER_REGISTRATION_API}`,
      postData
    );
  }

  userAdd(postData, token) {
    return this.http.post<UserData>(
      `${environment.apiUrl}` +
      `${USERPROFILES_ADD_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  employeeAddEditData(id, postData, token, api) {
    return this.http.post<ResponseBean<AdvSettingsResponce>>(
      `${environment.apiUrl}` +
      `${api}` + (id ? this.SLASE +
        id : '') +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }


  forgotPassword(postData) {
    return this.http.post<ResponseBean<ForgotDataResponse>>(
      `${environment.apiUrl}` + `${FORGOTPASSWORD_API}`,
      postData
    );
  }

  forgotPasswordOTPVarify(postData) {
    return this.http.post<any>(
      `${environment.apiUrl}` + `${FORGOTPASSWORDOTPVERIFY_API}`,
      postData
    );
  }

  resetPassword(postData) {
    return this.http.post<any>(
      `${environment.apiUrl}` + `${RESETPASSWORD_API}`,
      postData
    );
  }

  forgotMemberId(postData) {
    return this.http.post<any>(
      `${environment.apiUrl}` + `${FORGOTMEMBERID_API}`,
      postData
    );
  }

  getUserProfile(id, token, section?) {
    return this.http.get<ResponseBean<UserProfileResponce>>(
      `${environment.apiUrl}` +
      `${USER_PROFILE_API}` +
      this.SLASE +
      id +
      this.getTokenPaginationStr(token) + this.getDataSection(section, token)
    );
  }

  userDelete(data, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${CLOSE_PROFILE_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      data
    );
  }

  updateUserProfile(id, postData, token) {
    return this.http.post<any>(
      `${environment.apiUrl}` +
      `${USER_PROFILE_API}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  checkUserExit(postData, token) {
    return this.http.post<ResponseBean<UniqueResponce>>(
      `${environment.apiUrl}` +
      `${USER_EXIT_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  applyReferalCode(postData, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${REFERRALCODES_CHECK_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  changePassword(postData, token) {
    return this.http.post<any>(
      `${environment.apiUrl}` +
      `${CHANGEPASSWORD_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  userImageUpload(id, postData, token) {
    return this.http.post<any>(
      `${environment.apiUrl}` +
      `${USER_IMAGE_UPLOAD_API}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  getBusinessTypesTrading(token) {
    return this.http.post<ResponseBean<MasterData>>(
      `${environment.apiUrl}` +
      `${GET_BUSINESSTYPES_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      {}
    );
  }

  getMaster(apiUrl, postData, token) {
    return this.http.post<ResponseBean<MasterData>>(
      `${environment.apiUrl}` +
      `${apiUrl}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  getBusinessListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<BusinessData>>(
      `${environment.apiUrl}` +
      `${GET_BUSINESSLISTS_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  addBusinessList(postData, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${ADD_BUSINESSLISTS_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  editBusinessList(postData, id, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${EDIT_BUSINESSLISTS_API}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  getBusinessData(id, token) {
    return this.http.get<ResponseBean<BusinessListData>>(
      `${environment.apiUrl}` +
      `${EDIT_BUSINESSLISTS_API}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token)
    );
  }

  getBusinessDataAddEdit(id, token, api, section) {
    return this.http.get<ResponseBean<BusinessListData>>(
      `${environment.apiUrl}` +
      `${api}` + (id ? this.SLASE +
        id : '') +
      this.SLASE +
      this.getTokenPaginationStr(token) + this.getDataSection(section, token)
    );
  }


  updateStatusForBusinessList(postData, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${UPDATE_STATUS_BUSINESS_LIST_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  updateStatusForListData(postData, apiEndpoint, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${apiEndpoint}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  getProductListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<BusinessData>>(
      `${environment.apiUrl}` +
      `${GET_PRODUCTLISTS_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  addProductList(postData, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${ADD_PRODUCTLISTS_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  editProductList(postData, id, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${EDIT_PRODUCTLISTS_API}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  getProductListData(id, token, section) {
    return this.http.get<ResponseBean<ProductListData>>(
      `${environment.apiUrl}` +
      `${EDIT_PRODUCTLISTS_API}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token) + this.getDataSection(section, token)
    );
  }

  getTradingListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<BusinessData>>(
      `${environment.apiUrl}` +
      `${GET_TRADINGTYPES_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  addTradingList(postData, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${ADD_TRADINGTYPES_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  editTradingList(postData, id, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${EDIT_TRADINGTYPES_API}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  getTradingListData(id, token, section) {
    return this.http.get<ResponseBean<TradingListData>>(
      `${environment.apiUrl}` +
      `${EDIT_TRADINGTYPES_API}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token) + this.getDataSection(section, token)
    );
  }

  getStatus(token) {
    return this.http.post<ResponseBean<MasterData>>(
      `${environment.apiUrl}` +
      `${GET_STATUS_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      {}
    );
  }
  getStateListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<StatesData>>(
      `${environment.apiUrl}` +
      `${GET_STATE_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  getDisctrictListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<DistrictsData>>(
      `${environment.apiUrl}` +
      `${GET_DISTRICTS_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  getTalukaListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<TalukasData>>(
      `${environment.apiUrl}` +
      `${GET_TALUKAS_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  getVillagesListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<VillagesData>>(
      `${environment.apiUrl}` +
      `${GET_VILLAGES_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  getVillagesAreaListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<VillageareasData>>(
      `${environment.apiUrl}` +
      `${GET_VILLAGEAREAS_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  getComplaintsListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<ComplaininquiryListResponse>>(
      `${environment.apiUrl}` +
      `${GET_COMPLAINS_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  // getInq(id, token) {
  //   return this.http.get<ResponseBean<ComplaininquiriesResponse>>(
  //     `${environment.apiUrl}` +
  //     `${COMPLAININQUIRIES_EDIT_API}` +
  //     this.SLASE + id + this.SLASE +
  //     this.getTokenPaginationStr(token)
  //   );
  // }

  getInq(id, token, api, section) {
    return this.http.get<ResponseBean<ComplaininquiriesResponse>>(
      `${environment.apiUrl}` +
      `${api}` + (id ? this.SLASE +
        id : '') +
      this.SLASE +
      this.getTokenPaginationStr(token) + this.getDataSection(section, token)
    );
  }

  getComplains(id, token, api, section) {
    return this.http.get<ResponseBean<ComplaininquiriesResponse>>(
      `${environment.apiUrl}` +
      `${api}` + (id ? this.SLASE +
        id : '') +
      this.SLASE +
      this.getTokenPaginationStr(token) + this.getDataSection(section, token)
    );
  }

  getInquiryListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<ComplaininquiryListResponse>>(
      `${environment.apiUrl}` +
      `${GET_INQUIRIES_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  addProductCompney(postData, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${ADD_PRODUCTCOMPANIES_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  editProductCompney(postData, id, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${EDIT_PRODUCTCOMPANIES_API}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }
  getProductCompney(id, token) {
    return this.http.get<ResponseBean<ProductCompaniesData>>(
      `${environment.apiUrl}` +
      `${EDIT_PRODUCTCOMPANIES_API}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token)
    );
  }
  // Business crud

  addBusiness(postData, token, apiEndPoint) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${apiEndPoint}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  editBusiness(postData, id, token, apiEndPoint) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${apiEndPoint}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  createInq(postData, token, isBusiness) {
    // `${COMPLAININQUIRIES_ADD_API}` + is replace and both same 
    if (isBusiness) {
      return this.http.post<ResponseBean<any>>(
        `${environment.apiUrl}` +
        `${HOMECONTACT_API}` +
        this.SLASE +
        this.getTokenPaginationStr(token),
        postData
      );
    } else {
      return this.http.post<ResponseBean<any>>(
        `${environment.apiUrl}` +
        `${HOMECONTACT_API}` + this.SLASE +
        this.getTokenPaginationStr(token),
        postData
      );
    }

  }

  getProductCompneyListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<BusinessData>>(
      `${environment.apiUrl}` +
      `${GET_PRODUCTCOMPANIES_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }
  getStateData(id, token) {
    return this.http.get<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${STATES_EDIT_API}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token),
      {}
    );
  }

  getDistrictData(id, token) {
    return this.http.get<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${DISTRICT_EDIT_API}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token),
      {}
    );
  }

  getTalukaData(id, token) {
    return this.http.get<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${TALUKAS_EDIT_API}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token),
      {}
    );
  }

  getVillagesData(id, token) {
    return this.http.get<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${VILLAGES_EDIT_API}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token),
      {}
    );
  }

  getVillagesAreaData(id, token) {
    return this.http.get<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${VILLAGEAREAS_EDIT_API}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token),
      {}
    );
  }

  getUserListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<BDData>>(
      `${environment.apiUrl}` +
      `${GET_USERPROFILES_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  getEmpListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<BDData>>(
      `${environment.apiUrl}` +
      `${GET_EMPLOYEES_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  getUerRegitrationAreaListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<UserregisterareasListData>>(
      `${environment.apiUrl}` +
      `${GET_USERREGISTERAREAS_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  getAllocatedArea(id, token) {
    return this.http.get<ResponseBean<UserregisterareasData>>(
      `${environment.apiUrl}` +
      `${EDIT_USERREGISTERAREAS_API}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token),
      {}
    );
  }

  getGroupListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<GroupsData>>(
      `${environment.apiUrl}` +
      `${GET_GROUPS_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  getGroupData(id, token, api, section) {
    return this.http.get<ResponseBean<GroupResponse>>(
      `${environment.apiUrl}` +
      `${api}` + (id ? this.SLASE +
        id : '') +
      this.SLASE +
      this.getTokenPaginationStr(token) + this.getDataSection(section, token)
    );
  }

  getAddEditData(id, token, api, section) {
    return this.http.get<ResponseBean<TransactionResponceSingle>>(
      `${environment.apiUrl}` +
      `${api}` + (id ? this.SLASE +
        id : '') +
      this.SLASE +
      this.getTokenPaginationStr(token) + this.getDataSection(section, token)
    );
  }

  // getAddEditData(id, token, api, section) {
  //   return this.http.get<ResponseBean<TransactionResponce>>(
  //     `${environment.apiUrl}` +
  //     `${api}` + (id ? this.SLASE +
  //       id : '') +
  //     this.SLASE +
  //     this.getTokenPaginationStr(token)
  //   );
  // }

  getAccountLedgerListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<LadgerResponce>>(
      `${environment.apiUrl}` +
      `${GET_LEDGERS_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  getItemLedgerListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<LadgerResponce>>(
      `${environment.apiUrl}` +
      `${GET_ITEM_LEDGERS_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  getLedgerData(id, token, api, section) {
    return this.http.get<ResponseBean<LadgerData>>(
      `${environment.apiUrl}` +
      `${api}` + (id ? this.SLASE +
        id : '') +
      this.SLASE +
      this.getTokenPaginationStr(token) + this.getDataSection(section, token)
    );
  }

  getItemGroupListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<ItemgroupsData>>(
      `${environment.apiUrl}` +
      `${GET_ITEM_GROUPS_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  getComplaintSubjectsListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<SubjectListData>>(
      `${environment.apiUrl}` +
      `${GET_SUBJECTS_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  getStaffListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${GET_STAFF_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  getAdvertiseListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<AdvertisementsResponse>>(
      `${environment.apiUrl}` +
      `${GET_ADVERTISEMENTS_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  getMasterAddUserRegisterarea(token, section) {
    return this.http.get<ResponseBean<MasterData>>(
      `${environment.apiUrl}` +
      `${ADD_USERREGISTERAREAS_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token) + this.getDataSection(section, token),
    );
  }


  addAdvertiseData(postData, token) {
    return this.http.post<ResponseBean<AdvDetailResponse>>(
      `${environment.apiUrl}` +
      `${ADD_ADVERTISEMENTS_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  editAdvertiseData(id, postData, token) {
    return this.http.post<ResponseBean<AdvDetailResponse>>(
      `${environment.apiUrl}` +
      `${EDIT_ADVERTISEMENTS_API}` +
      this.SLASE + id + this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  getAdvertiseData(id, token) {
    return this.http.get<ResponseBean<AdvDetailResponse>>(
      `${environment.apiUrl}` +
      `${EDIT_ADVERTISEMENTS_API}` +
      this.SLASE + id + this.SLASE +
      this.getTokenPaginationStr(token)
    );
  }

  getTransactionListIndex(postData, api, token, pageNo) {
    return this.http.post<ResponseBean<TransactionResponce>>(
      `${environment.apiUrl}` +
      `${api}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  getReceivedPaymentsListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<AccountsResponce>>(
      `${environment.apiUrl}` +
      `${GET_ACCOUNTS_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  editReceivedPayments(id, postData, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${ACCOUNTS_EDIT_API}` +
      this.SLASE + id + this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  getReceivedPaymentsData(id, token, section) {
    return this.http.get<ResponseBean<AccountsData>>(
      `${environment.apiUrl}` +
      `${ACCOUNTS_EDIT_API}` +
      this.SLASE + id + this.SLASE +
      this.getTokenPaginationStr(token) + this.getDataSection(section, token)
    );
  }

  // createOrder(id, token) {
  //   return this.http.get<ResponseBean<AccountsData>>(
  //     `${environment.apiUrl}` +
  //     `${ACCOUNTS_CREATEORDER_API}`  +
  //     this.SLASE +id+this.SLASE +
  //     this.getTokenPaginationStr(token)
  //   );
  // }

  createOrder(postData, token) {
    return this.http.post<ResponseBean<AccountsData>>(
      `${environment.apiUrl}` +
      `${ACCOUNTS_CREATEORDER_API}` + this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }


  addressMasterCrud(crudType, postData, id, token, apiEndpoint, page) {
    switch (crudType) {
      case ADD_DATA:
        return this.http.post<ResponseBean<any>>(
          `${environment.apiUrl}` +
          `${apiEndpoint}` +
          this.SLASE +
          this.getTokenPaginationStr(token),
          postData
        );
        break;
      case EDIT_DATA:
        return this.http.post<ResponseBean<any>>(
          `${environment.apiUrl}` +
          `${apiEndpoint}` +
          this.SLASE +
          id +
          this.SLASE +
          this.getTokenPaginationStr(token),
          postData
        );
        break;
      case DELETE_DATA:
        return this.http.post<ResponseBean<any>>(
          `${environment.apiUrl}` +
          `${apiEndpoint}` +
          this.SLASE +
          this.getTokenPaginationStr(token),
          postData
        );
        break;
    }
  }

  transationCrud(crudType, postData, id, token, apiEndpoint) {
    switch (crudType) {
      case ADD_DATA:
        return this.http.post<ResponseBean<any>>(
          `${environment.apiUrl}` +
          `${apiEndpoint}` +
          this.SLASE +
          this.getTokenPaginationStr(token),
          postData
        );
        break;
      case EDIT_DATA:
        return this.http.post<ResponseBean<any>>(
          `${environment.apiUrl}` +
          `${apiEndpoint}` +
          this.SLASE +
          id +
          this.SLASE +
          this.getTokenPaginationStr(token),
          postData
        );
        break;
    }
  }

  getTranslationListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<TranslationResponce>>(
      `${environment.apiUrl}` +
      `${TRANSLATIONS_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  addUpdateTranslation(postData, id, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${TRANSLATIONS_UPDATE_API}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  getTranslationDataById(id, token) {
    return this.http.get<ResponseBean<TranslationResponceId>>(
      `${environment.apiUrl}` +
      `${TRANSLATIONS_UPDATE_API}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token)
    );
  }

  getSubjectData(id, token) {
    return this.http.get<ResponseBean<SubjectsData>>(
      `${environment.apiUrl}` +
      `${SUBJECTS_EDIT_API}` +
      this.SLASE +
      id +
      this.SLASE +
      this.getTokenPaginationStr(token),
      {}
    );
  }

  getRoleListIndex(token) {
    return this.http.post<ResponseBean<RoleResponce>>(
      `${environment.apiUrl}` +
      `${GET_ROLES_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      {}
    );
  }

  editRole(id, token) {
    return this.http.get<ResponseBean<RoleData>>(
      `${environment.apiUrl}` +
      `${EDIT_ROLE_API}` +
      this.SLASE + id + this.SLASE +
      this.getTokenPaginationStr(token)
    );
  }

  saveRoleDataForEdit(id, postData, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${EDIT_ROLE_API}` +
      this.SLASE + id + this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  saveRoleDataForAdd(postData, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${ADD_ROLE_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  addRole(postData, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${ADD_ROLE_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  addRoleForGet(token) {
    return this.http.get<ResponseBean<RoleData>>(
      `${environment.apiUrl}` +
      `${ADD_ROLE_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token)
    );
  }


  getLedgerReportData(token) {
    return this.http.get<ResponseBean<LedgerReportResponce>>(
      `${environment.apiUrl}` +
      `${GET_TRANSACTIONS_LEDGER_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token)
    );
  }

  getLedgerReportIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<LedgerReportResponce>>(
      `${environment.apiUrl}` +
      `${GET_TRANSACTIONS_LEDGER_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  getReportDataByPayment(id, token, section) {
    return this.http.get<ResponseBean<TransactionPaymentResponse>>(
      `${environment.apiUrl}` +
      `${TRANS_APPROVEPAYMENT_API}` +
      this.SLASE + id + this.SLASE +
      this.getTokenPaginationStr(token) + this.getDataSection(section, token)
    );
  }

  editTransactionDataByPayment(id, postData, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${TRANS_APPROVEPAYMENT_API}` +
      this.SLASE + id + this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }


  uploadPaymentScreenshot(id, postData, token) {
    return this.http.post<any>(
      `${environment.apiUrl}` +
      `${ACCOUNTS_EDIT_API}` +
      this.SLASE + id +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  // getPlanDetails(id,token) {
  //   return this.http.get<ResponseBean<PlanResponce>>(
  //     `${environment.apiUrl}` +
  //     `${USER_PLAN_DETAILS}` +
  //     this.SLASE + id +
  //     this.SLASE +
  //     this.getTokenPaginationStr(token)
  //   );
  // }

  getPlanDetails(postData, token) {
    return this.http.post<ResponseBean<PlanResponce>>(
      `${environment.apiUrl}` +
      `${USER_PLAN_DETAILS}` +
      this.SLASE +
      this.getTokenPaginationStr(token), postData
    );
  }

  userProfileUpdate(postData, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${USER_PROFILE_UPDATE_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token), postData
    );
  }

  userProfileConvert(postData, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${USERPROFILES_CONVERTPROFILE_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token), postData
    );
  }

  applyFreetrial(postData, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${ACCOUNTS_APPLY_FREETRIAL_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token), postData
    );
  }

  editReferalId(id, postData, token) {
    return this.http.post<ResponseBean<ReferalCodeResponce>>(
      `${environment.apiUrl}` +
      `${EDIT_REFERALCODE}` +
      this.SLASE + id +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  getReferalData(id, token) {
    return this.http.get<ResponseBean<ReferalCodeResponce>>(
      `${environment.apiUrl}` +
      `${EDIT_REFERALCODE}` +
      this.SLASE + id +
      this.SLASE + this.getTokenPaginationStr(token)
    );
  }

  getRegisterPlans(token) {
    return this.http.get<ResponseBean<RegisterPlanResponce>>(
      `${environment.apiUrl}` + `${GET_REGISTRATIONPLANS}` +
      this.SLASE + this.getTokenPaginationStr(token)
    );
  }

  getAdvsetData(id, token, api, section) {
    return this.http.get<ResponseBean<AdvSettingsResponce>>(
      `${environment.apiUrl}` +
      `${api}` + (id ? this.SLASE +
        id : '') +
      this.SLASE +
      this.getTokenPaginationStr(token) + this.getDataSection(section, token)
    );
  }

  getAdvertisementData(id, token, api, section) {
    return this.http.get<ResponseBean<AdvDetailResponse>>(
      `${environment.apiUrl}` +
      `${api}` + (id ? this.SLASE +
        id : '') +
      this.SLASE +
      this.getTokenPaginationStr(token) + this.getDataSection(section, token)
    );
  }

  addEditAdvsetData(id, postData, token, api) {
    return this.http.post<ResponseBean<AdvSettingsResponce>>(
      `${environment.apiUrl}` +
      `${api}` + (id ? this.SLASE +
        id : '') +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  getAdvSetListIndex(postData, token, pageNo) {
    return this.http.post<ResponseBean<AdvsetsListResponse>>(
      `${environment.apiUrl}` +
      `${GET_ADVSETS_INDEX_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  getCommentsListIndex(id, isBusView, postData, token, pageNo) {
    if (isBusView) {
      return this.getCommentsListForBusinessIndex(id, postData, token, pageNo);
    } else {
      return this.http.post<ResponseBean<ReviewResponce>>(
        `${environment.apiUrl}` +
        `${GETREVIEWS_API}` +
        this.SLASE +
        this.getTokenPaginationStr(token, pageNo, PER_PAGE),
        postData
      );
    }

  }

  getCommentsListForBusinessIndex(id, postData, token, pageNo) {
    return this.http.post<ResponseBean<ReviewResponce>>(
      `${environment.apiUrl}` +
      `${GET_BUSINESSREVIEWS_API}` +
      this.SLASE + id +
      this.SLASE +
      this.getTokenPaginationStr(token, pageNo, PER_PAGE),
      postData
    );
  }

  socialBusiness(postData, api, token) {
    return this.http.post<ResponseBean<any>>(
      `${environment.apiUrl}` +
      `${api}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  getStatisticsData() {
    return this.http.get<ResponseBean<StatisticsResponce>>(
      `${environment.apiUrl}` +
      `${GETSTATISTICS_API}` +
      this.SLASE +
      this.getTokenPaginationStr(this.authservices.getUserToken()),
      {}
    );
  }

  async logoutApiCall(err) {
    // LogData("logoutApiCall_err",JSON.stringify(err));
    err.error = this.utilServices.getDecriptReasponse(err.error);
    if (err.error && err.error.code) {
      if (err.error.code === 401) {
        this.userLogoutApiCall();
      } else if (err.error.code === 400) {
        this.utilServices.errorToast(err.error.result.error);
      }

      LogData("LOGOUT_CALLING_1");
    } else if (err.status === 0) {
      LogData("LOGOUT_CALLING_2");
      this.utilServices.errorHandling(err);
    } else {
      LogData("LOGOUT_CALLING_3");
    }
  }

  async userLogoutApiCall() {
    const loading = await this.lodingCtrl.create(
      this.utilServices.getLoaderUI()
    );
    await loading.present();
    this.logout(this.authservices.getUserToken())
      .pipe(
        finalize(() => {
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("logoutApiCall_res", res);
          res = this.utilServices.getDecriptReasponse(res);
          // this.authservices.logout();
          // this.router.navigate(['login']);
          // this.authservices.setRefreshApp();
          // window.location.href = 'login';
          this.authservices.logout();
          // this.router.navigate([this.utilServices.isWeb() ? MAIN_HOME : LOGIN_ROUTE]);
          // window.location.href = this.utilServices.isWeb() ? MAIN_HOME : LOGIN_ROUTE;

          // oldcode
          // this.router.navigate([
          //   this.utilServices.isWeb() ? MAIN_HOME : VISITORS_TAB1_ROUTE,
          // ]);
          // window.location.href = this.utilServices.isWeb()
          //   ? MAIN_HOME
          //   : VISITORS_TAB1_ROUTE;
          // newcode
          this.router.navigate([VISITORS_TAB1_ROUTE]);
          // window.location.href = VISITORS_TAB1_ROUTE;
          // this.utilServices.reloadApp();
          this.utilServices.setUpdateSideMenu(true);
        },
        (err) => {
          loading.dismiss();
          LogData("logoutApiCall_error", err);
          this.logoutApiCall(err);
        }
      );
  }

  async userProfileUpdateApicall(formData, userId?) {
    const _id = userId ? userId : this.authservices.getForId();
    const loading = await this.lodingCtrl.create(
      this.utilServices.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");

    this.updateUserProfile(_id, formData, this.authservices.getUserToken())
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(async (res) => {
        LogData("userProfileUpdateApicall_res", res);
        res = this.utilServices.getDecriptReasponse(res);
        if (res) {
          this.utilServices.setUpdateuserProfile(true);
          this.utilServices.setSuccessResponse(true);
          this.utilServices.openForSuccessError(false, res.result.success);
          window.history.back();
          const modal = await this.modalCtrl.getTop();
          if (modal) {
            this.modalCtrl.dismiss();
          }
        }
      },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("userProfileUpdateApicall_error", err);
          this.logoutApiCall(err);
        }
      );
  }

  async getPoFileApi(lang) {
    // const loading = await this.loadingController.create(
    //   this.utilServices.getLoaderUI()
    // );
    // await loading.present();
    LogData("loader_start");
    this.getPoFile(lang)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          //   loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("getPoFile_res", res);
          res = this.utilServices.getDecriptReasponse(res);
          if (
            res &&
            res.result &&
            res.result.responce &&
            res.result.responce.getpo
          ) {
            this.authservices.setLanguageData(res.result.responce.getpo);
            this.utilServices.setUpdateSideMenu(true);
            window.history.back();
          }
        },
        (err) => {
          LogData("loader_dismiss");
          // loading.dismiss();
          LogData("getPoFile_error", err);
        }
      );
  }

  async getAllHasAccess() {
    if (this.authservices.getUserLoginData()) {
      this.getAllowPermission(this.authservices.getUserToken())
        .pipe(
          finalize(() => {
            LogData("loader_dismiss");
          })
        )
        .subscribe(
          (res) => {
            LogData("getAllowPermission_res", res);
            res = this.utilServices.getDecriptReasponse(res);
            if (res && res.result && res.result.responce.getAllowPermissionApi) {
              this.authservices.setAllowPermission(res.result.responce.getAllowPermissionApi);
              setTimeout(() => {
                this.utilServices.setUpdateSideMenu(true);
              }, 500);
            } else {
              this.authservices.setAllowPermission(null);
              setTimeout(() => {
                this.utilServices.setUpdateSideMenu(true);
              }, 500);
            }
          },
          (err) => {
            LogData("loader_dismiss");
            LogData("getAllowPermission_error", err);
          }
        );
    }

  }


  addRating(postData, token) {
    return this.http.post<ResponseBean<RatingResponce>>(
      `${environment.apiUrl}` +
      `${RATING_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  getAllowPermission(token) {
    return this.http.get<ResponseBean<GetAllowPermissionResponce>>(
      `${environment.apiUrl}` +
      `${GETALLOWPERMISSION_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token)
    );
  }

  getApplink(postData, token) {
    return this.http.post<any>(
      `${environment.apiUrl}` +
      `${GETAPPLINK_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }

  endDateChange(postData, token) {
    return this.http.post<ResponseBean<AccountsData>>(
      `${environment.apiUrl}` +
      `${ACCOUNTS_UPDATEENDDATE_API}` +
      this.SLASE +
      this.getTokenPaginationStr(token),
      postData
    );
  }


  // async statusUpdateConfirmation(
  //   keyIndex,
  //   msg,
  //   iconName,
  //   listData,
  //   apiEndpoint,
  //   isBulkAction
  // ) {
  //   const modal = await this.popCtrl.create({
  //     component: ConfirmationComponent,
  //     componentProps: { msg: msg, icon: iconName },
  //     cssClass: "custom-popover",
  //     showBackdrop: true,
  //     backdropDismiss: false,
  //     // breakpoints: [0, 0.3, 0.5, 0.8],
  //     // initialBreakpoint: 0.5
  //   });

  //   modal.onDidDismiss().then((data) => {
  //     LogData("onDidDismiss...", data);
  //     if (data.data != null) {
  //       const formData = new FormData();
  //       let checkedData = [];
  //       let tempArray = [];
  //       if (isBulkAction) {
  //         switch (keyIndex) {
  //           case 0: // All Delete
  //             checkedData = listData.filter((el) => el.isChecked);
  //             checkedData.forEach((element) => {
  //               tempArray.push(element.id);
  //             });
  //             formData.append(RECORDID_KEY, JSON.stringify(tempArray));
  //             formData.append(CURRENTSTATUS_KEY, DELETE_KEY);
  //             break;
  //           case 1: // All Inactive
  //             listData.forEach((element) => {
  //               tempArray.push(element.id);
  //             });
  //             formData.append(RECORDID_KEY, JSON.stringify(tempArray));
  //             formData.append(CURRENTSTATUS_KEY, INACTIVE_STATUS_KEY);
  //             break;
  //           case 2: // All Active
  //             listData.forEach((element) => {
  //               tempArray.push(element.id);
  //             });
  //             formData.append(RECORDID_KEY, JSON.stringify(tempArray));
  //             formData.append(CURRENTSTATUS_KEY, ACTIVE_STATUS_KEY);
  //             break;
  //           case 3: // Selected Inactive
  //             checkedData = listData.filter((el) => el.isChecked);
  //             checkedData.forEach((element) => {
  //               tempArray.push(element.id);
  //             });
  //             formData.append(RECORDID_KEY, JSON.stringify(tempArray));
  //             formData.append(CURRENTSTATUS_KEY, INACTIVE_STATUS_KEY);
  //             break;
  //           case 4: // Selected Active
  //             checkedData = listData.filter((el) => el.isChecked);
  //             checkedData.forEach((element) => {
  //               tempArray.push(element.id);
  //             });
  //             formData.append(RECORDID_KEY, JSON.stringify(tempArray));
  //             formData.append(CURRENTSTATUS_KEY, ACTIVE_STATUS_KEY);
  //             break;
  //         }
  //       } else {
  //         formData.append(RECORDID_KEY, JSON.stringify(listData));
  //         formData.append(CURRENTSTATUS_KEY, DELETE_KEY);
  //       }

  //       this.statusUpdate(formData, apiEndpoint, isBulkAction);
  //     }
  //   });

  //   await modal.present();
  // }

  async statusUpdateConfirmation(
    keyIndex,
    msg,
    iconName,
    listData,
    apiEndpoint,
    isBulkAction
  ) {
    const modal = await this.popCtrl.create({
      component: ConfirmationComponent,
      componentProps: { msg: msg, icon: iconName },
      cssClass: "custom-popover",
      showBackdrop: true,
      backdropDismiss: false,
      // breakpoints: [0, 0.3, 0.5, 0.8],
      // initialBreakpoint: 0.5
    });

    modal.onDidDismiss().then((data) => {
      LogData("onDidDismiss...", data);
      if (data.data != null) {
        const formData = new FormData();
        let checkedData = [];
        let tempArray = [];
        if (isBulkAction) {
          switch (keyIndex) {
            case 0: // All Delete
              checkedData = listData.filter((el) => el.isChecked);
              checkedData.forEach((element) => {
                tempArray.push(element.id);
              });
              formData.append(RECORDID_KEY, JSON.stringify(tempArray));
              formData.append(CURRENTSTATUS_KEY, DELETE_KEY);
              break;
            case 1: // Selected Inactive
              checkedData = listData.filter((el) => el.isChecked);
              checkedData.forEach((element) => {
                tempArray.push(element.id);
              });
              formData.append(RECORDID_KEY, JSON.stringify(tempArray));
              formData.append(CURRENTSTATUS_KEY, INACTIVE_STATUS_KEY);
              break;
            case 2: // Selected Active
              checkedData = listData.filter((el) => el.isChecked);
              checkedData.forEach((element) => {
                tempArray.push(element.id);
              });
              formData.append(RECORDID_KEY, JSON.stringify(tempArray));
              formData.append(CURRENTSTATUS_KEY, ACTIVE_STATUS_KEY);
              break;
          }
        } else {
          formData.append(RECORDID_KEY, JSON.stringify(listData));
          formData.append(CURRENTSTATUS_KEY, DELETE_KEY);
        }

        this.statusUpdate(formData, apiEndpoint, isBulkAction);
      }
    });

    await modal.present();
  }

  async statusUpdate(formData, apiEndpoint, isBulkAction) {
    const loading = await this.lodingCtrl.create(
      this.utilServices.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    this.updateStatusForListData(
      formData,
      apiEndpoint,
      this.authservices.getUserToken()
    )
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("updateStatusForListData_res", res);
          res = this.utilServices.getDecriptReasponse(res);
          if (res) {
            switch (apiEndpoint) {
              //updated status
              case UPDATE_STATUS_BUSINESS_LIST_API:
                this.utilServices.setUpdatedBusinessList(true);
                break;
              case UPDATE_STATUS_TRADING_LIST_API:
                this.utilServices.setUpdatedTradingList(true);
                break;
              case UPDATE_STATUS_PRODUCTLISTS_API:
                this.utilServices.setUpdatedProductList(true);
                break;
              case UPDATE_STATUS_PRODUCTCOMPANIES_API:
                this.utilServices.setUpdatedProductCompneyList(true);
                break;
              case UPDATE_STATUS_STATES_LIST_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_STATE,
                  value: true,
                });
                break;
              case UPDATE_STATUS_DISTRICTS_LIST_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_DISTRCIT,
                  value: true,
                });
                break;
              case UPDATE_STATUS_TALUKA_LIST_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_TALUKA,
                  value: true,
                });
                break;
              case UPDATE_STATUS_VILLAGES_LIST_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_VILLAGE,
                  value: true,
                });
                break;
              case UPDATE_STATUS_VILLAGES_AREA_LIST_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_VILLAGE_AREA,
                  value: true,
                });
                break;
              case UPDATE_STATUS_SUBJECTS_LIST_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_COMPL_SUB,
                  value: true,
                });
                break;
              case UPDATE_STATUS_COMPLAINS_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_COMPLAINS,
                  value: true,
                });
                break;
              case UPDATE_STATUS_INQUIRIES_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_INQ,
                  value: true,
                });
                break;
              case UPDATE_STATUS_USERPROFILES_LIST_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_STAFF,
                  value: true,
                });
                break;
              case UPDATE_STATUS_USERREGISTERAREAS_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_ALLOC_AREA,
                  value: true,
                });
                break;
              case UPDATE_STATUS_GROUPS_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_ACC_GROUP,
                  value: true,
                });
                break;
              case UPDATE_STATUS_ITEM_GROUPS_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_ITEM_GROUP,
                  value: true,
                });
                break;
              case UPDATE_STATUS_LEDGERS_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_ACC_LEDGER,
                  value: true,
                });
                break;
              case UPDATE_STATUS_ITEM_LEDGERS_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_ITEM_LADGER,
                  value: true,
                });
                break;
              case UPDATE_STATUS_ADVSETS_LIST_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_ADV_SETTING,
                  value: true,
                });
                break;
              case UPDATE_STATUS_EMPLOYEES_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_EMPLOYEE,
                  value: true,
                });
                break;
              case UPDATE_STATUS_COMMENTS_LIST_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_REVIEW_COMMENTS,
                  value: true,
                });
                break;
              //delete status
              case DELETE_BUSINESS_LIST_API:
                this.utilServices.setUpdatedBusinessList(true);
                break;
              case DELETE_TRADING_LIST_API:
                this.utilServices.setUpdatedTradingList(true);
                break;
              case DELETE_PRODUCTLISTS_API:
                this.utilServices.setUpdatedProductList(true);
                break;
              case DELETE_PRODUCTCOMPANIES_API:
                this.utilServices.setUpdatedProductCompneyList(true);
                break;
              case DELETE_STATES_LIST_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_STATE,
                  value: true,
                });
                break;
              case DELETE_DISTRICTS_LIST_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_DISTRCIT,
                  value: true,
                });
                break;
              case DELETE_TALUKA_LIST_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_TALUKA,
                  value: true,
                });
                break;
              case DELETE_VILLAGES_LIST_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_VILLAGE,
                  value: true,
                });
                break;
              case DELETE_VILLAGES_AREA_LIST_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_VILLAGE_AREA,
                  value: true,
                });
                break;
              case DELETE_SUBJECTS_LIST_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_COMPL_SUB,
                  value: true,
                });
                break;
              case DELETE_COMPLAINS_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_COMPLAINS,
                  value: true,
                });
                break;
              case DELETE_INQUIRIES_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_INQ,
                  value: true,
                });
                break;
              case DELETE_USERPROFILES_LIST_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_STAFF,
                  value: true,
                });
                break;
              case DELETE_USERREGISTERAREAS_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_ALLOC_AREA,
                  value: true,
                });
                break;
              case DELETE_GROUPS_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_ACC_GROUP,
                  value: true,
                });
                break;
              case DELETE_ITEM_GROUPS_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_ITEM_GROUP,
                  value: true,
                });
                break;
              case DELETE_LEDGERS_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_ACC_LEDGER,
                  value: true,
                });
                break;
              case DELETE_ITEM_LEDGERS_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_ITEM_LADGER,
                  value: true,
                });
                break;
              case DELETE_ADVSETS_LIST_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_ADV_SETTING,
                  value: true,
                });
                break;
              case DELETE_EMPLOYEES_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_EMPLOYEE,
                  value: true,
                });
                break;
              case DELETE_COMMENTS_LIST_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_REVIEW_COMMENTS,
                  value: true,
                });
                break;

            }
            this.utilServices.openForSuccessError(false, res.result.success);
            if (!isBulkAction) {
              window.history.back();
            }
          }
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("updateStatusForListData_error", JSON.stringify(err));
          // if (err.error.result.error) {
          //   this.utilServices.openForSuccessError(true, err.error.result.error);
          // } else {
          //   this.logoutApiCall(err);
          // }
          this.logoutApiCall(err);
        }
      );
  }

  async addBusinessData(formData, apiEndpoint, routerUrl?) {
    const loading = await this.lodingCtrl.create(
      this.utilServices.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");

    this.addBusiness(formData, this.authservices.getUserToken(), apiEndpoint)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("login_res", res);
          res = this.utilServices.getDecriptReasponse(res);
          if (res) {
            switch (apiEndpoint) {
              case ADD_BUSINESSLISTS_API:
                this.utilServices.setUpdatedBusinessList(true);
                break;
              case ADD_TRADINGTYPES_API:
                this.utilServices.setUpdatedTradingList(true);
                break;
              case ADD_PRODUCTLISTS_API:
                this.utilServices.setUpdatedProductList(true);
                break;
              case ADD_PRODUCTCOMPANIES_API:
                this.utilServices.setUpdatedProductCompneyList(true);
                break;
              case SUBJECTS_ADD_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_COMPL_SUB,
                  value: true,
                });
                break;
              case ADD_COMPLAINS_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_COMPLAINS,
                  value: true,
                });
                break;
              case ADD_INQUIRIES_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_INQ,
                  value: true,
                });
                break;
            }
            if (!routerUrl) {
              this.utilServices.openForSuccessError(false, res.result.success);
              window.history.back();
            } else {
              this.utilServices.setNavigationRoute(routerUrl);
            }

          }
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("login_error", err);
          this.logoutApiCall(err);
          // if (err.error.result.error) {
          //   this.logoutApiCall(err);
          //   this.utilServices.openForSuccessError(true, err.error.result.error);
          // }
        }
      );
  }

  async editBusinessData(formData, id, apiEndpoint, routerUrl?) {
    const loading = await this.lodingCtrl.create(
      this.utilServices.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");

    this.editBusiness(
      formData,
      id,
      this.authservices.getUserToken(),
      apiEndpoint
    )
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("login_res", res);
          res = this.utilServices.getDecriptReasponse(res);
          this.authservices.removeLocalData(BUSINESS_NAME_LST_KEY);
          this.authservices.removeLocalData(BUSINESS_STATUS_LST_KEY);
          if (res) {
            switch (apiEndpoint) {
              case EDIT_BUSINESSLISTS_API:
                this.utilServices.setUpdatedBusinessList(true);
                break;
              case EDIT_TRADINGTYPES_API:
                this.utilServices.setUpdatedTradingList(true);
                break;
              case EDIT_PRODUCTLISTS_API:
                this.utilServices.setUpdatedProductList(true);
                break;
              case EDIT_PRODUCTCOMPANIES_API:
                this.utilServices.setUpdatedProductCompneyList(true);
                break;
              case SUBJECTS_EDIT_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_COMPL_SUB,
                  value: true,
                });
                break;
              case EDIT_COMPLAINS_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_COMPLAINS,
                  value: true,
                });
                break;
              case EDIT_INQUIRIES_API:
                this.utilServices.setAddressMaster({
                  page: PAGE_INQ,
                  value: true,
                });
                break;
            }
            if (!routerUrl) {
              this.utilServices.openForSuccessError(false, res.result.success);
              window.history.back();
            } else {
              this.utilServices.setNavigationRoute(routerUrl);
            }
          }
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("login_error", err);
          this.logoutApiCall(err);
          // if (err.error.result.error) {
          //   this.logoutApiCall(err);
          //   this.utilServices.openForSuccessError(true, err.error.result.error);
          // } else {
          //   this.logoutApiCall(err);
          // }
        }
      );
  }

  async addressMasterUpdate(
    crudType,
    postData,
    id,
    apiEndpoint,
    page,
    messsage,
    icon
  ) {
    if (crudType === DELETE_DATA) {
      this.updateConfirmationForAddressMaster(
        crudType,
        postData,
        id,
        apiEndpoint,
        page,
        messsage,
        icon
      );
    } else {
      this.setAddressMasterCrud(crudType, postData, id, apiEndpoint, page);
    }
  }

  async setAddressMasterCrud(crudType, postData, id, apiEndpoint, page) {
    const loading = await this.lodingCtrl.create(
      this.utilServices.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    this.addressMasterCrud(
      crudType,
      postData,
      id,
      this.authservices.getUserToken(),
      apiEndpoint,
      page
    )
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("login_res", res);
          res = this.utilServices.getDecriptReasponse(res);
          if (res) {
            if (page === PAGE_BANK_TRANS || page === PAGE_PAYMENT_TRANS || page === PAGE_RECEIPT_TRANS || page === PAGE_JOURNAL_TRANS) {
              this.utilServices.setTransactionMaster({ page: page, value: true });
            } else {
              if (page !== PAGE_ALLOC_AREA) {
                this.utilServices.setAddressMaster({ page: page, value: true });
              }
            }

            this.utilServices.openForSuccessError(false, res.result.success);
            if (page === PAGE_ACC_LEDGER) {
              this.utilServices.setNavigationRoute(ACCOUNT_LADGER);
            } else {
              window.history.back();
            }

          }
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("login_error", err);
          // if (err.error.result.error) {
          //   this.utilServices.openForSuccessError(true, err.error.result.error);
          // } else {
          //   this.logoutApiCall(err);
          // }
          this.logoutApiCall(err);

        }
      );
  }

  async updateConfirmationForAddressMaster(
    crudType,
    postData,
    id,
    apiEndpoint,
    page,
    messsage,
    icon
  ) {
    const modal = await this.popCtrl.create({
      component: ConfirmationComponent,
      componentProps: { msg: messsage, icon: icon },
      cssClass: "custom-popover",
      showBackdrop: true,
      backdropDismiss: false,
      // breakpoints: [0, 0.3, 0.5, 0.8],
      // initialBreakpoint: 0.5
    });

    modal.onDidDismiss().then((data) => {
      LogData("onDidDismiss...", data);
      if (data.data != null) {
        this.setAddressMasterCrud(crudType, postData, id, apiEndpoint, page);
      }
    });

    await modal.present();
  }

  async getUserProfileUpdate() {
    if (this.authservices.getUserLoginData()) {
      this.getUserProfile(
        this.authservices.getForId(),
        this.authservices.getUserToken()
      )
        .pipe(
          finalize(() => {
            LogData("loader_dismiss");
          })
        )
        .subscribe(
          (res) => {
            LogData("getUserProfile_res", res);
            res = this.utilServices.getDecriptReasponse(res);
            if (
              res &&
              res.result &&
              res.result.responce
            ) {
              if (res.result.responce.otherSettings) {
                this.authservices.setUserOtherSettingsData(res.result.responce.otherSettings);
              }
              const userProfileData = res.result.responce.Userprofiles ? res.result.responce.Userprofiles : null;
              const currentUser: CurrentUserData =
                this.authservices.getUserLoginData();
              currentUser.userprofile = userProfileData;
              this.authservices.setUserLoginData(currentUser);
              this.utilServices.setUpdateSideMenu(true);
            }
          },
          (err) => {
            LogData("loader_dismiss");
            LogData("getUserProfile_error", err);
            this.logoutApiCall(err);
          }
        );
    }
  }

  async getUserProfileData(id, isLoader, section?) {
    let loading;
    if (isLoader) {
      loading = await this.lodingCtrl.create(this.utilServices.getLoaderUI());
      await loading.present();
    }

    LogData("loader_start");
    this.getUserProfile(id, this.authservices.getUserToken(), section)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          if (isLoader) {
            loading.dismiss();
          }
        })
      )
      .subscribe(
        (res) => {
          LogData("getUserProfile_res", res);
          res = this.utilServices.getDecriptReasponse(res);
          if (res && res.result && res.result.responce) {
            if (res.result.responce.Userprofiles) {
              this.utilServices.setUserProfileDataInSubject(res.result.responce.Userprofiles);
            }
            if (res.result.responce.getDataSection) {
              this.utilServices.setDataSection(res.result.responce.getDataSection);
            }

          }
        },
        (err) => {
          LogData("loader_dismiss");
          if (isLoader) {
            loading.dismiss();
          }
          LogData("getUserProfile_error", err);
          this.logoutApiCall(err);
        }
      );
  }

  async createInqForUserApi(formData, isBusiness) {
    const loading = await this.lodingCtrl.create(
      this.utilServices.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");

    this.createInq(
      formData,
      this.authservices.getUserToken(),
      isBusiness
    )
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("login_res", res);
          res = this.utilServices.getDecriptReasponse(res);
          if (res) {
            this.utilServices.openForSuccessError(false, res.result.success);
            this.utilServices.setSuccessResponse(true);
            // window.history.back();
          }
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("login_error", err);
          this.logoutApiCall(err);
        }
      );
  }

  async setTransationCrud(crudType, postData, id, apiEndpoint, pageName) {
    const loading = await this.lodingCtrl.create(
      this.utilServices.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    this.transationCrud(
      crudType,
      postData,
      id,
      this.authservices.getUserToken(),
      apiEndpoint
    )
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("setTransationCrud_res", res);
          res = this.utilServices.getDecriptReasponse(res);
          if (res) {
            this.utilServices.clearTransactionData();
            this.utilServices.setTransactionMaster({
              page: pageName,
              value: true,
            });
            switch (pageName) {
              case PAGE_BANK_TRANS:
                this.utilServices.setNavigationRoute(BANK_TRANSACTIONS);
                break;
              case PAGE_PAYMENT_TRANS:
                this.utilServices.setNavigationRoute(PAYMENT_TRANSACTIONS);
                break;
              case PAGE_RECEIPT_TRANS:
                this.utilServices.setNavigationRoute(RECEIPT_TRANSACTIONS);
                break;
              case PAGE_JOURNAL_TRANS:
                this.utilServices.setNavigationRoute(JOURNAL_TRANSACTIONS);
                break;
            }
          }
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("setTransationCrud_error", err);
          this.logoutApiCall(err);

        }
      );
  }

  async getTransactionData(id, api) {
    const loading = await this.lodingCtrl.create(
      this.utilServices.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");

    this.getAddEditData(id, this.authservices.getUserToken(), api, null)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("getPaymentMethodData_res", res);
          res = this.utilServices.getDecriptReasponse(res);
          if (res && res.result && res.result.responce) {
            this.utilServices.setTransactionReasonse(res.result.responce);
            // if (res.result.responce.getCrDr) {
            // if (res.result.responce.getCrDr.cr &&
            //   res.result.responce.getCrDr.cr.length > 0) {
            //   this.crDataList = res.result.responce.getCrDr.cr;
            // }
            // if (res.result.responce.getCrDr.dr &&
            //   res.result.responce.getCrDr.dr.length > 0) {
            //   this.drDataList = res.result.responce.getCrDr.dr;
            // }
            // }
            // this.buildForm();
          }
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("getPaymentMethodData_error", err);
          this.logoutApiCall(err);
        }
      );
  }


  async sendOtpEdit(id, editFormData, email, mobile, token) {
    const loading = await this.lodingCtrl.create(
      this.utilServices.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    const formData = new FormData();
    formData.append(PHONE_KEY, mobile);
    if(email){
      formData.append(EMAIL_KEY, email);
    }
    this.sendOTPForEdit(formData, token)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe((res) => {
        LogData("customerSendOTP_res", res);
        res = this.utilServices.getDecriptReasponse(res);
        if (res) {
          this.openOtp(email, mobile, editFormData, id);
        }
      }, err => {
        LogData("loader_dismiss");
        loading.dismiss();
        LogData("customerSendOTP_error", err);
        this.logoutApiCall(err);
      });
  }

  async openOtp(email, mobile, editFormData, id) {
    const modal = await this.modalCtrl.create({
      component: VarifyAccountComponent,
      componentProps: {
        email: email,
        mobile: mobile,
        isEditMode: true,
        editFormData: editFormData,
        profileId: id
      },
      cssClass: 'custom-modal'
    });
    modal.onDidDismiss().then((data) => {
      LogData("onDidDismiss...", data);
      if (data.data) {
        // this.setNext();
      }

    });
    return await modal.present();
  }


  async alertForUserConvert(selectedId, newUserType, otherData, msg, page) {
    const modal = await this.popCtrl.create({
      component: ConfirmationComponent,
      componentProps: { msg: msg, icon: 'convert' },
      cssClass: 'custom-popover',
    });

    modal.onDidDismiss().then((data) => {
      LogData("onDidDismiss...", data);
      if (data.data != null && data.data === "Yes") {
        LogData("API_CALL");
        this.userProfileConvertApi(selectedId, newUserType, otherData, page);
      }
    });

    await modal.present();
  }

  async userProfileConvertApi1(userprofile_id, new_user_type, data, page) {
    LogData("userprofile_id", userprofile_id);
    LogData("new_user_type", new_user_type);
    LogData("data", data);
    LogData("page", page);
    if (page) {
      if (page === WITHOUT_PAGE) {
        this.utilServices.setAddressMaster(true);
      } else {
        this.utilServices.setAddressMaster({
          page: page,
          value: true,
        });
      }
    }
    window.history.back();
  }

  async userProfileConvertApi(userprofile_id, new_user_type, data, page) {
    const loading = await this.lodingCtrl.create(
      this.utilServices.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    const formData = new FormData();
    formData.append(USERPROFILE_ID_KEY, userprofile_id);
    formData.append(NEW_USER_TYPE_KEY, new_user_type);
    this.userProfileConvert(formData, this.authservices.getUserToken())
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe((res) => {
        LogData("userProfileConvertApi_res", res);
        res = this.utilServices.getDecriptReasponse(res);
        if (res && res.result && res.result.responce) {
          if (data) {
            if (data.isLogout) {
              this.userLogoutApiCall();
            }
          } else {
            if (page) {
              if (page === WITHOUT_PAGE) {
                this.utilServices.setAddressMaster(true);
              } else {
                this.utilServices.setAddressMaster({
                  page: page,
                  value: true,
                });
              }
            }
            this.utilServices.openForSuccessError(false, res.result.success);
            window.history.back();
          }

        }

      }, err => {
        LogData("loader_dismiss");
        loading.dismiss();
        LogData("userProfileConvertApi_error", err);
        this.logoutApiCall(err);
      });
  }

  async userSocilaBusinessApi(userObj: SocialResponseObj) {
    const loading = await this.lodingCtrl.create(
      this.utilServices.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    this.socialBusiness(userObj.formData, userObj.api, this.authservices.getUserToken())
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe((res) => {
        LogData("userSocilaBusinessApi_res", res);
        res = this.utilServices.getDecriptReasponse(res);
        if (res && res.result && res.result.responce) {
          if (userObj.isToast) {
            this.utilServices.presentToast(res.result.success);
          }
          if (userObj.isResponse) {
            this.utilServices.setSocialResponse({ page: userObj.page, data: res.result.responce });
          }
          if (userObj.isSuccessError) {
            this.utilServices.openForSuccessError(false, res.result.success);
          }
        }

      }, err => {
        LogData("loader_dismiss");
        loading.dismiss();
        LogData("userSocilaBusinessApi_error", err);
        this.logoutApiCall(err);
      });
  }


  getPagination(pageNo, len) {
    if (!len) {
      len = 10;
    }
    if (!pageNo) {
      pageNo = 1;
    }
    const start = (pageNo - 1) * len;
    return "&start=" + start + "&length=" + len + "";
  }

  // getDataSection(section, token?) {
  //   let prefix = '';
  //   if (token && (environment.tokenFlag === 1 || environment.tokenFlag === 2)) {
  //     prefix = "&";
  //   } else {
  //     prefix = "?";
  //   }
  //   return section ? prefix + "getDataSection=" + section : '';
  // }

  getDataSection(section, token?) {

    return section ? "&getDataSection=" + section : '';
  }

  getModule(module, token?) {
    let prefix = '';
    if (token && (environment.tokenFlag === 1 || environment.tokenFlag === 2)) {
      prefix = "&";
    } else {
      prefix = "?";
    }
    return module ? prefix + "module=" + module : '';
  }

  getApiMode(apimode, token?) {
    // let prefix = '';
    // if (token && (environment.tokenFlag === 1 || environment.tokenFlag === 2)) {
    //   prefix = "&";
    // } else {
    //   prefix = "?";
    // }
    return apimode ? "&apimode=" + apimode : '';
  }


  getTokenPaginationStr(token?, pageNo?, len?) {
    let str = "";
    if (pageNo && len) {
      if (!len) {
        len = 10;
      }
      if (!pageNo) {
        pageNo = 1;
      }
      const start = (pageNo - 1) * len;
      str = "start=" + start + "&length=" + len + "";
    }
    if (token && (environment.tokenFlag === 1 || environment.tokenFlag === 2)) {
      if (str) {
        str = str + "&";
      }
      str =
        str +
        "token=" +
        token +
        "&AppTimezone=" +
        moment().format("YYYY-MM-DD HH:mm:ssZ");
    }
    if (str) {
      str = "?" + str;
    }

    const appLang = this.authservices.getLanguage();
    if (appLang) {
      // str = str + (str ? "&" : "") + "lang=" + (appLang ? appLang.value : "en");
      str = str + (str ? "&" : "?") + "lang=" + (appLang ? appLang.value : "en");
    }
    return str;
  }
}
