<app-photo-utility (fileSelected)="selectedFile($event)" [allowEdit]="false"></app-photo-utility>
<!-- <ion-row>
  <ion-col [ngClass]="marginTop"  *ngIf="!selectedCoverImg">
    <div class="flex flex-col div-pic ion-align-items-center p-16" (click)="openProfileImage()">
      <ion-img [src]="'photo_cap' | getImagePipe:2" alt="Photo" class="hp-50"></ion-img>
      <ion-text class="mt-16 lbl-sec-sb-12">+ {{title | getLanguagePipe}} </ion-text>
      <ion-text class="mt-16 lbl-10 lh-16 ml-8 mr-8 ion-text-center">
        {{discription}} </ion-text>
    </div>
  </ion-col>

  <ion-col [ngClass]="marginTop" *ngIf="selectedCoverImg">
    <div class="flex flex-col ion-align-items-start">
      <ion-text class="mt-16 lbl-med-16">{{editTxt | getLanguagePipe}} </ion-text>
      <div class="container">
        <div class="mt-16 ">
          <ion-img [src]="selectedCoverImg | getServerImgDisplay:CONST_DATA.ADVERTISEMENT_PIC_KEY" alt="Photo"
            class="round-10"></ion-img>
        </div>
        <div class="top-right">
          <ion-icon [src]="'ic_ro_delete' | getImagePipe" size="large"
            (click)="removImage(selectedCoverImg,false,1,null);$event.stopPropagation();"></ion-icon>
        </div>
      </div>
    </div>
  </ion-col>
</ion-row> -->

<div [ngClass]="marginTop" class="flex flex-col">
  <ion-label *ngIf="displayLbl" class="lbl-med-12">{{displayLbl| getLanguagePipe}} :</ion-label>
  <div *ngIf="!selectedCoverImg" class="mt-4">
    <div class="flex flex-col div-pic ion-align-items-center p-16" (click)="openProfileImage()">
      <ion-img [src]="'photo_cap' | getImagePipe:2" alt="Photo" class="hp-50"></ion-img>
      <ion-text class="mt-16 lbl-sec-sb-12">+ {{title | getLanguagePipe}} </ion-text>
      <ion-text class="mt-16 lbl-10 lh-16 ml-8 mr-8 ion-text-center">
        {{discription}} </ion-text>
    </div>
  </div>

  <div  *ngIf="selectedCoverImg" class="mt-4">
    <div class="flex flex-col ion-align-items-start">
      <ion-text class="mt-16 lbl-med-16">{{editTxt | getLanguagePipe}} </ion-text>
      <div class="container">
        <div class="mt-16 ">
          <ion-img [src]="selectedCoverImg | getServerImgDisplay:CONST_DATA.ADVERTISEMENT_PIC_KEY:isMain" alt="Photo"
            class="round-10"></ion-img>
        </div>
        <div class="top-right">
          <ion-icon [src]="'ic_ro_delete' | getImagePipe" size="large"
            (click)="removImage(selectedCoverImg,false,1,null);$event.stopPropagation();"></ion-icon>
        </div>
      </div>
    </div>
  </div>
  </div>