import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AccountsDetails } from 'src/app/models/accounts-data';
import { CommonData } from 'src/app/models/common-data';
import { AuthServices } from 'src/app/services/auth.services';
import { UtilsService } from 'src/app/services/uitils.services';
import { ADMIN_ROLE_ID, ADVANCE_PAYMENT, BUSINESS_ROLE_ID, DATE_TIME_FORMAT_FOR_USER, FREETRIAL_PAYEMNT, OPEN_TYPE_EDIT, OPEN_TYPE_NEW, PENDING_PAYMENT, QR_CODE_TYPE, RENEWAL_PAYMENT } from 'src/app/utils/constant';
import { PAYMENT_PROOF, PAYMENT_SUMMARY, UPGRADE_BUSINESS } from 'src/app/utils/routes';
import { LogData } from 'src/app/utils/utils';
import { EnddateChangeComponent } from '../enddate-change/enddate-change.component';

@Component({
  selector: 'app-received-payments-card',
  templateUrl: './received-payments-card.component.html',
  styleUrls: ['./received-payments-card.component.scss'],
})
export class ReceivedPaymentsCardComponent implements OnInit {

  @Input() paymentsData: AccountsDetails;
  isBusinessUser;
  CONST_DATA = {
    DATE_TIME_FORMAT_FOR_USER, ADMIN_ROLE_ID, BUSINESS_ROLE_ID, ADVANCE_PAYMENT
  };
  rollId;
  constructor(private authServices: AuthServices,
    private utilServices: UtilsService,
    private popCtrl: PopoverController) { }

  ngOnInit() {
    this.rollId = this.authServices.getUserRoll();
    this.isBusinessUser = this.rollId === BUSINESS_ROLE_ID ? true : false;
  }

  getLable() {
    return this.paymentsData.subs_btn.text === ADVANCE_PAYMENT ? 'lbl_adv_pay' :
      this.paymentsData.subs_btn.text === RENEWAL_PAYMENT ? 'lbl_renew' :
        this.paymentsData.subs_btn.text === FREETRIAL_PAYEMNT ? 'lbl_fre_tr_pay' :
          this.paymentsData.subs_btn.text === PENDING_PAYMENT ? 'lbl_pending_pay' : 'lbl_pay'
  }

  openPaymentPage(data: CommonData) {
    // LogData("paymentsData",this.paymentsData);
    // id = accunt.id
    this.utilServices.setSuccessResponse(false);
    if (this.utilServices.isIos()) {
      this.utilServices.openSfariView(this.authServices.getUserRoll());
    } else {
      if(this.paymentsData.subs_btn.text === PENDING_PAYMENT){
        if(this.paymentsData.call_from_btn === OPEN_TYPE_NEW){
          this.utilServices.setNavigationRouteQuery(PAYMENT_SUMMARY, { id: data.value,renewal_type:OPEN_TYPE_EDIT });
        }else{
          this.utilServices.setNavigationRouteQuery(UPGRADE_BUSINESS, { id: data.value, renewal_type: data.text,exit_data:true });
        }
        
      }else{
        this.utilServices.setNavigationRouteQuery(UPGRADE_BUSINESS, { id: data.value, renewal_type: data.text });
      }
      
    }
  }


  async endDateChange() {
    const modal = await this.popCtrl.create({
      component: EnddateChangeComponent,
      componentProps: {
        title: "dg_tl_end_date_ch",
        btn1: "submit",
        paymentsData: this.paymentsData
      },
      cssClass: "custom-popover",
      showBackdrop: true,
      backdropDismiss: false,
      // breakpoints: [0, 0.3, 0.5, 0.8],
      // initialBreakpoint: 0.5
    });

    modal.onDidDismiss().then((data) => {
      LogData("onDidDismiss...", data);
      if (data.data != null) {
        this.paymentsData = data.data;
      }
    });

    await modal.present();
  }

  isBtnDisplayForQR() {
    //status cancell = 2
    // payment_type = 4 for qr code
    let flag = false;
    if (this.paymentsData.status && this.paymentsData.status.length > 0 && this.paymentsData.payment_type && this.paymentsData.payment_type.length > 0 &&
      this.paymentsData.payment_type[0].value === 4
      && this.paymentsData.status[0].value === 2 && this.paymentsData.btn_allow !== 2 && this.isBusinessUser) {
      flag = true;
    }
    return flag;
  }

  isBtnDisplayForOnline() {
    //status cancell = 2
    // payment_type = 6 for online
    let flag = false;
    if (this.paymentsData.status && this.paymentsData.status.length > 0
      && this.paymentsData.status[0].value === 2 && this.paymentsData.btn_allow !== 2 &&
      this.paymentsData.payment_type && this.paymentsData.payment_type.length > 0 && this.paymentsData.payment_type[0].value === 6 && this.isBusinessUser) {
      flag = true;
    }
    return flag;
  }

  openNew(data) {
    // this.utilServices.setNavigationRoute(PAYMENT_SUMMARY+"/"+this.paymentsData.id);
    this.utilServices.setNavigationRouteQuery(PAYMENT_SUMMARY, { type: OPEN_TYPE_NEW, id: this.paymentsData.id,renewal_type:OPEN_TYPE_NEW });
  }

  openEdit(data) {
    this.utilServices.setNavigationRouteQuery(PAYMENT_PROOF, { type: QR_CODE_TYPE, id: this.paymentsData.id,renewal_type:OPEN_TYPE_EDIT });
  }

}
